const ExcelJS = require("exceljs");

export function exportExcelReport(swaymsevakList) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("ગુરુપૂર્ણિમા ઉત્સવ સમર્પણ યાદી", {
    views: [{ showGridLines: true }],
  });
  sheet.properties.defaultColWidth = 15;
  sheet.getRow(1).height = 60;
  sheet.getRow(1).style = {
    font: { size: 16, bold: true },
    alignment: { vertical: "middle", horizontal: "center", wrapText: true },
    border: {
      top: { style: "thin", color: { argb: "000" } },
      left: { style: "thin", color: { argb: "000" } },
      bottom: { style: "double", color: { argb: "000" } },
      right: { style: "thin", color: { argb: "000" } },
    },
  };

  sheet.columns = [
    {
      header: "ક્રમાંક",
      key: "count",
      width: 10,
    },
    {
      header: "નામ",
      key: "name",
      width: 32,
    },
    {
      header: "મોબાઈલ નંબર",
      key: "mobileno",
      width: 20,
    },
    {
      header: "સરનામું",
      key: "address",
      width: 25,
    },
    {
      header: "સમર્પણ",
      key: "samrpan",
      width: 35,
    },
  ];

  let count_excel = 1;
  swaymsevakList?.map((swaymsevak, index) => {
    let row = sheet.addRow({
      count: count_excel++,
      name: swaymsevak?.name,
      mobileno: swaymsevak?.mobileno,
      address: swaymsevak?.address,
      samrpan: "",
    });
    row.eachCell(function (cell) {
      cell.style = {
        border: {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        },
        font: { size: 14 },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
      };
    });
    row.style = {
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      font: { size: 14 },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
    };
    return true;
  });

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "ગુરુપૂર્ણિમા ઉત્સવ સમર્પણ યાદી.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}
