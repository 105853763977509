import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getPraharVrut() {
  const response = await axiosInstance.get("api/praharvrut/fetchallpraharvrut");
  return response.data;
}

export async function getPraharVrutYears() {
  const response = await axiosInstance.get(
    "api/praharvrut/fetchpraharvrutyears"
  );
  return response.data;
}

export async function getPraharVrutbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/praharvrut/fetchbymonthpraharvrut",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addPraharVrut({
  date,
  sahbhagi_shakha,
  sahbhagi_swaymsevak,
  kul_prahar,
  prahar_500,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post("api/praharvrut/addpraharvrut", {
    date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
    sahbhagi_shakha,
    sahbhagi_swaymsevak,
    kul_prahar,
    prahar_500,
    bal,
    tarun,
    yog,
  });
  return response;
}

export async function updatePraharVrut({
  _id: id,
  date,
  sahbhagi_shakha,
  sahbhagi_swaymsevak,
  kul_prahar,
  prahar_500,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/praharvrut/updatepraharvrut/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sahbhagi_shakha,
      sahbhagi_swaymsevak,
      kul_prahar,
      prahar_500,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deletePraharVrut(id) {
  const response = await axiosInstance.delete(
    `api/praharvrut/deletepraharvrut/${id}`
  );
  return response;
}
