import axiosInstance from "axios-instance";

export async function getLocalSamiti() {
  const response = await axiosInstance.get(
    "api/localsamiti/fetchalllocalsamiti"
  );
  return response.data;
}

export async function getLocalSamitibyVasti(vasti_id) {
  const response = await axiosInstance.get(
    `api/localsamiti/getlocalsamitibyvasti/${vasti_id}`
  );
  return response.data;
}

export async function addLocalSamiti({ vasti_id, local_samiti_name }) {
  const response = await axiosInstance.post("api/localsamiti/addlocalsamiti", {
    vasti_id,
    local_samiti_name,
  });
  return response;
}

export async function updateLocalSamiti({
  _id: id,
  vasti_id,
  local_samiti_name,
}) {
  const response = await axiosInstance.put(
    `api/localsamiti/updatelocalsamiti/${id}`,
    {
      vasti_id,
      local_samiti_name,
    }
  );
  return response;
}

export async function updateLocalSamitiPersons({ samiti_id, persons }) {
  const response = await axiosInstance.put(
    `api/localsamiti/updatelocalsamitipersons/${samiti_id}`,
    {
      persons,
    }
  );
  return response;
}

export async function deleteLocalSamiti(id) {
  const response = await axiosInstance.delete(
    `api/localsamiti/deletelocalsamiti/${id}`
  );
  return response;
}
