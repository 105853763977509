import axiosInstance from "axios-instance";

export async function getShakha() {
  const response = await axiosInstance.get("api/shakha/fetchallshakha");
  return response.data;
}

export async function getSaptahikShakha() {
  const response = await axiosInstance.get("api/shakha/fetchsaptahikshakha");
  return response.data;
}

export async function getNonSaptahikShakha() {
  const response = await axiosInstance.get("api/shakha/fetchnonsaptahikshakha");
  return response.data;
}

export async function getShakhaProfile(shakha_id) {
  const response = await axiosInstance.get(
    `api/shakha/fetchshakhaprofile/${shakha_id}`
  );
  return response.data;
}

export async function getShakhabyVasti(vasti_id) {
  const response = await axiosInstance.get(
    `api/shakha/fetchshakhabyvasti/${vasti_id}`
  );
  return response;
}

export async function getShakhabyUpnagar(upnagar_id) {
  const response = await axiosInstance.get(
    `api/shakha/fetchshakhabyupnagar/${upnagar_id}`
  );
  return response;
}

export async function addShakha({
  nagar_id,
  upnagar_id,
  vasti_id,
  shakha_name,
  is_saptahik,
}) {
  const response = await axiosInstance.post("api/shakha/addshakha", {
    nagar_id,
    upnagar_id,
    vasti_id,
    shakha_name,
    is_saptahik,
  });
  return response;
}

export async function updateShakha({
  _id: id,
  nagar_id,
  upnagar_id,
  vasti_id,
  shakha_name,
  is_saptahik,
}) {
  const response = await axiosInstance.put(`api/shakha/updateshakha/${id}`, {
    nagar_id,
    upnagar_id,
    vasti_id,
    shakha_name,
    is_saptahik,
  });
  return response;
}

export async function updateShakhaProfile({
  _id: id,
  shakha_prakar_1,
  shakha_prakar_2,
  sthan,
  samay,
  saptahik_bethak_divas,
  seva_divas,
  ganvesh_din,
  upsthiti_din,
  is_shakha_dhvaj,
  is_shakha_vishal,
  is_shakha_varshikutsav,
}) {
  const response = await axiosInstance.put(
    `api/shakha/updateshakhaprofile/${id}`,
    {
      shakha_prakar_1,
      shakha_prakar_2,
      sthan,
      samay,
      saptahik_bethak_divas,
      seva_divas,
      ganvesh_din,
      upsthiti_din,
      is_shakha_dhvaj,
      is_shakha_vishal,
      is_shakha_varshikutsav,
    }
  );
  return response;
}

export async function deleteShakha(id) {
  const response = await axiosInstance.delete(`api/shakha/deleteshakha/${id}`);
  return response;
}
