import { Box, Card, styled, Grid } from "@mui/material";
import { H3 } from "app/components/Typography";
import { useLocation } from "react-router-dom";
import Layout from "../Layout";
import { getSocietybyVasti } from "app/service/api-service/vasti-service";
import { useQuery } from "react-query";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  padding: "24px !important",
  background: theme.palette.background.paper,
  boxShadow: "none !important",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    fontSize: "25px",
    color: "#34314c",
  },
}));
const Itembox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "15px",
  paddingBottom: "10px",
  borderBottom: "1px dashed #d5d4d4",
  ":last-child": {
    borderBottom: "0px",
  },
}));
const Title = styled(H3)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "bold",
  textAlign: "center",
  borderBottom: "2px dashed #414c76",
  marginBottom: "15px",
}));
const Content = styled(H3)(({ theme }) => ({
  lineHeight: 1.8,
  fontSize: "18px",
  textAlign: "center",
  "& a": {
    color: "#3b3ea1",
  },
}));

const VastiDetails = () => {
  const { state } = useLocation();
  const { vasti } = state;
  const { data: societyList, isLoading } = useQuery(
    ["getSocietybyVasti", vasti._id],
    () => getSocietybyVasti(vasti._id)
  );

  return (
    <Layout
      isLoading={isLoading || false}
      title={vasti.vasti_name}
      content={
        <StyledCard>
          <ContentBox sx={{ mb: "24px", mt: 2 }}>
            <Itembox>
              <Title>ઉપનગર</Title>
              <Content>{vasti.upnagar_id.upnagar_name}</Content>
            </Itembox>
            {vasti.vasti_pramukh_name && (
              <Itembox>
                <Title>વસ્તી પ્રમુખ</Title>
                <Content>{vasti.vasti_pramukh_name}</Content>
                <Content>
                  <a href={"tel:" + vasti.vasti_pramukh_mobileno}>
                    {vasti.vasti_pramukh_mobileno}
                  </a>
                </Content>
              </Itembox>
            )}
            {vasti.vasti_vistar && (
              <Itembox>
                <Title>વસ્તી વિસ્તાર</Title>
                <Content>{vasti.vasti_vistar}</Content>
              </Itembox>
            )}
            {societyList?.length !== 0 && (
              <Itembox>
                <Title>વસ્તીની સોસાયટી</Title>
                <Content>
                  {societyList?.map((society, index) => {
                    return (
                      <Grid
                        container
                        spacing={0}
                        sx={{ flexWrap: "nowrap" }}
                        key={index}
                      >
                        <Grid item lg={2.5} sx={{ fontWeight: "bold" }}>
                          ({index + 1})&nbsp;
                        </Grid>
                        <Grid item sx={{ textAlign: "left" }}>
                          {society.society_name}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Content>
              </Itembox>
            )}
          </ContentBox>
        </StyledCard>
      }
    />
  );
};

export default VastiDetails;
