import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getVastiEkatrikaran() {
  const response = await axiosInstance.get(
    "api/vastiekatrikaran/fetchallvastiekatrikaran"
  );
  return response.data;
}

export async function getVastiEkatriKaranYears() {
  const response = await axiosInstance.get(
    "api/vastiekatrikaran/fetchvastiekatrikaranyears"
  );
  return response.data;
}

export async function getVastiEkatriKaranbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/vastiekatrikaran/fetchbymonthvastiekatrikaran",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addVastiEkatriKaran({
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/vastiekatrikaran/addvastiekatrikaran",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateVastiEkatriKaran({
  _id: id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/vastiekatrikaran/updatevastiekatrikaran/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteVastiEkatriKaran(id) {
  const response = await axiosInstance.delete(
    `api/vastiekatrikaran/deletevastiekatrikaran/${id}`
  );
  return response;
}
