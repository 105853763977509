import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getVishaysahBethak() {
  const response = await axiosInstance.get(
    "api/vishaysahbethak/fetchallvishaysahbethak"
  );
  return response.data;
}

export async function getVishaysahBethakYears() {
  const response = await axiosInstance.get(
    "api/vishaysahbethak/fetchvishaysahbethakyears"
  );
  return response.data;
}

export async function getVishaysahBethakbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/vishaysahbethak/fetchbymonthvishaysahbethak",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addVishaysahBethak({
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/vishaysahbethak/addvishaysahbethak",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateVishaysahBethak({
  _id: id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/vishaysahbethak/updatevishaysahbethak/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteVishaysahBethak(id) {
  const response = await axiosInstance.delete(
    `api/vishaysahbethak/deletevishaysahbethak/${id}`
  );
  return response;
}
