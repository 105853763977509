import { themeShadows } from "./themeColors";

export const components = {
  MuiTable: {
    styleOverrides: {
      root: {
        tableLayout: "fixed",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: "13px",
        padding: "12px 0px",
      },
      root: {
        fontSize: "14px",
        wordBreak: "break-all",
        padding: "12px 8px 12px 0px",
        whiteSpace: "break-spaces",
      },
    },
  },
  MUIDataTableSelectCell: {
    styleOverrides: {
      root: {
        paddingLeft: 12,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: "14px",
        textTransform: "none",
        fontWeight: "400",
      },
      contained: {
        boxShadow: themeShadows[8],
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      "*": {
        boxSizing: "border-box",
      },
      html: {
        MozOsxFontSmoothing: "grayscale",
        WebkitFontSmoothing: "antialiased",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
      },
      a: {
        textDecoration: "none",
        color: "inherit",
      },
      "#root": {
        height: "100%",
      },
      "#nprogress .bar": {
        zIndex: "2000 !important",
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: themeShadows[12],
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: "11px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: "0.875rem",
      },
    },
  },
  MuiExpansionPanel: {
    styleOverrides: {
      root: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
        boxShadow:
          "0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%), 0px 1px 8px 0px rgb(0 0 0 / 4%) !important",
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        color: "#041a64",
        borderBottom: "2px solid #efecec",
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "rgb(255 61 87)",
        textAlign: "justify",
        marginTop: "20px",
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: "0.9rem",
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "15px",
      },
    },
  },
  MUIDataTableHeadCell: {
    styleOverrides: {
      root: {
        paddingLeft: 16,
        fontSize: "17px",
        fontWeight: "bold",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        wordBreak: "break-word",
      },
      sortAction: {
        fontSize: "17px",
        fontWeight: "bold",
        alignItems: "center",
      },
    },
  },
  MUIDataTableBodyCell: {
    styleOverrides: {
      root: {
        paddingLeft: 8,
        fontSize: "15px",
        wordBreak: "break-word",
        whiteSpace: "break-spaces",
      },
    },
  },
  MUIDataTableToolbar: {
    styleOverrides: {
      regular: {
        minHeight: "8px",
      },
      root: {
        padding: "15px",
        flexDirection: "column-reverse",
        "& div:nth-of-type(2)": {
          width: "100%",
          textAlign: "center",
        },
        "& div:first-of-type": {
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
        },
      },
    },
  },
  MUIDataTable: {
    styleOverrides: {
      root: {
        boxShadow: "none",
      },
    },
  },
};
