import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box } from "@mui/material";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { addVisheshnondh } from "app/service/api-service/visheshnondh-service";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useMutation, useQueryClient } from "react-query";
import dayjs from "dayjs";
import MyDatePicker from "app/components/MyDatePicker";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const AddVisheshNondh = (props) => {
  const [state, setState] = useState({
    date: dayjs(),
    upsthiti: 0,
  });

  const queryClient = useQueryClient();

  React.useEffect(() => {
    ValidatorForm.addValidationRule("isValidDate", (value) => {
      return dayjs(value, "DD-MM-YYYY", true).isValid();
    });
    return () => ValidatorForm.removeValidationRule("isValidDate");
  }, [state.date]);

  const AddVisheshnondhMutation = useMutation(addVisheshnondh, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["getVisheshnondhbyMonth", props?.value],
        (MainData) => {
          return data.visheshnondhData.concat(MainData);
        }
      );
      setState({
        date: dayjs(),
        upsthiti: 0,
      });
      props.setValue({
        month: state.date.get("month"),
        year: state.date.get("year"),
      });
      props.handleClose();
    },
  });
  const handleSubmit = async () => {
    AddVisheshnondhMutation.mutate(state);
  };

  const handleChange = (event) => {
    if (event.target.name === "upsthiti") {
      event.target.value.replace(/\D/g, "");
      event.target.value.substr(0, 10);
      setState({
        ...state,
        [event.target.name]: event.target.validity.valid
          ? event.target.value
          : "",
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleDateChange = (date) => {
    setState({ ...state, date });
  };

  return (
    <MyDialogBox
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({
          date: dayjs(),
          upsthiti: 0,
        });
      }}
      title="વિશેષ નોંધ ઉમેરો"
      content={
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <MyDatePicker
                label="તારીખ *"
                value={state.date || ""}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <TextField
                type="text"
                name="vishay"
                id="standard-basic"
                value={state.vishay || ""}
                onChange={handleChange}
                errorMessages={["વિષય દાખલ કરો"]}
                label="વિષય *"
                validators={["required"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                multiline
                type="text"
                name="vishesh_nondh"
                id="standard-basic"
                value={state?.vishesh_nondh || ""}
                onChange={handleChange}
                errorMessages={["વિશેષ નોંધ દાખલ કરો"]}
                label="વિશેષ નોંધ *"
                validators={["required"]}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={AddVisheshnondhMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default AddVisheshNondh;
