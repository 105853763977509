import axiosInstance from "axios-instance";

export async function getUpnagar() {
  const response = await axiosInstance.get("api/upnagar/fetchallupnagar");
  return response.data;
}

export async function getUpnagarwithShakha() {
  const response = await axiosInstance.get(
    "api/upnagar/fetchallupnagarwithshakha"
  );
  return response.data;
}

export async function getUpnagarbyNagar(nagar_id) {
  const response = await axiosInstance.get(
    `api/upnagar/fetchupnagarbynagar/${nagar_id}`
  );
  return response;
}

export async function addUpnagar({ nagar_id, upnagar_name }) {
  const response = await axiosInstance.post("api/upnagar/addupnagar", {
    nagar_id,
    upnagar_name,
  });
  return response;
}

export async function updateUpnagar({ _id: id, nagar_id, upnagar_name }) {
  const response = await axiosInstance.put(`api/upnagar/updateupnagar/${id}`, {
    nagar_id,
    upnagar_name,
  });
  return response;
}

export async function deleteUpnagar(id) {
  const response = await axiosInstance.delete(
    `api/upnagar/deleteupnagar/${id}`
  );
  return response;
}
