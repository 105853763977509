import axiosInstance from "axios-instance";

export async function getBlobFile(url) {
  const response = await axiosInstance.get(url, {
    responseType: "blob",
  });
  return response.data;
}

export async function getCommunityPost(skip, limit) {
  const response = await axiosInstance.get(
    `api/communitypost/fetchallcommunitypost?skip=${skip}&limit=${limit}`
  );
  return response.data;
}

export async function getCityLevelCommunityPost(skip, limit) {
  const response = await axiosInstance.get(
    `api/communitypost/fetchcitylevelcommunitypost?skip=${skip}&limit=${limit}`
  );
  return response.data;
}

export async function getCommunityProfilePost(skip, limit) {
  const response = await axiosInstance.get(
    `api/communitypost/fetchallcommunityprofilepost?skip=${skip}&limit=${limit}`
  );
  return response.data;
}

export async function likePost(postId) {
  const response = await axiosInstance.put(`api/communitypost/like/${postId}`);
  return response;
}

export async function addCommunityPost(formData) {
  const response = await axiosInstance.post(
    "api/communitypost/addcommunitypost",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=XXX",
      },
    }
  );
  return response;
}

export async function updateCommunityPost(formData) {
  const response = await axiosInstance.put(
    "api/communitypost/updatecommunitypost",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=XXX",
      },
    }
  );
  return response;
}

export async function deleteCommunityPost(id) {
  const response = await axiosInstance.delete(
    `api/communitypost/deletecommunitypost/${id}`
  );
  return response;
}
