import * as React from "react";
import {
  Box,
  Icon,
  Typography,
  MenuItem,
  CircularProgress,
  styled,
  Grid,
} from "@mui/material";
import Layout from "../Layout";
import MyReportTable from "app/components/MyReportTable";
import { useQuery } from "react-query";
import { pdf } from "@react-pdf/renderer";
import { LoadingButton } from "@mui/lab";
import {
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { getShakha } from "app/service/api-service/shakha-service";
import { useState } from "react";
import { getSwaymsevakbyShakha } from "app/service/api-service/swaymsevak-service";
import { MyCard } from "app/components";
import GurupurnimaUtsavPDF from "./GurupurnimaUtsavPDF";
import { exportExcelReport } from "./GuruPurnimaUtsavExcel";
import useAuth from "app/hooks/useAuth";
import { saveAs } from "file-saver";

const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const GurupurnimaUtsavReport = () => {
  let { user } = useAuth();
  const [shakhaName, setShakhaName] = useState(
    ["શાખા સ્તર"].includes(user.role_level) ? user.shakha : ""
  );
  const [shakhaID, setShakhaID] = useState(
    ["શાખા સ્તર"].includes(user.role_level) ? user.shakha_id : 0
  );
  const [PDFLoading, setPDFLoading] = useState(false);
  const [shakhaList, setShakhaList] = useState();
  const [swaymsevakList, setSwaymsevakList] = useState([]);

  const { isLoading: shakhaLoading } = useQuery(["getShakha1"], getShakha, {
    onSuccess: (data) => {
      setShakhaList(data);
    },
  });

  const { isLoading: swaymsevakLoading } = useQuery(
    ["getSwaymsevakbyShakha1", shakhaID],
    () => getSwaymsevakbyShakha(shakhaID),
    {
      onSuccess: (data) => {
        setTimeout(() => {
          setSwaymsevakList(data);
        }, 500);
      },
    }
  );

  const DownloadPDF = async () => {
    setPDFLoading(true);
    const doc = (
      <GurupurnimaUtsavPDF
        swaymsevakList={swaymsevakList}
        shakhaName={shakhaName}
      />
    );

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "ગુરુપૂર્ણિમા ઉત્સવ સમર્પણ યાદી.pdf");
    setPDFLoading(false);
  };
  const columns = [
    { name: "ક્રમાંક", options: { filterOptions: { fullWidth: true } } },
    "નામ",
    "મોબાઈલ નંબર",
    "સરનામું",
    "સમર્પણ",
  ];

  let count = 1;
  const table_data = swaymsevakList?.map((swaymsevak, index) => [
    count++,
    swaymsevak.name,
    swaymsevak.mobileno,
    swaymsevak.address,
    "",
  ]);

  return (
    <Layout
      title="ગુરુપૂર્ણિમા ઉત્સવ સમર્પણ યાદી"
      content={
        <MyCard
          title={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                mt: 2,
              }}
            >
              <LoadingButton
                onClick={DownloadPDF}
                variant="contained"
                loading={PDFLoading || swaymsevakLoading}
                color="primary"
                style={{
                  minHeight: 36,
                  margin: "10px 0 10px 10px",
                  fontWeight: "bold",
                }}
              >
                <Icon className="icon">picture_as_pdf</Icon> &nbsp;ડાઉનલોડ
                રિપોર્ટ
              </LoadingButton>
              <LoadingButton
                onClick={() =>
                  swaymsevakList && exportExcelReport(swaymsevakList)
                }
                loading={swaymsevakLoading}
                variant="contained"
                color="primary"
                style={{
                  minHeight: 36,
                  margin: "10px 0 10px 10px",
                  fontWeight: "bold",
                }}
              >
                <Icon className="icon">text_snippet</Icon> &nbsp;ડાઉનલોડ EXCEL
                રિપોર્ટ
              </LoadingButton>
            </Box>
          }
        >
          {!["શાખા સ્તર"].includes(user.role_level) && (
            <Grid
              container
              spacing={1}
              sx={{ justifyContent: "center", mt: 2, mb: 1 }}
            >
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ValidatorForm
                  autoComplete="new-password"
                  onSubmit={() => {}}
                  onError={() => null}
                >
                  <SelectField
                    id="standard-basic"
                    name="shakha_id"
                    value={(shakhaList && shakhaID) || ""}
                    label="શાખા પસંદ કરો"
                    onChange={(event) => {
                      setShakhaID(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <React.Fragment>
                          {shakhaLoading ? (
                            <CircularProgress
                              color="inherit"
                              size={25}
                              style={{ height: "-1px" }}
                              sx={{ mr: 5 }}
                            />
                          ) : null}
                        </React.Fragment>
                      ),
                    }}
                  >
                    <MenuItem value="0" onClick={() => setShakhaName("")}>
                      <em>શાખા પસંદ કરો</em>
                    </MenuItem>
                    {shakhaList?.map((shakha, index) => (
                      <MenuItem
                        value={shakha._id}
                        key={index}
                        onClick={() => setShakhaName(shakha.shakha_name)}
                      >
                        {shakha.shakha_name}
                      </MenuItem>
                    ))}
                  </SelectField>
                </ValidatorForm>
              </Grid>
            </Grid>
          )}

          <MyReportTable
            data={table_data}
            columns={columns}
            title={
              shakhaName && (
                <Typography gutterBottom variant="h5" component="div">
                  {shakhaName}
                </Typography>
              )
            }
          />
        </MyCard>
      }
    />
  );
};

export default GurupurnimaUtsavReport;
