import * as React from "react";
import { Icon, Tooltip, IconButton } from "@mui/material";
import Layout from "../../Layout";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";
import {
  deleteRammandirvrut,
  getRammandirvrutbyVasti,
} from "app/service/api-service/rammandirvrut-service";
import { useLocation, useNavigate } from "react-router-dom";
import MyDashDataTable from "app/components/MyDashDataTable";
import EditRamMandirVrut from "./EditRamMandirVrut";
import dayjs from "dayjs";

const ViewVastiVrut = () => {
  const confirm = useConfirm();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { vasti } = state;
  const [editopen, setEditopen] = useState(false);
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();
  const [
    value,
    // eslint-disable-next-line
    setValue,
  ] = React.useState({
    vasti_id: vasti._id,
  });

  const { data: vrutList, isLoading } = useQuery(
    ["getRammandirvrutbyVasti", value],
    () => getRammandirvrutbyVasti(value)
  );

  const DeleteRammandirvrutMutation = useMutation(deleteRammandirvrut, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["getRammandirvrutbyVasti", value],
        (MainData) => {
          return MainData.filter((vrut) => {
            return vrut._id !== data.rammandirvrut._id;
          });
        }
      );
    },
  });

  const handleDelete = async (id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ વૃત કાઢી નાખવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        DeleteRammandirvrutMutation.mutate(id);
      })
      .catch(() => {});
  };

  const handleClickOpen = (type) => {
    setEditopen(true);
    navigate(pathname + "#alert", {
      state: { vasti: vasti },
    });
  };

  const handleClose = () => {
    setEditopen(false);
    setEdit(false);
  };
  const columns = [
    "તારીખ",
    "ઘર સંપર્ક સંખ્યા",
    "ઘર સંપર્કમાં જોડાયેલા કાર્યકર્તાની સંખ્યા",
    {
      name: "ક્રિયાઓ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false,
      },
    },
  ];
  const options = {};
  const data = vrutList?.map((vrut, index) => [
    vrut.formatted_date,
    vrut.ghar_sampark_sankhya,
    vrut.ghar_sampark_karyakarta_sankhya,
    <>
      <Tooltip title="સંપાદિત કરો" placement="bottom">
        <IconButton
          onClick={() => {
            setEdit({
              ...vrut,
              date: dayjs(vrut.date),
            });
            handleClickOpen("edit");
          }}
        >
          <Icon color="primary">edit</Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title="કાઢી નાખો" placement="bottom">
        <IconButton
          onClick={() => {
            handleDelete(vrut._id);
          }}
        >
          <Icon color="error">delete</Icon>
        </IconButton>
      </Tooltip>
    </>,
  ]);
  return (
    <Layout
      isLoading={isLoading || false}
      title={vasti.vasti_name + " વૃત"}
      content={
        <>
          <MyDashDataTable
            filename={vasti.vasti_name + " વૃત.csv"}
            data={data}
            columns={columns}
            options={options}
          />
          {edit && (
            <EditRamMandirVrut
              handleClose={handleClose}
              open={editopen}
              edit={edit}
              value={value}
            />
          )}
        </>
      }
    />
  );
};

export default ViewVastiVrut;
