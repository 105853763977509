export const navigations = [
  {
    name: "ડેશબોર્ડ",
    path: "/dashboard/default/",
    icon: "dashboard",
    access: [
      "એડમિન સ્તર",
      "શાખા સ્તર",
      "નગર સ્તર",
      "ઉપનગર સ્તર",
      "વસ્તી સ્તર",
      "માહિતી સ્તર",
    ],
  },
  {
    name: "કૉમ્યુનિટી",
    path: "/community/dashboard",
    icon: "grid_view",
    access: [
      "એડમિન સ્તર",
      "શાખા સ્તર",
      "નગર સ્તર",
      "ઉપનગર સ્તર",
      "વસ્તી સ્તર",
      "સ્વયંસેવક સ્તર",
    ],
  },
  {
    label: "કાર્યકર્તા",
    type: "label",
    access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    name: "કાર્યકર્તા મેનેજમેન્ટ",
    icon: "device_hub",
    access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
    children: [
      {
        name: "કાર્યકર્તા",
        iconText: "કાર્યકર્તા",
        path: "/staff/allstaff",
        access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
      },
      {
        name: "ભૂમિકા",
        iconText: "ભૂમિકા",
        path: "/role/allroles",
        access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
      },
      {
        name: "નગર",
        iconText: "નગર",
        path: "/nagar/allnagar",
        access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
      },
      {
        name: "ઉપનગર",
        iconText: "ઉપનગર",
        path: "/upnagar/allupnagar",
        access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
      },
      {
        name: "વસ્તી",
        iconText: "વસ્તી",
        path: "/vasti/allvasti",
        access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
      },
      {
        name: "શાખા",
        iconText: "શાખા",
        path: "/shakha/allshakha",
        access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
      },
    ],
  },
  {
    name: "વાર્ષિક કેલેન્ડર",
    path: "/varshik_calender/allvarshikcalender",
    icon: "event",
    access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    name: "ડાઉનલોડ સેન્ટર",
    path: "/download_center/alldownloads",
    icon: "download",
    access: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર", "શાખા સ્તર"],
  },
  {
    label: "શાખા પ્રવૃત્તિઓ",
    type: "label",
    access: ["શાખા સ્તર"],
  },
  {
    name: "અષ્ટબિંદુ વૃત પત્રક",
    path: "/ashtabindu/addpatrak",
    icon: "wb_sunny",
    access: ["શાખા સ્તર"],
  },
  {
    name: "ઉપસ્થિતિ પત્રક",
    path: "/upsthiti/addpatrak",
    icon: "group",
    access: ["શાખા સ્તર"],
  },
  {
    name: "શાખા પ્રોફાઈલ",
    path: "/shakha/profile",
    icon: "pages",
    access: ["શાખા સ્તર"],
  },
  {
    name: "સ્વયંસેવકો",
    path: "/swaymsevak/allswaymsevak",
    icon: "person_pin",
    access: ["શાખા સ્તર"],
  },
];
