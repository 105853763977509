import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box, MenuItem, CircularProgress } from "@mui/material";
import { useState } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useMutation, useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import MyDatePicker from "app/components/MyDatePicker";
import { getVasti } from "app/service/api-service/vasti-service";
import { updateRammandirvrut } from "app/service/api-service/rammandirvrut-service";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const EditRamMandirVrut = (props) => {
  const editval = props.edit;
  const [state, setState] = useState(editval);
  const queryClient = useQueryClient();

  const { data: vastiList, isLoading: vastiisLoading } = useQuery(
    ["getVasti"],
    getVasti
  );

  React.useEffect(() => {
    ValidatorForm.addValidationRule("isValidDate", (value) => {
      return dayjs(value, "DD-MM-YYYY", true).isValid();
    });
    return () => ValidatorForm.removeValidationRule("isValidDate");
  }, [state.date]);

  const UpdateRammandirVrutMutation = useMutation(updateRammandirvrut, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(["getRammandirvrutbyVasti", props.value]);
      setState({
        date: dayjs(),
        ghar_sampark_sankhya: 0,
        ghar_sampark_karyakarta_sankhya: 0,
      });
      props.handleClose();
    },
  });

  const handleSubmit = async () => {
    UpdateRammandirVrutMutation.mutate(state);
  };

  const handleChange = (event) => {
    if (event.target.name !== "vasti_id") {
      event.target.value.replace(/\D/g, "");
      setState({
        ...state,
        [event.target.name]: event.target.validity.valid
          ? event.target.value
          : "",
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleDateChange = (date) => {
    setState({ ...state, date });
  };

  return (
    <MyDialogBox
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({
          date: dayjs(),
          ghar_sampark_sankhya: 0,
          ghar_sampark_karyakarta_sankhya: 0,
        });
      }}
      title="વૃત સંપાદિત કરો"
      content={
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="vasti_id"
                value={(vastiList && state.vasti_id) || ""}
                label="વસ્તી *"
                errorMessages={["વસ્તી પસંદ કરો"]}
                validators={["required"]}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {vastiisLoading ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>વસ્તી પસંદ કરો</em>
                </MenuItem>
                {vastiList?.map((vasti, index) => (
                  <MenuItem value={vasti._id} key={index}>
                    {vasti.vasti_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <MyDatePicker
                label="તારીખ *"
                value={state.date || ""}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="ghar_sampark_sankhya"
                id="standard-basic1"
                value={state.ghar_sampark_sankhya}
                onChange={handleChange}
                errorMessages={[
                  "ઘર સંપર્ક સંખ્યા દાખલ કરો",
                  "માન્ય ઘર સંપર્ક સંખ્યા દાખલ કરો",
                ]}
                label="ઘર સંપર્ક સંખ્યા *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="ghar_sampark_karyakarta_sankhya"
                id="standard-basic1"
                value={state.ghar_sampark_karyakarta_sankhya}
                onChange={handleChange}
                errorMessages={[
                  "ઘર સંપર્કમાં જોડાયેલા કાર્યકર્તાની સંખ્યા દાખલ કરો",
                  "માન્ય ઘર સંપર્કમાં જોડાયેલા કાર્યકર્તાની સંખ્યા દાખલ કરો",
                ]}
                label="ઘર સંપર્કમાં જોડાયેલા કાર્યકર્તાની સંખ્યા *"
                validators={["required", "isNumber"]}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={UpdateRammandirVrutMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default EditRamMandirVrut;
