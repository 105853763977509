import axiosInstance from "axios-instance";

export async function getVastiSamiti() {
  const response = await axiosInstance.get(
    "api/vastisamiti/fetchallvastisamiti"
  );
  return response.data;
}

export async function getVastiSamitibyGroup() {
  const response = await axiosInstance.get(
    "api/vastisamiti/fetchallvastisamitibygroup"
  );
  return response.data;
}

export async function getVastiSamitibyVasti(vasti_id) {
  const response = await axiosInstance.get(
    `api/vastisamiti/getvastisamitibyvasti/${vasti_id}`
  );
  return response.data;
}

export async function addVastiSamiti({
  vasti_id,
  name,
  mobileno,
  sangh_parivar,
  sanyojak,
  vishesh_nondh,
}) {
  const response = await axiosInstance.post("api/vastisamiti/addvastisamiti", {
    vasti_id,
    name,
    mobileno,
    sangh_parivar,
    sanyojak,
    vishesh_nondh,
  });
  return response;
}

export async function updateVastiSamiti({
  _id: id,
  vasti_id,
  name,
  mobileno,
  sangh_parivar,
  sanyojak,
  vishesh_nondh,
}) {
  const response = await axiosInstance.put(
    `api/vastisamiti/updatevastisamiti/${id}`,
    {
      vasti_id,
      name,
      mobileno,
      sangh_parivar,
      sanyojak,
      vishesh_nondh,
    }
  );
  return response;
}

export async function deleteVastiSamiti(id) {
  const response = await axiosInstance.delete(
    `api/vastisamiti/deletevastisamiti/${id}`
  );
  return response;
}
