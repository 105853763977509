import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getVisheshnondh() {
  const response = await axiosInstance.get(
    "api/visheshnondh/fetchallvisheshnondh"
  );
  return response.data;
}

export async function getVisheshnondhYears() {
  const response = await axiosInstance.get(
    "api/visheshnondh/fetchvisheshnondhyears"
  );
  return response.data;
}

export async function getVisheshnondhbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/visheshnondh/fetchbymonthvisheshnondh",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addVisheshnondh({ date, vishesh_nondh, vishay }) {
  const response = await axiosInstance.post(
    "api/visheshnondh/addvisheshnondh",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      vishesh_nondh,
      vishay,
    }
  );
  return response;
}

export async function updateVisheshnondh({
  _id: id,
  date,
  vishesh_nondh,
  vishay,
}) {
  const response = await axiosInstance.put(
    `api/visheshnondh/updatevisheshnondh/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      vishesh_nondh,
      vishay,
    }
  );
  return response;
}

export async function deleteVisheshnondh(id) {
  const response = await axiosInstance.delete(
    `api/visheshnondh/deletevisheshnondh/${id}`
  );
  return response;
}
