import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getUpnagarSaptahik() {
  const response = await axiosInstance.get(
    "api/upnagarsaptahik/fetchallupnagarsaptahik"
  );
  return response.data;
}

export async function getUpnagarSaptahikYears() {
  const response = await axiosInstance.get(
    "api/upnagarsaptahik/fetchupnagarsaptahikyears"
  );
  return response.data;
}

export async function getUpnagarSaptahikbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/upnagarsaptahik/fetchbymonthupnagarsaptahik",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addUpnagarSaptahik({
  date,
  upnagar_id,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/upnagarsaptahik/addupnagarsaptahik",
    {
      upnagar_id,
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateUpnagarSaptahik({
  _id: id,
  upnagar_id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/upnagarsaptahik/updateupnagarsaptahik/${id}`,
    {
      upnagar_id,
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteUpnagarSaptahik(id) {
  const response = await axiosInstance.delete(
    `api/upnagarsaptahik/deleteupnagarsaptahik/${id}`
  );
  return response;
}
