import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import GujaratiFont from "../../../../../pdf_fonts/MuktaVaani-Regular.ttf";
import BoldGujaratiFont from "../../../../../pdf_fonts/MuktaVaani-Bold.ttf";

Font.register({
  family: "GujaratiFont",
  src: GujaratiFont,
});
Font.register({
  family: "GujaratiFont",
  src: BoldGujaratiFont,
});
const styles = StyleSheet.create({
  page: {
    fontFamily: "GujaratiFont",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  header: {
    fontSize: "25px",
    textAlign: "center",
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexGrow: 1,
    height: "auto",
    flexDirection: "row",
  },
  tableCol: {
    border: "2px solid black",
    borderStyle: "solid",
    borderCollapse: "collapse",
  },
  tableColCell: {
    padding: "5px",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    flexGrow: 1,
    marginTop: 5,
    marginBottom: 5,
    fontSize: "12px",
    textAlign: "center",
  },
});

const SwaymsevakPDF = ({ swaymsevakList, shakhaName }) => {
  let count = 1;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.header, { margin: "0 0 20px 0" }]}>
          <Text>સ્વયંસેવકોની યાદી</Text>
        </View>
        <View style={styles.table}>
          <View>
            {shakhaName ? (
              <View style={styles.tableRow}>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "10px 0px",
                    width: "100%",
                    border: "2px solid black",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {shakhaName}
                  </Text>
                </View>
              </View>
            ) : null}
            {shakhaName ? (
              <View style={styles.tableRow} wrap={false} fixed>
                <View style={[styles.tableCol, { width: "10%" }]}>
                  <Text style={styles.tableColCell}>ક્રમાંક</Text>
                </View>
                <View style={[styles.tableCol, { width: "30%" }]}>
                  <Text style={styles.tableColCell}>નામ</Text>
                </View>
                <View style={[styles.tableCol, { width: "20%" }]}>
                  <Text style={styles.tableColCell}>મોબાઈલ નંબર</Text>
                </View>
                <View style={[styles.tableCol, { width: "40%" }]}>
                  <Text style={styles.tableColCell}>સરનામું</Text>
                </View>
              </View>
            ) : null}
            {shakhaName
              ? swaymsevakList?.map((swaymsevak, index) => (
                  <View style={styles.tableRow} wrap={false} key={index + 1}>
                    <View style={[styles.tableCol, { width: "10%" }]}>
                      <Text style={styles.tableCell}>{count++}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "30%" }]}>
                      <Text style={styles.tableCell}>{swaymsevak?.name}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "20%" }]}>
                      <Text style={styles.tableCell}>
                        {swaymsevak?.mobileno}
                      </Text>
                    </View>
                    <View style={[styles.tableCol, { width: "40%" }]}>
                      <Text style={styles.tableCell}>
                        {swaymsevak?.address}
                      </Text>
                    </View>
                  </View>
                ))
              : null}
            {!shakhaName ? (
              <View style={styles.tableRow} wrap={false} fixed>
                <View style={[styles.tableCol, { width: "10%" }]}>
                  <Text style={styles.tableColCell}>ક્રમાંક</Text>
                </View>
                <View style={[styles.tableCol, { width: "20%" }]}>
                  <Text style={styles.tableColCell}>શાખા</Text>
                </View>
                <View style={[styles.tableCol, { width: "25%" }]}>
                  <Text style={styles.tableColCell}>નામ</Text>
                </View>
                <View style={[styles.tableCol, { width: "20%" }]}>
                  <Text style={styles.tableColCell}>મોબાઈલ નંબર</Text>
                </View>
                <View style={[styles.tableCol, { width: "25%" }]}>
                  <Text style={styles.tableColCell}>સરનામું</Text>
                </View>
              </View>
            ) : null}
            {!shakhaName
              ? swaymsevakList?.map((swaymsevak, index) => (
                  <View style={styles.tableRow} wrap={false} key={index + 1}>
                    <View style={[styles.tableCol, { width: "10%" }]}>
                      <Text style={styles.tableCell}>{count++}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "20%" }]}>
                      <Text style={styles.tableCell}>
                        {swaymsevak?.shakha_id?.shakha_name}
                      </Text>
                    </View>
                    <View style={[styles.tableCol, { width: "25%" }]}>
                      <Text style={styles.tableCell}>{swaymsevak?.name}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "20%" }]}>
                      <Text style={styles.tableCell}>
                        {swaymsevak?.mobileno}
                      </Text>
                    </View>
                    <View style={[styles.tableCol, { width: "25%" }]}>
                      <Text style={styles.tableCell}>
                        {swaymsevak?.address}
                      </Text>
                    </View>
                  </View>
                ))
              : null}
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default SwaymsevakPDF;
