import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Icon, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";

const MyFormDialogBox = ({
  open,
  handleClose,
  content,
  title,
  maxWidth = "",
  isDetail = false,
}) => {
  const [modalopen, setModalopen] = React.useState(open);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isDetail) {
      window.location.hash = "#alert";
      const onHashChange = () =>
        setModalopen(window.location.hash === "#alert" || !modalopen);
      window.addEventListener("hashchange", onHashChange);
      return () => window.removeEventListener("hashchange", onHashChange);
    }
  }, [setModalopen, modalopen, isDetail]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      open={modalopen}
      onClose={handleClose}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={maxWidth ? maxWidth : "sm"}
      disableEscapeKeyDown
    >
      <DialogTitle
        sx={{ color: "#041a64", letterSpacing: "0.4px", fontWeight: 600 }}
      >
        {title}
      </DialogTitle>
      <Box
        id="form_dialog_close"
        position="absolute"
        top={11}
        right={10}
        onClick={() => {
          handleClose();
          if (!isDetail) {
            navigate(-1);
          }
        }}
      >
        <IconButton
          className="button"
          aria-label="Close"
          color="error"
          sx={{ "& span": { fontWeight: "bold !important" } }}
        >
          <Icon>close</Icon>
        </IconButton>
      </Box>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default MyFormDialogBox;
