import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getAshtabindu(date, shakha_id) {
  const response = await axiosInstance.get("api/ashtabindu/getashtabindu", {
    params: {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      shakha_id,
    },
  });
  return response.data;
}

export async function getAshtabindubyMonth({ month, year, shakha_id }) {
  const response = await axiosInstance.post(
    "api/ashtabindu/getashtabindubymonth",
    {
      month,
      year,
      shakha_id,
    }
  );
  return response;
}

export async function checkAshtabindu(date, shakha_id) {
  const response = await axiosInstance.get("api/ashtabindu/checkashtabindu", {
    params: {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      shakha_id,
    },
  });
  return response.data;
}

export async function addAshtabindu({
  git,
  subhashit_amrutvachan,
  bodhkatha,
  charcha,
  samachar_samiksha,
  baudhik,
  dirgh_varta,
  shakha_id,
  staff_id,
  date,
}) {
  const response = await axiosInstance.post("api/ashtabindu/addashtabindu", {
    shakha_id,
    staff_id,
    git,
    subhashit_amrutvachan,
    bodhkatha,
    charcha,
    samachar_samiksha,
    baudhik,
    dirgh_varta,
    date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
  });
  return response;
}
