const ExcelJS = require("exceljs");

export function exportGramSamelanExcelReport(YadiList) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("ગ્રામ સંમેલન યાદી", {
    views: [{ showGridLines: true }],
  });
  sheet.properties.defaultColWidth = 15;
  sheet.getRow(1).height = 60;
  sheet.getRow(1).style = {
    font: { size: 16, bold: true },
    alignment: { vertical: "middle", horizontal: "center", wrapText: true },
    border: {
      top: { style: "thin", color: { argb: "000" } },
      left: { style: "thin", color: { argb: "000" } },
      bottom: { style: "double", color: { argb: "000" } },
      right: { style: "thin", color: { argb: "000" } },
    },
  };

  sheet.columns = [
    {
      header: "ક્રમાંક",
      key: "count",
      width: 10,
    },
    {
      header: "નામ",
      key: "full_name",
      width: 32,
    },
    {
      header: "જાતિ",
      key: "gender",
      width: 20,
    },
    {
      header: "મોબાઇલ નંબર",
      key: "mobileno",
      width: 20,
    },
    {
      header: "ઈ. મેઈલ આઈ. ડી",
      key: "email",
      width: 25,
    },
    {
      header: "અભ્યાસ",
      key: "abhyas",
      width: 35,
    },
    {
      header: "વ્યવસાય",
      key: "vyavasay",
      width: 35,
    },
    {
      header: "અન્ય વ્યવસાય",
      key: "anya_vyavasay",
      width: 35,
    },
    {
      header: "શેરી / મહોલ્લા",
      key: "street_name",
      width: 35,
    },
    {
      header: "ગામ",
      key: "village_name",
      width: 35,
    },
    {
      header: "મંડલ",
      key: "mandal_name",
      width: 35,
    },
    {
      header: "તાલુકો",
      key: "taluka_name",
      width: 35,
    },
    {
      header: "જિલ્લો",
      key: "district_name",
      width: 35,
    },
    {
      header: "પસંદગીનું કામ",
      key: "pasandagi_kam",
      width: 35,
    },
    {
      header: "પસંદગીનું કામનો પ્રકાર",
      key: "pasandagi_sub_kam",
      width: 35,
    },
    {
      header: "કાર્ય સાથે જોડાયેલ",
      key: "pasandagi_jodayel_karya",
      width: 35,
    },
    {
      header: "અન્ય કાર્ય",
      key: "anya_karya",
      width: 35,
    },
  ];

  let count_excel = 1;
  YadiList?.map((yadi, index) => {
    let row = sheet.addRow({
      count: count_excel++,
      full_name: yadi?.full_name,
      gender: yadi?.gender,
      mobileno: yadi?.mobileno,
      email: yadi?.email,
      abhyas: yadi?.abhyas,
      vyavasay: yadi?.vyavasay,
      anya_vyavasay: yadi?.anya_vyavasay,
      street_name: yadi?.street_name,
      village_name: yadi?.village_name,
      mandal_name: yadi?.mandal_name,
      taluka_name: yadi?.taluka_name,
      district_name: yadi?.district_name,
      pasandagi_kam: yadi?.pasandagi_kam,
      pasandagi_sub_kam: yadi?.pasandagi_sub_kam,
      pasandagi_jodayel_karya: yadi?.pasandagi_jodayel_karya,
      anya_karya: yadi?.anya_karya,
    });
    row.eachCell(function (cell) {
      cell.style = {
        border: {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        },
        font: { size: 14 },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
      };
    });
    row.style = {
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      font: { size: 14 },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
    };
    return true;
  });

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "ગ્રામ સંમેલન યાદી.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}
