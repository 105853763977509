import { Box, Card, Grid, styled } from "@mui/material";
import { H3 } from "app/components/Typography";
import Layout from "../Layout";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getShakha } from "app/service/api-service/shakha-service";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const VrutFilter = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { type } = state;
  const { data: shakhaList, isLoading } = useQuery(["getShakha"], getShakha);
  return (
    <Layout
      isLoading={isLoading || false}
      title="શાખા"
      content={
        <Grid container spacing={0} sx={{ mb: "24px", mt: 0.5 }}>
          {shakhaList?.map((shakha, index) => (
            <Grid container spacing={0} key={index}>
              <Grid
                sx={{ mt: 3 }}
                item
                lg={1.5}
                xs={2.8}
                onClick={() => {
                  navigate("/dashboard/" + type + "/view_patrak", {
                    state: { shakha: shakha },
                  });
                }}
              >
                <StyledCard
                  elevation={6}
                  sx={{ borderRadius: "8px 0 0 8px", height: "100%" }}
                >
                  <ContentBox>
                    <Box>
                      <H3 sx={{ fontWeight: "bold", textAlign: "center" }}>
                        &nbsp;({index + 1})&nbsp;
                      </H3>
                    </Box>
                  </ContentBox>
                </StyledCard>
              </Grid>
              <Grid
                sx={{ mt: 3 }}
                item
                lg={10.5}
                xs={9.2}
                onClick={() => {
                  navigate("/dashboard/" + type + "/view_patrak", {
                    state: { shakha: shakha },
                  });
                }}
              >
                <StyledCard elevation={6} sx={{ borderRadius: "0 8px 8px 0" }}>
                  <ContentBox>
                    <Box>
                      <H3 sx={{ fontWeight: "bold", textAlign: "center" }}>
                        {shakha.shakha_name}
                      </H3>
                    </Box>
                  </ContentBox>
                </StyledCard>
              </Grid>
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};

export default VrutFilter;
