import * as React from "react";
import { Icon, Tooltip, IconButton, Button } from "@mui/material";
import Layout from "../../Layout";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import AddSevaVasti from "./AddSevaVasti";
import { useConfirm } from "material-ui-confirm";
import EditSevaVasti from "./EditSevaVasti";
import MyDashDataTable from "app/components/MyDashDataTable";
import {
  deleteSevaVasti,
  getSevaVasti,
} from "app/service/api-service/sevavasti-service";

const SevaVasti = () => {
  const { data: vastiList, isLoading } = useQuery(
    ["getSevaVasti"],
    getSevaVasti
  );

  const confirm = useConfirm();
  const [addopen, setAddopen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();
  const DeleteSevaVastiMutation = useMutation(deleteSevaVasti, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["getSevaVasti"], (MainData) => {
        return MainData.filter((sevavasti) => {
          return sevavasti._id !== data.sevavasti._id;
        });
      });
    },
  });

  const handleDelete = async (id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ સેવા વસ્તી કાઢી નાખવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        DeleteSevaVastiMutation.mutate(id);
      })
      .catch(() => {});
  };

  const handleClickOpen = (type) => {
    if (type === "edit") {
      setEditopen(true);
    } else {
      setAddopen(true);
    }
  };
  const handleClose = () => {
    setAddopen(false);
    setEditopen(false);
    setEdit(false);
  };
  const columns = [
    { name: "ક્રમાંક", options: { filterOptions: { fullWidth: true } } },
    "સેવા વસ્તી",
    "શાખા",
    "સેવા કાર્યકર્તા",
    "પાઠદાન કેન્દ્ર",
    "સંસ્કાર કેન્દ્ર",
    "આરોગ્ય કેન્દ્ર",
    "સ્વાવલંબન",
    {
      name: "ક્રિયાઓ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false,
      },
    },
  ];
  const options = {
    customToolbar: () => {
      return (
        <Tooltip title="સેવા વસ્તી ઉમેરો" placement="bottom">
          <Button
            variant="contained"
            color="primary"
            style={{ height: 36, margin: "10px 0 10px 10px" }}
            onClick={() => handleClickOpen("add")}
          >
            સેવા વસ્તી ઉમેરો
          </Button>
        </Tooltip>
      );
    },
  };
  const data = vastiList?.map((sevavasti, index) => [
    index + 1,
    sevavasti.seva_vasti,
    sevavasti.shakha_id.shakha_name,
    sevavasti?.seva_karyakarta?.map(
      (karyakarta, index) =>
        "(" +
        (index + 1) +
        ") " +
        karyakarta?.swayamsevak_id?.name +
        "\nમોં.: " +
        karyakarta?.swayamsevak_id?.mobileno +
        "\n"
    ),
    sevavasti.pathdan_kendra.name +
      "\nમોં.: " +
      sevavasti?.pathdan_kendra?.mobileno +
      "\nસંખ્યા: " +
      sevavasti?.pathdan_kendra?.vidhyarthi_sankhya,
    sevavasti.sanskar_kendra.name +
      "\nમોં.: " +
      sevavasti?.sanskar_kendra?.mobileno +
      "\nસંખ્યા: " +
      sevavasti?.sanskar_kendra?.vidhyarthi_sankhya,
    sevavasti.aarogya_kendra.name +
      "\nમોં.: " +
      sevavasti?.aarogya_kendra?.mobileno,
    sevavasti.svavlamban.name + "\nમોં.: " + sevavasti?.svavlamban?.mobileno,
    <>
      <Tooltip title="સંપાદિત કરો" placement="bottom">
        <IconButton
          onClick={() => {
            setEdit({
              ...sevavasti,
            });
            handleClickOpen("edit");
          }}
        >
          <Icon color="primary">edit</Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title="કાઢી નાખો" placement="bottom">
        <IconButton
          onClick={() => {
            handleDelete(sevavasti._id);
          }}
        >
          <Icon color="error">delete</Icon>
        </IconButton>
      </Tooltip>
    </>,
  ]);

  return (
    <Layout
      isLoading={isLoading || false}
      title="સેવા વસ્તી"
      content={
        <>
          <MyDashDataTable
            filename="સેવા વસ્તી.csv"
            data={data}
            columns={columns}
            options={options}
          />
          {addopen && <AddSevaVasti handleClose={handleClose} open={addopen} />}
          {edit && (
            <EditSevaVasti
              handleClose={handleClose}
              open={editopen}
              edit={edit}
            />
          )}
        </>
      }
    />
  );
};

export default SevaVasti;
