import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getNagarPravasdin() {
  const response = await axiosInstance.get(
    "api/nagarpravasdin/fetchallnagarpravasdin"
  );
  return response.data;
}

export async function getNagarPravasdinYears() {
  const response = await axiosInstance.get(
    "api/nagarpravasdin/fetchnagarpravasdinyears"
  );
  return response.data;
}

export async function getNagarPravasdinbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/nagarpravasdin/fetchbymonthnagarpravasdin",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addNagarPravasdin({
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/nagarpravasdin/addnagarpravasdin",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateNagarPravasdin({
  _id: id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/nagarpravasdin/updatenagarpravasdin/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteNagarPravasdin(id) {
  const response = await axiosInstance.delete(
    `api/nagarpravasdin/deletenagarpravasdin/${id}`
  );
  return response;
}
