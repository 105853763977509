import React, { createContext, useReducer } from "react";
import { MatxLoading } from "app/components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Login,
  SwaymsevakLogin,
  getProfile,
} from "app/service/api-service/auth-service";
import { useMutation, useQuery } from "react-query";
import { AvatarGenerator } from "random-avatar-generator";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const generator = new AvatarGenerator();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.removeItem("accessToken");
  }
};

const setUserData = (userdata, is_swaymsevak = false) => {
  if (is_swaymsevak) {
    return {
      id: userdata._id,
      avatar: generator.generateRandomAvatar(userdata.name + userdata.mobileno),
      email: "",
      mobileno: userdata.mobileno,
      name: userdata.name,
      role_level: "સ્વયંસેવક સ્તર",
      role: "સ્વયંસેવક",
      role_id: "",
      address: userdata?.address ? userdata?.address : null,
      vyavsahay: userdata?.vyavsahay ? userdata?.vyavsahay : null,
      sanghshikshan: userdata?.sanghshikshan ? userdata?.sanghshikshan : null,
      karyaruchi: userdata?.karyaruchi ? userdata?.karyaruchi : null,
      is_gatnayak: userdata?.is_gatnayak ? userdata?.is_gatnayak : null,
      shakhatoli_role: userdata?.shakhatoli_role
        ? userdata?.shakhatoli_role
        : null,
      nagar: userdata?.shakha_id?.nagar_id?.nagar_name
        ? userdata?.shakha_id?.nagar_id?.nagar_name
        : null,
      nagar_id: userdata?.shakha_id?.nagar_id?._id
        ? userdata?.shakha_id?.nagar_id?._id
        : null,
      upnagar: userdata?.shakha_id?.upnagar_id?.upnagar_name
        ? userdata?.shakha_id?.nagar_id?.upnagar_name
        : null,
      upnagar_id: userdata?.shakha_id?.upnagar_id?._id
        ? userdata?.shakha_id?.nagar_id?._id
        : null,
      vasti: userdata?.shakha_id?.vasti_id?.vasti_name
        ? userdata?.shakha_id?.nagar_id?.vasti_name
        : null,
      vasti_id: userdata?.shakha_id?.vasti_id?._id
        ? userdata?.shakha_id?.nagar_id?._id
        : null,
      shakha: userdata?.shakha_id?.shakha_name
        ? userdata?.shakha_id?.shakha_name
        : null,
      shakha_id: userdata?.shakha_id?._id ? userdata?.shakha_id?._id : null,
    };
  } else {
    return {
      id: userdata._id,
      avatar: generator.generateRandomAvatar(userdata.name),
      email: "",
      mobileno: userdata.mobileno,
      name: userdata.name,
      role_level: userdata.role_id.role_level,
      role: userdata.role_id.role_name,
      role_id: userdata.role_id._id,
      address: userdata?.address ? userdata?.address : null,
      nagar: userdata.nagar_id ? userdata.nagar_id.nagar_name : null,
      nagar_id: userdata.nagar_id ? userdata.nagar_id._id : null,
      upnagar: userdata.upnagar_id ? userdata.upnagar_id.upnagar_name : null,
      upnagar_id: userdata.upnagar_id ? userdata.upnagar_id._id : null,
      vasti: userdata.vasti_id ? userdata.vasti_id.vasti_name : null,
      vasti_id: userdata.vasti_id ? userdata.vasti_id._id : null,
      rahenak_vasti: userdata.rahenak_vasti_id
        ? userdata.rahenak_vasti_id.vasti_name
        : null,
      rahenak_vasti_id: userdata.rahenak_vasti_id
        ? userdata.rahenak_vasti_id._id
        : null,
      shakha: userdata.shakha_id ? userdata?.shakha_id?.shakha_name : null,
      shakha_id: userdata.shakha_id ? userdata.shakha_id._id : null,
    };
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "SWAYMSEVAK_LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();

  const LoginMutation = useMutation(Login, {
    onSuccess: ({ data }) => {
      const { authtoken, userdata } = data;
      const user = setUserData(userdata);
      setSession(authtoken);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
      navigate("/");
      return true;
    },
  });

  const SwaymsevakLoginMutation = useMutation(SwaymsevakLogin, {
    onSuccess: ({ data }) => {
      const { authtoken, userdata } = data;
      const user = setUserData(userdata, true);
      setSession(authtoken);
      dispatch({
        type: "SWAYMSEVAK_LOGIN",
        payload: {
          user,
        },
      });
      navigate("/");
      return true;
    },
  });

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  useQuery(["getProfile"], getProfile, {
    onSuccess: ({ userdata }) => {
      if (userdata) {
        let user;
        if (userdata?.is_swaymsevak) {
          user = setUserData(userdata?.data, true);
        } else {
          user = setUserData(userdata?.data);
        }
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
        navigate(location.pathname, {
          state: location.state,
        });
      } else {
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        navigate("/");
      }
    },
  });

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        LoginMutation,
        SwaymsevakLoginMutation,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
