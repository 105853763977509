import axiosInstance from "axios-instance";

export async function getNagarSamiti() {
  const response = await axiosInstance.get(
    "api/nagarsamiti/fetchallnagarsamiti"
  );
  return response.data;
}

export async function addNagarSamiti({
  name,
  mobileno,
  sangh_parivar,
  sanyojak,
  vishesh_nondh,
}) {
  const response = await axiosInstance.post("api/nagarsamiti/addnagarsamiti", {
    name,
    mobileno,
    sangh_parivar,
    sanyojak,
    vishesh_nondh,
  });
  return response;
}

export async function updateNagarSamiti({
  _id: id,
  name,
  mobileno,
  sangh_parivar,
  sanyojak,
  vishesh_nondh,
}) {
  const response = await axiosInstance.put(
    `api/nagarsamiti/updatenagarsamiti/${id}`,
    {
      name,
      mobileno,
      sangh_parivar,
      sanyojak,
      vishesh_nondh,
    }
  );
  return response;
}

export async function deleteNagarSamiti(id) {
  const response = await axiosInstance.delete(
    `api/nagarsamiti/deletenagarsamiti/${id}`
  );
  return response;
}
