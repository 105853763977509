import { Grid, styled, Fab, Icon, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
const ContentBox = styled("div")(({ theme }) => ({
  margin: "10px",
  padding: "15px 20px",
  background: "#fff",
  boxShadow: "-3px 4px 15px 0px rgb(92 92 92 / 75%)",
  borderRadius: "5px",
  [theme.breakpoints.down("sm")]: { margin: "10px" },
}));
const CardTitle = styled("div")(({ subtitle, theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  marginBottom: !subtitle && "10px",
  textAlign: "center",
  color: "#2c3c6e",
  width: "100%",
  [theme.breakpoints.down("sm")]: { marginLeft: "40px" },
}));
const Header = styled("div")(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  color: "#2c3c6e",
  borderBottom: "2px dashed #2c3c6e",
}));
const ButtonFab = styled(Fab)(({ theme }) => ({
  position: "absolute",
  height: "40px",
  width: "40px",
  color: "#fff",
  zIndex: "110",
  background: "#465278",
  boxShadow: "none",
  ":hover": {
    background: "#364c93",
  },
}));

const Layout = (props) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <ContentBox className="analytics">
        <Header>
          <Tooltip title="પાછળ જાઓ" placement="bottom">
            <ButtonFab
              variant="primary"
              color="primary"
              className="button"
              onClick={() => navigate(-1)}
            >
              <Icon sx={{ fontWeight: 900 }}>arrow_back</Icon>
            </ButtonFab>
          </Tooltip>
          <CardTitle>{props.title}</CardTitle>
        </Header>
        <Grid container spacing={3}>
          {props.isLoading ? (
            <div className="matx-loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {props.content}
            </Grid>
          )}
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default Layout;
