import { Grid } from "@mui/material";
import Chart from "react-apexcharts";
import Layout from "../Layout";
import { useState } from "react";
import { useQuery } from "react-query";
import { getShakha } from "app/service/api-service/shakha-service";

const VartmanSthiti = () => {
  const [state, setState] = useState({
    series: [0, 0],
    options: {
      chart: {
        width: "auto",
        type: "pie",
      },
      labels: ["શાખા", "સાપ્તાહિક શાખા"],
      responsive: [
        {
          breakpoint: undefined,
          options: {},
        },
      ],
    },
  });
  useQuery(["getShakha"], getShakha, {
    onSuccess: (data) => {
      setState({
        ...state,
        series: [
          data?.filter((shakha) => shakha.is_saptahik === 0).length,
          data?.filter((shakha) => shakha.is_saptahik === 1).length,
        ],
      });
    },
  });

  return (
    <Layout
      title="વર્તમાન સ્થિતિ"
      content={
        <Grid
          container
          spacing={3}
          sx={{ mb: "24px", mt: 0.5, justifyContent: "center" }}
        >
          <Grid item xs={12} sm={4} key={0} sx={{ justifyContent: "center" }}>
            <Chart options={state?.options} series={state?.series} type="pie" />
          </Grid>
        </Grid>
      }
    />
  );
};

export default VartmanSthiti;
