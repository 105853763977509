import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Upnagar = Loadable(lazy(() => import("./Upnagar")));

const UpnagarRoutes = [
  {
    path: "/upnagar/allupnagar",
    element: <Upnagar />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
];

export default UpnagarRoutes;
