import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getRammandirvrut() {
  const response = await axiosInstance.get(
    "api/rammandirvrut/fetchallrammandirvrut"
  );
  return response.data;
}

export async function getRammandirvrutbyVasti({ vasti_id }) {
  const response = await axiosInstance.get(
    "api/rammandirvrut/fetchbyvastirammandirvrut",
    {
      params: {
        vasti_id,
      },
    }
  );
  return response.data;
}

export async function getRammandirvrutwithupnagarbyDate({ date }) {
  const response = await axiosInstance.post(
    "api/rammandirvrut/fetchallrammandirvrutwithupnagarbydate",
    {
      date: dayjs(date).format("YYYY-MM-DD"),
    }
  );
  return response;
}

export async function addRammandirvrut({
  date,
  vasti_id,
  ghar_sampark_karyakarta_sankhya,
  ghar_sampark_sankhya,
}) {
  const response = await axiosInstance.post(
    "api/rammandirvrut/addrammandirvrut",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      vasti_id,
      ghar_sampark_karyakarta_sankhya,
      ghar_sampark_sankhya,
    }
  );
  return response;
}

export async function updateRammandirvrut({
  _id: id,
  date,
  vasti_id,
  ghar_sampark_karyakarta_sankhya,
  ghar_sampark_sankhya,
}) {
  const response = await axiosInstance.put(
    `api/rammandirvrut/updaterammandirvrut/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      vasti_id,
      ghar_sampark_karyakarta_sankhya,
      ghar_sampark_sankhya,
    }
  );
  return response;
}

export async function deleteRammandirvrut(id) {
  const response = await axiosInstance.delete(
    `api/rammandirvrut/deleterammandirvrut/${id}`
  );
  return response;
}
