import axiosInstance from "axios-instance";

export async function getKaryakarta() {
  const response = await axiosInstance.get("api/karyakarta/fetchallyadi");
  return response.data;
}

export async function getKaryakartaDistrict() {
  const response = await axiosInstance.get("api/karyakarta/fetchalldistrict");
  return response.data;
}

export async function getKaryakartaTalukabyDistrict(district_name) {
  const response = await axiosInstance.get(
    `api/karyakarta/fetchalltalukabydistrict/${district_name}`
  );
  return response;
}

export async function addKaryakarta({
  district_name,
  taluka_name,
  full_name,
  mobileno,
  sthan,
  dayitva,
}) {
  const response = await axiosInstance.post("api/karyakarta/add_yadi", {
    district_name,
    taluka_name,
    full_name,
    mobileno,
    sthan,
    dayitva,
  });
  return response;
}

export async function updateKaryakarta({
  _id: id,
  district_name,
  taluka_name,
  full_name,
  mobileno,
  sthan,
  dayitva,
}) {
  const response = await axiosInstance.put(`api/karyakarta/updateyadi/${id}`, {
    district_name,
    taluka_name,
    full_name,
    mobileno,
    sthan,
    dayitva,
  });
  return response;
}

export async function deleteKaryakarta(id) {
  const response = await axiosInstance.delete(
    `api/karyakarta/deleteyadi/${id}`
  );
  return response;
}
