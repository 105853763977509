import {
  Chip,
  Icon,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MyFormDialogBox from "app/components/MyFormDialogBox";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& tbody": {
    "& tr": {
      "& td": {
        paddingLeft: 0,
        textTransform: "capitalize",
        fontSize: "17px",
        "& a": {
          color: "#3b3ea1",
        },
      },
      "& td:nth-of-type(3)": {
        fontWeight: "600",
      },
    },
  },
}));

const ViewYadiDetails = (props) => {
  const yadidata = props.yadiData;

  return (
    <MyFormDialogBox
      isDetail={true}
      open={props.open}
      handleClose={() => {
        props.handleClose();
      }}
      title="વિગતો"
      content={
        <StyledTable sx={{ mt: "-15px" }}>
          <TableBody>
            <TableRow>
              <TableCell align="left" width="30%">
                નામ
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                {yadidata.name}
                {yadidata.is_sampark_pramukh === 1 && (
                  <>
                    <br />
                    <Chip
                      sx={{ mt: 1 }}
                      label={<b>સંપર્ક પ્રમુખ</b>}
                      color="primary"
                    />
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" width="30%">
                મોબાઇલ નંબર
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                <a
                  href={"tel:" + yadidata.mobileno}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "inline-flex" }}
                >
                  <Icon>phone</Icon>
                  &nbsp;{yadidata.mobileno}
                </a>
                <br />
                <a
                  href={`https://api.whatsapp.com/send?phone=91${yadidata.mobileno}&text=Hello`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "inline-flex" }}
                >
                  <WhatsAppIcon />
                  &nbsp;WhatsApp
                </a>
              </TableCell>
            </TableRow>
            {yadidata.address !== "" && (
              <TableRow>
                <TableCell align="left" width="30%">
                  સરનામું
                </TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{yadidata.address}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      }
    />
  );
};

export default ViewYadiDetails;
