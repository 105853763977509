import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, Box } from "@mui/material";
import { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import MyDatePicker from "app/components/MyDatePicker";
import { getRammandirvrutwithupnagarbyDate } from "app/service/api-service/rammandirvrut-service";
import DateRamMandirVrutPDF from "./DateRamMandirVrutPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

const DateRamMandirVrut = (props) => {
  const [state, setState] = useState({
    date: dayjs(),
  });
  const [PDFLoading, setPDFLoading] = useState(false);

  React.useEffect(() => {
    ValidatorForm.addValidationRule("isValidDate", (value) => {
      return dayjs(value, "DD-MM-YYYY", true).isValid();
    });
    return () => ValidatorForm.removeValidationRule("isValidDate");
  }, [state.date]);

  const DateRammandirVrutMutation = useMutation(
    getRammandirvrutwithupnagarbyDate,
    {
      onSuccess: ({ data }) => {
        DownloadPDF(data);
      },
    }
  );

  const DownloadPDF = async (vrutList) => {
    setPDFLoading(true);
    const doc = (
      <DateRamMandirVrutPDF
        vrutList={vrutList}
        date={dayjs(state.date).format("DD-MM-YYYY")}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(
      blob,
      "રામ_મંદિર_વૃત_" + dayjs(state.date).format("YYYY_MM_DD") + ".pdf"
    );
    setPDFLoading(false);
    setState({
      date: dayjs(),
    });
    props.handleClose();
  };

  const handleSubmit = async () => {
    DateRammandirVrutMutation.mutate(state);
  };

  const handleDateChange = (date) => {
    setState({ ...state, date });
  };

  return (
    <MyDialogBox
      open={props.open}
      maxWidth="xs"
      handleClose={() => {
        props.handleClose();
        setState({
          date: dayjs(),
        });
      }}
      title="તારીખ પ્રમાણે વૃત રિપોર્ટ"
      content={
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <MyDatePicker
                label="તારીખ *"
                value={state.date || ""}
                onChange={handleDateChange}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={DateRammandirVrutMutation.isLoading || PDFLoading}
              variant="contained"
              type="submit"
            >
              ડાઉનલોડ રિપોર્ટ
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default DateRamMandirVrut;
