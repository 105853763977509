import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Vasti = Loadable(lazy(() => import("./Vasti")));

const VastiRoutes = [
  {
    path: "/vasti/allvasti",
    element: <Vasti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
];

export default VastiRoutes;
