import RamMandir from "./RamMandir";
import Samiti from "./samiti/Samiti";
import LocalSamiti from "./samiti/local_samiti/LocalSamiti";
import ViewLocalSamiti from "./samiti/local_samiti/ViewLocalSamiti";
import NagarSamiti from "./samiti/nagar_samiti/NagarSamiti";
import UpnagarSamiti from "./samiti/upnagar_samiti/UpnagarSamiti";
import ViewUpnagarSamiti from "./samiti/upnagar_samiti/ViewUpnagarSamiti";
import VastiSamiti from "./samiti/vasti_samiti/VastiSamiti";
import ViewVastiSamiti from "./samiti/vasti_samiti/ViewVastiSamiti";
import RamMandirVrut from "./vrut/RamMandirVrut";
import ViewVastiVrut from "./vrut/ViewVastiVrut";

const rammandirRoutes = [
  {
    path: "/dashboard/ram_mandir_sampark",
    element: <RamMandir />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/vrut",
    element: <RamMandirVrut />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/vrut/vasti",
    element: <ViewVastiVrut />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/samiti",
    element: <Samiti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/samiti/nagar",
    element: <NagarSamiti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/samiti/upnagar",
    element: <UpnagarSamiti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/samiti/upnagar/view",
    element: <ViewUpnagarSamiti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/samiti/vasti",
    element: <VastiSamiti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/samiti/vasti/view",
    element: <ViewVastiSamiti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/samiti/local",
    element: <LocalSamiti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
  {
    path: "/dashboard/ram_madir_sampark/samiti/local/view",
    element: <ViewLocalSamiti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર"],
  },
];

export default rammandirRoutes;
