import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getSharirikVarg() {
  const response = await axiosInstance.get(
    "api/sharirikvarg/fetchallsharirikvarg"
  );
  return response.data;
}

export async function getSharirikVargYears() {
  const response = await axiosInstance.get(
    "api/sharirikvarg/fetchsharirikvargyears"
  );
  return response.data;
}

export async function getSharirikVargbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/sharirikvarg/fetchbymonthsharirikvarg",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addSharirikVarg({
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/sharirikvarg/addsharirikvarg",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateSharirikVarg({
  _id: id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/sharirikvarg/updatesharirikvarg/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteSharirikVarg(id) {
  const response = await axiosInstance.delete(
    `api/sharirikvarg/deletesharirikvarg/${id}`
  );
  return response;
}
