import {
  Alert,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  styled,
} from "@mui/material";
import { H3 } from "app/components/Typography";
import Layout from "../Layout";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import AddKaryakarta from "./AddKaryakarta";
import ViewKaryakarta from "./ViewKaryakarta";
import EditKaryakarta from "./EditKaryakarta";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useConfirm } from "material-ui-confirm";
import {
  deleteKaryakarta,
  getKaryakarta,
} from "app/service/api-service/karyakarta-service";
import useAuth from "app/hooks/useAuth";
import { exportKaryakartaExcelReport } from "./KaryakartaExcel";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const Karyakarta = () => {
  const confirm = useConfirm();
  const { user } = useAuth();
  const [detailopen, setDetailOpen] = useState(false);
  const [yadiData, setYadiData] = useState(false);
  const [addopen, setAddopen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [edit, setEdit] = useState(false);

  const queryClient = useQueryClient();
  const { data: YadiList, isLoading: yadiLoading } = useQuery(
    ["getKaryakarta"],
    getKaryakarta
  );

  const DeleteKaryakartaMutation = useMutation(deleteKaryakarta, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["getKaryakarta"], (YadiList) => {
        return YadiList.filter((yadi) => {
          return yadi._id !== data.yadi._id;
        });
      });
    },
  });

  const handleClickOpen = (type) => {
    if (type === "edit") {
      setEditopen(true);
    } else if (type === "detail") {
      setDetailOpen(true);
    } else {
      setAddopen(true);
    }
  };

  const handleClose = () => {
    setDetailOpen(false);
    setYadiData(false);
    setEditopen(false);
    setEdit(false);
    setAddopen(false);
  };

  const handleDelete = async (id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ રેકોર્ડ હટાવવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        DeleteKaryakartaMutation.mutate(id);
      })
      .catch(() => {});
  };

  return (
    <Layout
      title="કાર્યકર્તા"
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              pb: 2,
              borderBottom: "2px dotted #2c3c6e",
            }}
          >
            <LoadingButton
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              style={{
                minHeight: 40,
                margin: "10px 0",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">add</Icon> &nbsp;યાદી ઉમેરો
            </LoadingButton>
            {["માહિતી વિભાગ"].includes(user.role) && (
              <LoadingButton
                onClick={() =>
                  YadiList && exportKaryakartaExcelReport(YadiList)
                }
                loading={yadiLoading}
                variant="contained"
                color="primary"
                style={{
                  minHeight: 36,
                  margin: "10px 0 10px 10px",
                  fontWeight: "bold",
                }}
              >
                <Icon className="icon">text_snippet</Icon> &nbsp;ડાઉનલોડ EXCEL
                રિપોર્ટ
              </LoadingButton>
            )}
          </Box>
          {["માહિતી વિભાગ"].includes(user.role) && (
            <>
              <Grid container spacing={0} sx={{ mb: "24px", mt: 0.5 }}>
                {YadiList?.map((val, index) => (
                  <Grid container spacing={0} key={index}>
                    <Grid sx={{ mt: 3 }} item lg={1.5} xs={2.8}>
                      <StyledCard
                        elevation={6}
                        sx={{ borderRadius: "8px 0 0 8px", height: "100%" }}
                      >
                        <ContentBox>
                          <Box>
                            <H3
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              &nbsp;({index + 1})&nbsp;
                            </H3>
                          </Box>
                        </ContentBox>
                      </StyledCard>
                    </Grid>
                    <Grid sx={{ mt: 3 }} item lg={10.5} xs={9.2}>
                      <StyledCard
                        elevation={6}
                        sx={{ borderRadius: "0 8px 8px 0" }}
                      >
                        <ContentBox>
                          <Box sx={{ fontWeight: "bold", textAlign: "center" }}>
                            <H3
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              {val.full_name}
                            </H3>
                            <Tooltip title="વધુ વિગતો" placement="bottom">
                              <IconButton
                                onClick={() => {
                                  setYadiData(val);
                                  handleClickOpen("detail");
                                }}
                              >
                                <Icon color="primary">reorder</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="સંપાદિત કરો" placement="bottom">
                              <IconButton
                                onClick={() => {
                                  setEdit({
                                    ...val,
                                  });
                                  handleClickOpen("edit");
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="કાઢી નાખો" placement="bottom">
                              <IconButton
                                onClick={() => {
                                  handleDelete(val._id);
                                }}
                              >
                                <Icon color="error">delete</Icon>
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </ContentBox>
                      </StyledCard>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {YadiList?.length < 1 && (
                <Alert severity="info">
                  <b>કોઈ ડેટા મળ્યો નથી</b>
                </Alert>
              )}
              {yadiData && (
                <ViewKaryakarta
                  handleClose={handleClose}
                  open={detailopen}
                  yadiData={yadiData}
                />
              )}
              {edit && (
                <EditKaryakarta
                  handleClose={handleClose}
                  open={editopen}
                  edit={edit}
                />
              )}
            </>
          )}
          {addopen && (
            <AddKaryakarta
              handleClose={handleClose}
              open={editopen}
              edit={edit}
            />
          )}
        </>
      }
    />
  );
};

export default Karyakarta;
