import {
  styled,
  Tooltip,
  Box,
  Tabs,
  Tab,
  Icon,
  Grid,
  Fab,
} from "@mui/material";
import { Fragment } from "react";
import { useState } from "react";
import { getCityLevelCommunityPost } from "app/service/api-service/community-service";
import Feed from "../community/Feed";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  cursor: "pointer",
  background: theme.palette.background.paper,
}));
const ContentBox = styled("div")(({ theme }) => ({
  margin: "10px",
  padding: "10px 0",
  [theme.breakpoints.down("sm")]: { margin: "10px" },
}));
const ButtonFab = styled(Fab)(({ theme }) => ({
  height: "40px",
  minWidth: "40px",
  color: "#fff",
  zIndex: "110",
  background: "#465278",
  boxShadow: "none",
  marginRight: "5px",
  ":hover": {
    background: "#364c93",
  },
}));

const PublicCommunity = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`community-tabpanel-${index}`}
        aria-labelledby={`community-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: "10px" }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `community-tabpanel-${index}`,
    };
  }

  const PostLimit = 3;
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    isFetching,
    isRefetching,
    refetch,
  } = useInfiniteQuery(
    ["getCityLevelCommunityPost"],
    ({ pageParam = 0 }) => getCityLevelCommunityPost(pageParam, PostLimit),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.length === PostLimit
            ? allPages.length * PostLimit
            : undefined;
        return nextPage;
      },
    }
  );

  return (
    <Fragment>
      <ContentBox className="analytics" id="main-content-container">
        <Grid container spacing={1} sx={{ justifyContent: "center" }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                paddingRight: "10px",
              }}
            >
              <Tooltip title="પાછળ જાઓ" placement="bottom">
                <ButtonFab
                  variant="primary"
                  color="primary"
                  className="button"
                  onClick={() => navigate(-1)}
                >
                  <Icon sx={{ fontWeight: 900 }}>arrow_back</Icon>
                </ButtonFab>
              </Tooltip>
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="કૉમ્યુનિટી"
                sx={{
                  boxShadow: "0px 0px 5px 0px #00000033",
                  borderRadius: "10px",
                  minWidth: "91%",
                  "& button": {
                    borderBottom: "2px solid #93919f",
                    minWidth: "100%",
                  },
                }}
                centered
              >
                <Tab
                  icon={
                    <Tooltip title="ડેશબોર્ડ" placement="top">
                      <Icon sx={{ fontSize: "35px !important" }}>
                        grid_view
                      </Icon>
                    </Tooltip>
                  }
                  {...a11yProps(0)}
                />
              </StyledTabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Feed
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                data={data}
                isFetching={isFetching}
                isRefetching={isRefetching}
                refetch={refetch}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default PublicCommunity;
