import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getBaudhikvarg() {
  const response = await axiosInstance.get(
    "api/baudhikvarg/fetchallbaudhikvarg"
  );
  return response.data;
}

export async function getBaudhikVargYears() {
  const response = await axiosInstance.get(
    "api/baudhikvarg/fetchbaudhikvargyears"
  );
  return response.data;
}

export async function getBaudhikVargbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/baudhikvarg/fetchbymonthbaudhikvarg",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addBaudhikvarg({ date, sthan, upsthiti, vishay, vakta }) {
  const response = await axiosInstance.post("api/baudhikvarg/addbaudhikvarg", {
    date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
    sthan,
    upsthiti,
    vishay,
    vakta,
  });
  return response;
}

export async function updateBaudhikvarg({
  _id: id,
  date,
  sthan,
  upsthiti,
  vishay,
  vakta,
}) {
  const response = await axiosInstance.put(
    `api/baudhikvarg/updatebaudhikvarg/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      vishay,
      vakta,
    }
  );
  return response;
}

export async function deleteBaudhikvarg(id) {
  const response = await axiosInstance.delete(
    `api/baudhikvarg/deletebaudhikvarg/${id}`
  );
  return response;
}
