import { styled, Table, TableBody, TableCell, TableRow } from "@mui/material";
import MyFormDialogBox from "app/components/MyFormDialogBox";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& tbody": {
    "& tr": {
      "& td": { paddingLeft: 0, textTransform: "none", fontSize: "17px" },
      "& td:nth-of-type(3)": {
        fontWeight: "600",
      },
    },
  },
}));
const ViewDetail = (props) => {
  return (
    <MyFormDialogBox
      open={props.open}
      handleClose={() => {
        props.handleClose();
      }}
      title="કાર્યકર્તા વિગતો"
      content={
        <StyledTable sx={{ mt: "-15px" }}>
          <TableBody>
            <TableRow>
              <TableCell align="left" width="30%">
                નામ
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{props.detail.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">મોબાઇલ નંબર</TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{props.detail.mobileno}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">સરનામું</TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{props.detail.address}</TableCell>
            </TableRow>
            {props.detail.role_id && (
              <TableRow>
                <TableCell align="left">ભૂમિકા સ્તર</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.detail.role_id.role_level}
                </TableCell>
              </TableRow>
            )}
            {props.detail.role_id && (
              <TableRow>
                <TableCell align="left">ભૂમિકા</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.detail.role_id.role_name}
                </TableCell>
              </TableRow>
            )}
            {props.detail.nagar_id && (
              <TableRow>
                <TableCell align="left">નગર</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.detail.nagar_id.nagar_name}
                </TableCell>
              </TableRow>
            )}
            {props.detail.upnagar_id && (
              <TableRow>
                <TableCell align="left">ઉપનગર</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.detail.upnagar_id.upnagar_name}
                </TableCell>
              </TableRow>
            )}
            {props.detail.vasti_id && (
              <TableRow>
                <TableCell align="left">વસ્તી</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.detail.vasti_id.vasti_name}
                </TableCell>
              </TableRow>
            )}
            {props?.detail?.rahenak_vasti_id && (
              <TableRow>
                <TableCell align="left">વસ્તી (રહેણાંક)</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.detail.rahenak_vasti_id.vasti_name}
                </TableCell>
              </TableRow>
            )}
            {props.detail.shakha_id && (
              <TableRow>
                <TableCell align="left">શાખા</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.detail?.shakha_id?.shakha_name}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      }
    />
  );
};

export default ViewDetail;
