import React from "react";
import PostItem from "./postitem/PostItem";
import { useMutation } from "react-query";
import {
  deleteCommunityPost,
  likePost,
  updateCommunityPost,
} from "app/service/api-service/community-service";
import { useEffect } from "react";
import { Box, Grid, Icon, IconButton } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { CircularProgress } from "@mui/material";
import { H4 } from "app/components/Typography";
import { goToTop } from "app/utils/utils";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import EditPost from "./EditPost";

const Feed = ({
  handleEditOpen,
  handleEditClose,
  edit,
  editopen,
  setEdit,
  setEditopen,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  data,
  isFetching,
  isRefetching,
  refetch,
}) => {
  const confirm = useConfirm();
  const { ref, inView } = useInView();
  const [slientrefetch, setslientrefetch] = useState(true);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const LikeCommunityPostMutation = useMutation(likePost, {
    onSuccess: ({ data }) => {
      setslientrefetch(true);
      refetch();
    },
  });

  const DeleteCommunityPostMutation = useMutation(deleteCommunityPost, {
    onSuccess: ({ data }) => {
      setslientrefetch(true);
      refetch();
    },
  });

  const UpdateCommunityPostMutation = useMutation(updateCommunityPost, {
    onSuccess: ({ data }) => {
      setslientrefetch(true);
      refetch();
      setEdit(false);
      setEditopen(false);
    },
  });

  const handleDelete = async (id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ કૉમ્યુનિટી પોસ્ટ કાઢી નાખવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        DeleteCommunityPostMutation.mutate(id);
      })
      .catch(() => {});
  };

  return (
    <>
      {edit && (
        <EditPost
          handleEditClose={handleEditClose}
          open={editopen}
          edit={edit}
          UpdateCommunityPostMutation={UpdateCommunityPostMutation}
        />
      )}

      <Grid container sx={{ alignItems: "center", flexDirection: "column" }}>
        {!slientrefetch && isRefetching && (
          <CircularProgress
            sx={{ margin: "10px 0" }}
            className="circleProgress"
          />
        )}
        {data?.pages?.map((page) =>
          page?.map((postitem, i) => {
            if (page.length === i + 1) {
              return (
                <Grid
                  ref={ref}
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  key={postitem._id}
                >
                  <PostItem
                    {...postitem}
                    LikeCommunityPostMutation={LikeCommunityPostMutation}
                    handleDelete={handleDelete}
                    handleEditOpen={handleEditOpen}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid item lg={12} md={12} sm={12} xs={12} key={postitem._id}>
                  <PostItem
                    {...postitem}
                    LikeCommunityPostMutation={LikeCommunityPostMutation}
                    handleDelete={handleDelete}
                    handleEditOpen={handleEditOpen}
                  />
                </Grid>
              );
            }
          })
        )}
        <div>
          {data?.pages[0].length === 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "70vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                color="primary"
                sx={{ "& span": { fontSize: "45px" } }}
              >
                <Icon>assignment</Icon>
              </IconButton>
              <H4>અત્યારે જોવા માટે કોઈ પોસ્ટ નથી</H4>
            </Box>
          )}
          {isFetchingNextPage ? (
            <CircularProgress
              className="circleProgress"
              onClick={() => fetchNextPage()}
            />
          ) : hasNextPage ? (
            <CircularProgress
              className="circleProgress"
              onClick={() => fetchNextPage()}
            />
          ) : (
            data?.pages[0].length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  color="primary"
                  sx={{ "& span": { fontSize: "50px" } }}
                  onClick={() => {
                    goToTop();
                    setslientrefetch(false);
                    refetch();
                  }}
                >
                  <Icon>arrow_circle_up</Icon>
                </IconButton>
                <H4>બધી પોસ્ટ જોવાઈ ગઈ છે</H4>
              </Box>
            )
          )}
        </div>
        <div>
          {isFetching && !isFetchingNextPage ? (
            <CircularProgress className="circleProgress" />
          ) : null}
        </div>
      </Grid>
    </>
  );
};

export default Feed;
