import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getBalVarg() {
  const response = await axiosInstance.get("api/balvarg/fetchallbalvarg");
  return response.data;
}

export async function getBalVargYears() {
  const response = await axiosInstance.get("api/balvarg/fetchbalvargyears");
  return response.data;
}

export async function getBalVargbyMonth({ month, year }) {
  const response = await axiosInstance.get("api/balvarg/fetchbymonthbalvarg", {
    params: {
      month,
      year,
    },
  });
  return response.data;
}

export async function addBalVarg({ date, sthan, upsthiti, bal, tarun, yog }) {
  const response = await axiosInstance.post("api/balvarg/addbalvarg", {
    date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
    sthan,
    upsthiti,
    bal,
    tarun,
    yog,
  });
  return response;
}

export async function updateBalVarg({
  _id: id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(`api/balvarg/updatebalvarg/${id}`, {
    date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
    sthan,
    upsthiti,
    bal,
    tarun,
    yog,
  });
  return response;
}

export async function deleteBalVarg(id) {
  const response = await axiosInstance.delete(
    `api/balvarg/deletebalvarg/${id}`
  );
  return response;
}
