import axiosInstance from "axios-instance";

export async function getGramSamelan() {
  const response = await axiosInstance.get("api/gramsamelan/fetchallyadi");
  return response.data;
}

export async function getGramSamelanDistrict() {
  const response = await axiosInstance.get("api/gramsamelan/fetchalldistrict");
  return response.data;
}

export async function getGramSamelanTalukabyDistrict(district_name) {
  const response = await axiosInstance.get(
    `api/gramsamelan/fetchalltalukabydistrict/${district_name}`
  );
  return response;
}

export async function getGramSamelanMandalbyTaluka(taluka_name) {
  const response = await axiosInstance.get(
    `api/gramsamelan/fetchallmandalbytaluka/${taluka_name}`
  );
  return response;
}

export async function getGramSamelanVillagebyMandal(mandal_name) {
  const response = await axiosInstance.get(
    `api/gramsamelan/fetchallvillagebymandal/${mandal_name}`
  );
  return response;
}

export async function addGramSamelan({
  district_name,
  taluka_name,
  mandal_name,
  village_name,
  pasandagi_kam,
  pasandagi_sub_kam,
  pasandagi_jodayel_karya,
  anya_karya,
  full_name,
  gender,
  mobileno,
  street_name,
  email,
  abhyas,
  vyavasay,
  anya_vyavasay,
  sanstha,
}) {
  const response = await axiosInstance.post("api/gramsamelan/add_yadi", {
    district_name,
    taluka_name,
    mandal_name,
    village_name,
    pasandagi_kam,
    pasandagi_sub_kam,
    pasandagi_jodayel_karya,
    anya_karya,
    full_name,
    gender,
    mobileno,
    street_name,
    email,
    abhyas,
    vyavasay,
    anya_vyavasay,
    sanstha,
  });
  return response;
}

export async function updateGramSamelan({
  _id: id,
  district_name,
  taluka_name,
  mandal_name,
  village_name,
  pasandagi_kam,
  pasandagi_sub_kam,
  pasandagi_jodayel_karya,
  anya_karya,
  full_name,
  gender,
  mobileno,
  street_name,
  email,
  abhyas,
  vyavasay,
  anya_vyavasay,
  sanstha,
}) {
  const response = await axiosInstance.put(`api/gramsamelan/updateyadi/${id}`, {
    district_name,
    taluka_name,
    mandal_name,
    village_name,
    pasandagi_kam,
    pasandagi_sub_kam,
    pasandagi_jodayel_karya,
    anya_karya,
    full_name,
    gender,
    mobileno,
    street_name,
    email,
    abhyas,
    vyavasay,
    anya_vyavasay,
    sanstha,
  });
  return response;
}

export async function deleteGramSamelan(id) {
  const response = await axiosInstance.delete(
    `api/gramsamelan/deleteyadi/${id}`
  );
  return response;
}
