import * as React from "react";
import { Icon, Tooltip, IconButton } from "@mui/material";
import Layout from "../../../Layout";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";
import MyDashDataTable from "app/components/MyDashDataTable";
import EditUpnagarSamiti from "./EditUpnagarSamiti";
import {
  deleteUpnagarSamiti,
  getUpnagarSamitibyUpnagar,
} from "app/service/api-service/upnagarsamiti-service";
import { useLocation, useNavigate } from "react-router-dom";

const ViewUpnagarSamiti = () => {
  const confirm = useConfirm();
  const [editopen, setEditopen] = useState(false);
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();
  const { state, pathname } = useLocation();
  const { upnagar } = state;
  const navigate = useNavigate();

  const { data: upnagarsamitiList, isLoading } = useQuery(
    ["getUpnagarSamitibyUpnagar", upnagar._id],
    () => getUpnagarSamitibyUpnagar(upnagar._id)
  );

  const DeleteUpnagarSamitiMutation = useMutation(deleteUpnagarSamiti, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["getUpnagarSamitibyUpnagar", upnagar._id],
        (MainData) => {
          return MainData.filter((samiti) => {
            return samiti._id !== data.upnagarsamiti._id;
          });
        }
      );
    },
  });

  const handleDelete = async (id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ ઉપનગર સમિતિ માંથી કાઢી નાખવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        DeleteUpnagarSamitiMutation.mutate(id);
      })
      .catch(() => {});
  };

  const handleClickOpen = (type) => {
    setEditopen(true);
    navigate(pathname + "#alert", {
      state: { upnagar: upnagar },
    });
  };
  const handleClose = () => {
    setEditopen(false);
    setEdit(false);
  };
  const columns = [
    { name: "ક્રમાંક", options: { filterOptions: { fullWidth: true } } },
    "નામ",
    "મોબાઈલ નંબર",
    "સંઘ પરિવાર",
    "વિશેષ નોંધ",
    {
      name: "ક્રિયાઓ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false,
      },
    },
  ];
  const options = {};
  const data = upnagarsamitiList?.map((samiti, index) => [
    index + 1,
    samiti.name + (samiti.sanyojak ? " (સંયોજક)" : ""),
    samiti.mobileno,
    samiti.sangh_parivar,
    samiti.vishesh_nondh,
    <>
      <Tooltip title="સંપાદિત કરો" placement="bottom">
        <IconButton
          onClick={() => {
            setEdit({
              ...samiti,
              upnagar_id: samiti.upnagar_id._id,
            });
            handleClickOpen("edit");
          }}
        >
          <Icon color="primary">edit</Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title="કાઢી નાખો" placement="bottom">
        <IconButton
          onClick={() => {
            handleDelete(samiti._id);
          }}
        >
          <Icon color="error">delete</Icon>
        </IconButton>
      </Tooltip>
    </>,
  ]);
  return (
    <Layout
      isLoading={isLoading || false}
      title={upnagar.upnagar_name + " સમિતિ"}
      content={
        <>
          <MyDashDataTable
            filename={"ઉપનગર સમિતિ.csv"}
            data={data}
            columns={columns}
            options={options}
          />
          {edit && (
            <EditUpnagarSamiti
              handleClose={handleClose}
              open={editopen}
              edit={edit}
              value={upnagar._id}
            />
          )}
        </>
      }
    />
  );
};

export default ViewUpnagarSamiti;
