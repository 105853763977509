import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box, MenuItem } from "@mui/material";
import { useState } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { addAdhikaripravas } from "app/service/api-service/adhikaripravas-service";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useMutation, useQueryClient } from "react-query";
import dayjs from "dayjs";
import MyDatePicker from "app/components/MyDatePicker";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const AddAdhikariPravas = (props) => {
  const [state, setState] = useState({
    date: dayjs(),
    upsthiti: 0,
  });

  const queryClient = useQueryClient();

  React.useEffect(() => {
    ValidatorForm.addValidationRule("isValidDate", (value) => {
      return dayjs(value, "DD-MM-YYYY", true).isValid();
    });
    return () => ValidatorForm.removeValidationRule("isValidDate");
  }, [state.date]);

  const AddAdhikaripravasMutation = useMutation(addAdhikaripravas, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["getAdhikaripravasbyMonth", props?.value],
        (MainData) => {
          return data.adhikaripravasData.concat(MainData);
        }
      );
      setState({
        date: dayjs(),
        upsthiti: 0,
      });
      props.setValue({
        month: state.date.get("month"),
        year: state.date.get("year"),
      });
      props.handleClose();
    },
  });
  const handleSubmit = async () => {
    AddAdhikaripravasMutation.mutate(state);
  };

  const handleChange = (event) => {
    if (event.target.name === "mobileno") {
      event.target.value.replace(/\D/g, "");
      event.target.value.substr(0, 10);
      setState({
        ...state,
        mobileno: event.target.validity.valid ? event.target.value : "",
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleDateChange = (date) => {
    setState({ ...state, date });
  };

  return (
    <MyDialogBox
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({
          date: dayjs(),
          upsthiti: 0,
        });
      }}
      title="પ્રવાસ ઉમેરો"
      content={
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <MyDatePicker
                label="તારીખ *"
                value={state.date || ""}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <TextField
                type="text"
                name="adhikari_name"
                id="standard-basic"
                value={state?.adhikari_name || ""}
                onChange={handleChange}
                errorMessages={["અધિકારીનું નામ દાખલ કરો"]}
                label="અધિકારીનું નામ *"
                validators={["required"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                type="text"
                name="samparkit_vyakti_name"
                id="standard-basic"
                value={state?.samparkit_vyakti_name || ""}
                onChange={handleChange}
                errorMessages={["સંપર્કીત વ્યક્તિનું નામ દાખલ કરો"]}
                label="સંપર્કીત વ્યક્તિનું નામ *"
                validators={["required"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="samparkit_vyakti_shreni"
                value={state?.samparkit_vyakti_shreni || ""}
                label="સંપર્કીત વ્યક્તિનની શ્રેણી *"
                onChange={handleChange}
                errorMessages={["સંપર્કીત વ્યક્તિનની શ્રેણી પસંદ કરો"]}
                validators={["required"]}
              >
                <MenuItem value="">
                  <em>વિકલ્પ પસંદ કરો</em>
                </MenuItem>
                <MenuItem
                  value="સામાજિક અગ્રણી અને સ્વયંસેવી સંગઠન શ્રેણી"
                  key="0"
                >
                  સામાજિક અગ્રણી અને સ્વયંસેવી સંગઠન શ્રેણી
                </MenuItem>
                <MenuItem value="ચિકિત્સક શ્રેણી" key="1">
                  ચિકિત્સક શ્રેણી
                </MenuItem>
                <MenuItem value="ન્યાયિક શ્રેણી" key="2">
                  ન્યાયિક શ્રેણી
                </MenuItem>
                <MenuItem value="પ્રબુદ્ધ નાગરિક શ્રેણી" key="3">
                  પ્રબુદ્ધ નાગરિક શ્રેણી
                </MenuItem>
                <MenuItem value="પ્રશાંશનિક અધિકારી શ્રેણી" key="4">
                  પ્રશાંશનિક અધિકારી શ્રેણી
                </MenuItem>
                <MenuItem value="બિલ્ડર શ્રેણી (આર્થિક શ્રેણી)" key="5">
                  બિલ્ડર શ્રેણી (આર્થિક શ્રેણી)
                </MenuItem>
                <MenuItem value="વ્યાપારી શ્રેણી (આર્થિક શ્રેણી)" key="6">
                  વ્યાપારી શ્રેણી (આર્થિક શ્રેણી)
                </MenuItem>
                <MenuItem value="ઔદ્યોગિક શ્રેણી (આર્થિક શ્રેણી)" key="7">
                  ઔદ્યોગિક શ્રેણી (આર્થિક શ્રેણી)
                </MenuItem>
                <MenuItem value="સીએ શ્રેણી" key="8">
                  સીએ શ્રેણી
                </MenuItem>
                <MenuItem value="કલા શ્રેણી" key="9">
                  કલા શ્રેણી
                </MenuItem>
                <MenuItem value="ક્રિડા શ્રેણી" key="10">
                  ક્રિડા શ્રેણી
                </MenuItem>
                <MenuItem value="ધાર્મિક શ્રેણી" key="11">
                  ધાર્મિક શ્રેણી
                </MenuItem>
              </SelectField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                multiline
                type="text"
                name="address"
                id="standard-basic"
                value={state?.address || ""}
                onChange={handleChange}
                label="સરનામું"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: "10",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="mobileno"
                id="standard-basic1"
                value={state?.mobileno || ""}
                onChange={handleChange}
                errorMessages={[
                  "મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                ]}
                label="મોબાઇલ નંબર *"
                validators={[
                  "required",
                  "isNumber",
                  "minStringLength: 10",
                  "maxStringLength: 10",
                ]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                multiline
                type="text"
                name="vishesh_nondh"
                id="standard-basic"
                value={state?.vishesh_nondh || ""}
                onChange={handleChange}
                label="વિશેષ નોંધ"
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={AddAdhikaripravasMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default AddAdhikariPravas;
