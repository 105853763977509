import { Box, Card, Grid, styled } from "@mui/material";
import { H2 } from "app/components/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../Layout";
import { useState } from "react";
import ShakhaProfile from "./ShakhaProfile";
import { useQuery } from "react-query";
import { getShakhaProfile } from "app/service/api-service/shakha-service";
import { getSevaVastibyShakha } from "app/service/api-service/sevavasti-service";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const ShakhaDetails = () => {
  const [detailopen, setDetailOpen] = useState(false);
  const handleClickOpen = (type) => {
    setDetailOpen(true);
  };
  const handleClose = () => {
    setDetailOpen(false);
  };

  const { state } = useLocation();
  const { shakha } = state;
  const navigate = useNavigate();
  const [shakhadata, setShakhadata] = useState({});
  const [shakhauser, setShakhauser] = useState({});
  useQuery(
    ["getShakhaProfile", shakha._id],
    () => getShakhaProfile(shakha._id),
    {
      onSuccess: (data) => {
        setShakhadata(data[0]);
        let karyavah_user_1 = data[1]?.filter(function (item) {
          return item.role_id.role_name === "શાખા કાર્યવાહ";
        });
        let shikshak_user_1 = data[1]?.filter(function (item) {
          return item.role_id.role_name === "મુખ્ય શિક્ષક";
        });
        setShakhauser({
          karyavah_user_name: karyavah_user_1[0]?.name,
          karyavah_user_mobileno: karyavah_user_1[0]?.mobileno,
          shikshak_user_name: shikshak_user_1[0]?.name,
          shikshak_user_mobileno: shikshak_user_1[0]?.mobileno,
        });
      },
    }
  );
  const { data: sevavasti } = useQuery(
    ["getSevaVastibyShakha", shakha._id],
    () => getSevaVastibyShakha(shakha._id)
  );
  const cardList = [
    { name: "શાખા પ્રોફાઈલ", to: "/dashboard/shakha/profile" },
    { name: "સ્વયંસેવકોની યાદી", to: "/dashboard/shakha/swaymsevak" },
    { name: "શાખા ટોળી", to: "/dashboard/shakha/shakha_toli" },
    { name: "અષ્ટબિંદુ વૃત પત્રક", to: "/dashboard/ashtabindu/view_patrak" },
    { name: "ઉપસ્થિતિ પત્રક", to: "/dashboard/upsthiti/view_patrak" },
  ];

  return (
    <>
      <Layout
        title={shakha.shakha_name}
        content={
          <Grid container spacing={3} sx={{ mb: "24px", mt: 0.5 }}>
            {cardList.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                key={index}
                onClick={() => {
                  item.name !== "શાખા પ્રોફાઈલ"
                    ? navigate(item.to, {
                        state: { shakha: shakha },
                      })
                    : handleClickOpen();
                }}
              >
                <StyledCard elevation={6}>
                  <ContentBox>
                    <Box>
                      <H2
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          whiteSpace: "pre-wrap",
                          fontSize: "1.25rem",
                        }}
                      >
                        {item.name}
                      </H2>
                    </Box>
                  </ContentBox>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        }
      />
      {shakhadata && detailopen && (
        <ShakhaProfile
          handleClose={handleClose}
          open={detailopen}
          shakhadata={shakhadata}
          shakhauser={shakhauser}
          sevavasti={sevavasti}
        />
      )}
    </>
  );
};

export default ShakhaDetails;
