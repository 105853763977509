import { Box, Card, Grid, styled } from "@mui/material";
import { H2 } from "app/components/Typography";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const SaptahikBethako = () => {
  const navigate = useNavigate();

  const cardList = [
    { name: "નગર પ્રવાસ દિવસ", to: "/dashboard/nagar_pravas_din" },
  ];

  return (
    <Layout
      title="સાપ્તાહિક બેઠકો"
      content={
        <Grid container spacing={3} sx={{ mb: "24px", mt: 0.5 }}>
          {cardList.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={index}
              onClick={() => {
                navigate(item.to);
              }}
            >
              <StyledCard elevation={6}>
                <ContentBox>
                  <Box>
                    <H2
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        whiteSpace: "pre-wrap",
                        fontSize: "1.25rem",
                      }}
                    >
                      {item.name}
                    </H2>
                  </Box>
                </ContentBox>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};

export default SaptahikBethako;
