import * as React from "react";
import { Box, Icon, Tooltip } from "@mui/material";
import { useQuery } from "react-query";
import { useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { LoadingButton } from "@mui/lab";
import Layout from "../Layout";
import MyReportTable from "app/components/MyReportTable";
import { getSevaVasti } from "app/service/api-service/sevavasti-service";
import SevaVastiReportPDF from "./SevaVastiReportPDF";

const SevaVastiReport = () => {
  const [PDFLoading, setPDFLoading] = useState(false);
  const [sevaList, setsevaList] = useState([]);

  const { data: PDFList, isLoading } = useQuery(
    ["getSevaVasti"],
    getSevaVasti,
    {
      onSuccess: (data) => {
        let count = 1;
        setsevaList(
          data.map((vasti, index) => [
            count++,
            vasti?.shakha_id?.shakha_name,
            vasti?.seva_vasti,
            vasti?.seva_karyakarta?.map(
              (karyakarta, index) =>
                "(" +
                (index + 1) +
                ") " +
                karyakarta?.swayamsevak_id?.name +
                "\n"
            ),
            vasti?.seva_karyakarta?.map(
              (karyakarta, index) =>
                "(" +
                (index + 1) +
                ") " +
                karyakarta?.swayamsevak_id?.mobileno +
                "\n"
            ),
          ])
        );
      },
    }
  );

  const DownloadPDF = async () => {
    setPDFLoading(true);
    const doc = <SevaVastiReportPDF sevaList={PDFList} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "સેવા વસ્તી રિપોર્ટ.pdf");
    setPDFLoading(false);
  };
  const columns = [
    { name: "ક્રમાંક", options: { filterOptions: { fullWidth: true } } },
    "શાખા ",
    "સેવા વસ્તી",
    "સેવા કાર્યકર્તા",
    "મોબાઇલ નંબર",
  ];
  const options = {};

  return (
    <Layout
      isLoading={isLoading || false}
      title="સેવા વસ્તી રિપોર્ટ"
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              borderBottom: "1px dashed #ccc",
            }}
          >
            <Tooltip title="ડાઉનલોડ રિપોર્ટ" placement="bottom">
              <LoadingButton
                loading={PDFLoading}
                onClick={DownloadPDF}
                variant="contained"
                color="primary"
                style={{
                  minHeight: 36,
                  margin: "10px 0 10px 10px",
                  fontWeight: "bold",
                }}
              >
                <Icon className="icon">picture_as_pdf</Icon> &nbsp;ડાઉનલોડ
                રિપોર્ટ
              </LoadingButton>
            </Tooltip>
          </Box>
          <MyReportTable data={sevaList} columns={columns} options={options} />
        </>
      }
    />
  );
};

export default SevaVastiReport;
