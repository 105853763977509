import * as React from "react";
import {
  Box,
  Card,
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  tabsClasses,
  Tab,
  Alert,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Layout from "./Layout";
import { useMutation } from "react-query";
import { getVarshikCalenderbyMonth } from "app/service/api-service/varshikcalender-service";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  padding: "24px !important",
  boxShadow: "none !important",
  background: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  flexGrow: 1,
  maxWidth: "100%",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    fontSize: "25px",
    color: "#34314c",
  },
}));
const MyTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: "1px solid #ccc",
  "& .MuiTabs-scroller": {
    width: "100px",
  },
}));
const VarshikCalender = () => {
  let today = new Date();
  const [value, setValue] = React.useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  });
  const getVarshikCalenderbyMonthMutation = useMutation(
    getVarshikCalenderbyMonth
  );
  let VarshikCalenderData = getVarshikCalenderbyMonthMutation.data?.data;

  React.useEffect(() => {
    getVarshikCalenderbyMonthMutation.mutate(value);
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setValue({ ...value, month: newValue });
    getVarshikCalenderbyMonthMutation.mutate({
      month: newValue,
      year: value.year,
    });
  };

  function getMonthName(monthNumber) {
    const monthArray = [
      "જાન્યુઆરી",
      "ફેબ્રુઆરી",
      "માર્ચ",
      "એપ્રિલ",
      "મે",
      "જૂન",
      "જુલાઈ",
      "ઑગસ્ટ",
      "સપ્ટેમ્બર",
      "ઑક્ટોબર",
      "નવેમ્બર",
      "ડિસેમ્બર",
    ];
    return monthArray[monthNumber];
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  return (
    <Layout
      isLoading={getVarshikCalenderbyMonthMutation.isLoading || false}
      title="વાર્ષિક કેલેન્ડર"
      content={
        <StyledCard>
          <ContentBox>
            <MyTabs
              value={value.month}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="Month List"
              sx={{
                mb: 3,
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              {[...Array(12)].map((x, i) => (
                <Tab
                  key={i}
                  label={getMonthName(i)}
                  {...a11yProps(i)}
                  sx={{ fontSize: "17px", fontWeight: "bold" }}
                />
              ))}
            </MyTabs>
            {VarshikCalenderData?.map((day, index) => (
              <Accordion key={index} sx={{ border: "1px solid #ccc", mb: 2 }}>
                <AccordionSummary
                  id="panel1a-header"
                  expandIcon={day.vigato !== "" && <ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  sx={{
                    borderBottom: "1px solid #ccc",
                    ".MuiAccordionSummary-content": {
                      justifyContent: "center",
                      flexDirection: "column",
                    },
                  }}
                >
                  <Typography
                    component={"span"}
                    className="heading"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      whiteSpace: " pre-wrap",
                      textAlign: "center",
                      color: "#2c469e",
                    }}
                  >
                    {new Date(day.date).toLocaleString("guj-IN", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Typography>
                  <Typography
                    component={"span"}
                    className="heading"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      whiteSpace: " pre-wrap",
                      textAlign: "center",
                      lineHeight: 2.1,
                    }}
                  >
                    {day.vishay}
                  </Typography>
                </AccordionSummary>
                {day.vigato !== "" && (
                  <AccordionDetails
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "center",
                      mt: 1,
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#787272",
                        letterSpacing: "0.5px",
                      }}
                    >
                      {day.vigato}
                    </Typography>
                  </AccordionDetails>
                )}
              </Accordion>
            ))}
            {VarshikCalenderData?.length < 1 && (
              <Alert severity="info" sx={{ mt: 2, width: "100%" }}>
                <b>કોઈ ડેટા મળ્યો નથી</b>
              </Alert>
            )}
          </ContentBox>
        </StyledCard>
      }
    />
  );
};

export default VarshikCalender;
