import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Nagar = Loadable(lazy(() => import("./Nagar")));

const NagarRoutes = [
  {
    path: "/nagar/allnagar",
    element: <Nagar />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
];

export default NagarRoutes;
