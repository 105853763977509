import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box } from "@mui/material";
import { useState } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useMutation } from "react-query";
import MenuItem from "@mui/material/MenuItem";
import { addSamparkshreniyadi } from "app/service/api-service/samparkshreniyadi-service";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
  marginTop: "0px !important",
}));

const AddSamparkshreniYadi = (props) => {
  const [state, setState] = useState({ is_sampark_pramukh: 0 });

  const AddSamparkshreniYadiMutation = useMutation(addSamparkshreniyadi, {
    onSuccess: ({ data }) => {
      setState({});
      props.handleClose();
    },
  });
  const handleSubmit = async () => {
    AddSamparkshreniYadiMutation.mutate(state);
  };

  const handleChange = (event) => {
    if (event.target.name === "mobileno") {
      event.target.value.replace(/\D/g, "");
      event.target.value.substr(0, 10);
      setState({
        ...state,
        mobileno: event.target.validity.valid ? event.target.value : "",
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  return (
    <MyDialogBox
      maxWidth="sm"
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({});
      }}
      title="સંપર્ક શ્રેણી યાદી ઉમેરો"
      content={
        <ValidatorForm
          autoComplete="new-password"
          onSubmit={handleSubmit}
          onError={() => null}
        >
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="samparkshreni_prakar"
                value={state?.samparkshreni_prakar || ""}
                label="સંપર્ક શ્રેણીનો પ્રકાર *"
                onChange={handleChange}
                errorMessages={["સંપર્ક શ્રેણીનો પ્રકાર પસંદ કરો"]}
                validators={["required"]}
              >
                <MenuItem value="">
                  <em>વિકલ્પ પસંદ કરો</em>
                </MenuItem>
                <MenuItem
                  value="સામાજિક અગ્રણી અને સ્વયંસેવી સંગઠન શ્રેણી"
                  key="0"
                >
                  સામાજિક અગ્રણી અને સ્વયંસેવી સંગઠન શ્રેણી
                </MenuItem>
                <MenuItem value="ચિકિત્સક શ્રેણી" key="1">
                  ચિકિત્સક શ્રેણી
                </MenuItem>
                <MenuItem value="ન્યાયિક શ્રેણી" key="2">
                  ન્યાયિક શ્રેણી
                </MenuItem>
                <MenuItem value="પ્રબુદ્ધ નાગરિક શ્રેણી" key="3">
                  પ્રબુદ્ધ નાગરિક શ્રેણી
                </MenuItem>
                <MenuItem value="પ્રશાંશનિક અધિકારી શ્રેણી" key="4">
                  પ્રશાંશનિક અધિકારી શ્રેણી
                </MenuItem>
                <MenuItem value="બિલ્ડર શ્રેણી (આર્થિક શ્રેણી)" key="5">
                  બિલ્ડર શ્રેણી (આર્થિક શ્રેણી)
                </MenuItem>
                <MenuItem value="વ્યાપારી શ્રેણી (આર્થિક શ્રેણી)" key="6">
                  વ્યાપારી શ્રેણી (આર્થિક શ્રેણી)
                </MenuItem>
                <MenuItem value="ઔદ્યોગિક શ્રેણી (આર્થિક શ્રેણી)" key="7">
                  ઔદ્યોગિક શ્રેણી (આર્થિક શ્રેણી)
                </MenuItem>
                <MenuItem value="સીએ શ્રેણી" key="8">
                  સીએ શ્રેણી
                </MenuItem>
                <MenuItem value="કલા શ્રેણી" key="9">
                  કલા શ્રેણી
                </MenuItem>
                <MenuItem value="ક્રિડા શ્રેણી" key="10">
                  ક્રિડા શ્રેણી
                </MenuItem>
                <MenuItem value="ધાર્મિક શ્રેણી" key="11">
                  ધાર્મિક શ્રેણી
                </MenuItem>
              </SelectField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                type="text"
                name="name"
                id="standard-basic"
                value={state.name || ""}
                onChange={handleChange}
                errorMessages={["નામ દાખલ કરો", "માન્ય નામ દાખલ કરો"]}
                label="નામ *"
                validators={["required", "minStringLength: 3"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: "10",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="mobileno"
                id="standard-basic1"
                value={state.mobileno || ""}
                onChange={handleChange}
                errorMessages={[
                  "મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                ]}
                label="મોબાઇલ નંબર *"
                validators={[
                  "required",
                  "isNumber",
                  "minStringLength: 10",
                  "maxStringLength: 10",
                ]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                multiline
                type="text"
                name="address"
                id="standard-basic3"
                value={state.address || ""}
                onChange={handleChange}
                label="સરનામું"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="is_sampark_pramukh"
                value={String(state?.is_sampark_pramukh || "0")}
                label="સંપર્ક પ્રમુખ *"
                onChange={handleChange}
                errorMessages={["સંપર્ક પ્રમુખનો વિકલ્પ પસંદ કરો"]}
                validators={["required"]}
              >
                <MenuItem value="0" key="0">
                  ના
                </MenuItem>
                <MenuItem value="1" key="1">
                  હા
                </MenuItem>
              </SelectField>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={AddSamparkshreniYadiMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default AddSamparkshreniYadi;
