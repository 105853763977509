import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Swaymsevak = Loadable(lazy(() => import("./Swaymsevak")));

const SwaymsevakRoutes = [
  {
    path: "/swaymsevak/allswaymsevak",
    element: <Swaymsevak />,
    auth: ["શાખા સ્તર"],
  },
];

export default SwaymsevakRoutes;
