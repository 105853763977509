import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box, MenuItem, CircularProgress } from "@mui/material";
import { useState } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getVasti } from "app/service/api-service/vasti-service";
import { updateLocalSamiti } from "app/service/api-service/localsamiti-service";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const EditLocalSamiti = (props) => {
  const editval = props.edit;
  const [state, setState] = useState(editval);
  const queryClient = useQueryClient();
  const { data: vastiList, isLoading } = useQuery(["getVasti"], getVasti);

  const UpdateLocalSamitiMutation = useMutation(updateLocalSamiti, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(["getLocalSamitibyVasti", props.value]);
      setState({});
      props.handleClose();
    },
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    UpdateLocalSamitiMutation.mutate(state);
  };

  return (
    <MyDialogBox
      maxWidth="sm"
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({});
      }}
      title="સંપાદિત કરો"
      content={
        <ValidatorForm
          autoComplete="new-password"
          onSubmit={handleSubmit}
          onError={() => null}
        >
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="vasti_id"
                value={(vastiList && state.vasti_id) || ""}
                errorMessages={["વસ્તી પસંદ કરો"]}
                label="વસ્તી *"
                validators={["required"]}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>વસ્તી પસંદ કરો</em>
                </MenuItem>
                {vastiList?.map((vasti, index) => (
                  <MenuItem value={vasti._id} key={index}>
                    {vasti.vasti_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                type="text"
                name="local_samiti_name"
                id="standard-basic3"
                value={state.local_samiti_name || ""}
                onChange={handleChange}
                errorMessages={["લોકલ સમિતિનું નામ દાખલ કરો"]}
                label="લોકલ સમિતિનું નામ *"
                validators={["required"]}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={UpdateLocalSamitiMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default EditLocalSamiti;
