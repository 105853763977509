import * as React from "react";
import { Box, Card, styled, Tabs, tabsClasses, Tab } from "@mui/material";
import { H3 } from "app/components/Typography";
import Layout from "../Layout";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { getUpsthitibyMonth } from "app/service/api-service/upsthiti-service";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  padding: "24px !important",
  background: theme.palette.background.paper,
  boxShadow: "none !important",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  flexGrow: 1,
  maxWidth: "100%",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    fontSize: "25px",
    color: "#34314c",
  },
}));
const MyTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: "1px solid #ccc",
  "& .MuiTabs-scroller": {
    width: "100px",
  },
}));
const Itembox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "15px",
  paddingBottom: "10px",
  borderBottom: "1px dashed #d5d4d4",
  ":last-child": {
    borderBottom: "0px",
  },
}));
const Title = styled(H3)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "bold",
  textAlign: "center",
  borderBottom: "2px dashed #414c76",
  marginBottom: "15px",
}));
const Content = styled(H3)(({ theme }) => ({
  lineHeight: 1.8,
  fontSize: "20px",
  textAlign: "center",
  color: theme.palette.primary.main,
}));

const UpsthitiPatrak = () => {
  let today = new Date();
  const { state } = useLocation();
  const { shakha } = state;
  const [value, setValue] = React.useState({
    month: today.getMonth(),
    year: today.getFullYear(),
    shakha_id: shakha._id,
  });

  const [data, setData] = React.useState({
    total_day: 0,
    bal: 0,
    tarun: 0,
    yog: 0,
    shishu: 0,
  });

  const getUpsthitibyMonthMutation = useMutation(getUpsthitibyMonth, {
    onSuccess: ({ data: serverdata }) => {
      if (serverdata[0] && serverdata[1]) {
        setData({
          ...data,
          total_day:
            typeof serverdata[1]["count"] !== "undefined" &&
            serverdata[1]["count"]
              ? serverdata[1]["count"]
              : 0,
          bal:
            typeof serverdata[0]["bal"] !== "undefined" && serverdata[0]["bal"]
              ? serverdata[0]["bal"]
              : 0,
          tarun:
            typeof serverdata[0]["tarun"] !== "undefined" &&
            serverdata[0]["tarun"]
              ? serverdata[0]["tarun"]
              : 0,
          yog:
            typeof serverdata[0]["yog"] !== "undefined" && serverdata[0]["yog"]
              ? serverdata[0]["yog"]
              : 0,
          shishu:
            typeof serverdata[0]["shishu"] !== "undefined" &&
            serverdata[0]["shishu"]
              ? serverdata[0]["shishu"]
              : 0,
        });
      } else {
        setData({
          total_day: 0,
          bal: 0,
          tarun: 0,
          yog: 0,
          shishu: 0,
        });
      }
    },
  });

  React.useEffect(() => {
    getUpsthitibyMonthMutation.mutate(value);
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setValue({ ...value, month: newValue });
    getUpsthitibyMonthMutation.mutate({
      month: newValue,
      year: value.year,
      shakha_id: shakha._id,
    });
  };

  function getMonthName(monthNumber) {
    const monthArray = [
      "જાન્યુઆરી",
      "ફેબ્રુઆરી",
      "માર્ચ",
      "એપ્રિલ",
      "મે",
      "જૂન",
      "જુલાઈ",
      "ઑગસ્ટ",
      "સપ્ટેમ્બર",
      "ઑક્ટોબર",
      "નવેમ્બર",
      "ડિસેમ્બર",
    ];
    return monthArray[monthNumber];
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  return (
    <Layout
      isLoading={getUpsthitibyMonthMutation.isLoading || false}
      title="ઉપસ્થિતિ પત્રક"
      content={
        <StyledCard>
          <ContentBox>
            <MyTabs
              value={value.month}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="Month List"
              sx={{
                mb: 3,
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              {[...Array(12)].map((x, i) => (
                <Tab
                  key={i}
                  label={getMonthName(i)}
                  {...a11yProps(i)}
                  sx={{ fontSize: "17px", fontWeight: "bold" }}
                />
              ))}
            </MyTabs>

            <Itembox>
              <Title>કુલ દિવસો</Title>
              <Content>{data?.total_day}</Content>
            </Itembox>
            <Itembox>
              <Title>સરેરાશ બાલ</Title>
              <Content>
                {parseFloat(
                  String(
                    data?.bal !== 0 && data?.total_day !== 0
                      ? data?.bal / data?.total_day
                      : 0
                  )
                ).toFixed(2)}
              </Content>
            </Itembox>
            <Itembox>
              <Title>સરેરાશ તરુણ</Title>
              <Content>
                {parseFloat(
                  String(
                    data?.tarun !== 0 && data?.total_day !== 0
                      ? data?.tarun / data?.total_day
                      : 0
                  )
                ).toFixed(2)}
              </Content>
            </Itembox>
            <Itembox>
              <Title>સરેરાશ યોગ</Title>
              <Content>
                {parseFloat(
                  String(
                    data?.yog !== 0 && data?.total_day !== 0
                      ? data?.yog / data?.total_day
                      : 0
                  )
                ).toFixed(2)}
              </Content>
            </Itembox>
            <Itembox>
              <Title>સરેરાશ શિશુ</Title>
              <Content>
                {parseFloat(
                  String(
                    data?.shishu !== 0 && data?.total_day !== 0
                      ? data?.shishu / data?.total_day
                      : 0
                  )
                ).toFixed(2)}
              </Content>
            </Itembox>
          </ContentBox>
        </StyledCard>
      }
    />
  );
};

export default UpsthitiPatrak;
