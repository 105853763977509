import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box, MenuItem, CircularProgress } from "@mui/material";
import { useState } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { updateVastiSamiti } from "app/service/api-service/vastisamiti-service";
import { getVasti } from "app/service/api-service/vasti-service";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const EditVastiSamiti = (props) => {
  const editval = props.edit;
  const [state, setState] = useState(editval);
  const queryClient = useQueryClient();

  const { data: vastiList, isLoading } = useQuery(["getVasti"], getVasti);

  const UpdateVastiSamitiMutation = useMutation(updateVastiSamiti, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(["getVastiSamitibyVasti", props.value]);
      setState({
        sanyojak: 0,
      });
      props.handleClose();
    },
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    UpdateVastiSamitiMutation.mutate(state);
  };

  return (
    <MyDialogBox
      maxWidth="sm"
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({
          sanyojak: 0,
        });
      }}
      title="સંપાદિત કરો"
      content={
        <ValidatorForm
          autoComplete="new-password"
          onSubmit={handleSubmit}
          onError={() => null}
        >
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="vasti_id"
                value={(vastiList && state.vasti_id) || ""}
                errorMessages={["વસ્તી પસંદ કરો"]}
                label="વસ્તી *"
                validators={["required"]}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>વસ્તી પસંદ કરો</em>
                </MenuItem>
                {vastiList?.map((vasti, index) => (
                  <MenuItem value={vasti._id} key={index}>
                    {vasti.vasti_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <TextField
                type="text"
                name="name"
                id="standard-basic3"
                value={state.name || ""}
                onChange={handleChange}
                errorMessages={["નામ દાખલ કરો"]}
                label="નામ *"
                validators={["required"]}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: "10",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="mobileno"
                id="standard-basic1"
                value={(state.mobileno && String(state.mobileno)) || ""}
                onChange={(event) => {
                  event.target.value.replace(/\D/g, "");
                  event.target.value.substr(0, 10);
                  setState({
                    ...state,
                    mobileno: event.target.validity.valid
                      ? event.target.value
                      : "",
                  });
                }}
                errorMessages={[
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                ]}
                label="મોબાઇલ નંબર"
                validators={[
                  "isNumber",
                  "minStringLength: 10",
                  "maxStringLength: 10",
                ]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="sangh_parivar"
                value={state.sangh_parivar || ""}
                errorMessages={["સંઘ પરિવારનો વિકલ્પ પસંદ કરો"]}
                label="સંઘ પરિવાર *"
                validators={["required"]}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>વિકલ્પ પસંદ કરો</em>
                </MenuItem>
                <MenuItem value="મહિલા સમિતિ" key="0">
                  મહિલા સમિતિ
                </MenuItem>
                <MenuItem value="બજરંગ દળ" key="1">
                  બજરંગ દળ
                </MenuItem>
                <MenuItem value="RSS" key="2">
                  RSS
                </MenuItem>
                <MenuItem value="BJP" key="3">
                  BJP
                </MenuItem>
                <MenuItem value="ABVP" key="4">
                  ABVP
                </MenuItem>
                <MenuItem value="અન્ય" key="5">
                  અન્ય
                </MenuItem>
              </SelectField>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="sanyojak"
                value={String(state.sanyojak) || ""}
                errorMessages={["સંયોજકનો વિકલ્પ પસંદ કરો"]}
                label="સંયોજક *"
                validators={["required"]}
                onChange={handleChange}
              >
                <MenuItem value="0" key="0">
                  ના
                </MenuItem>
                <MenuItem value="1" key="1">
                  હા
                </MenuItem>
              </SelectField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                multiline
                type="text"
                name="vishesh_nondh"
                id="standard-basic3"
                value={state.vishesh_nondh || ""}
                onChange={handleChange}
                label="વિશેષ નોંધ"
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={UpdateVastiSamitiMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default EditVastiSamiti;
