import { Alert, Box, Card, Grid, styled } from "@mui/material";
import { H3 } from "app/components/Typography";
import Layout from "../Layout";
import { useQuery } from "react-query";
import { getAllSwaymsevak } from "app/service/api-service/swaymsevak-service";
import { useState } from "react";
import SwaymsevakDetail from "../SwaymsevakDetail";
import { useLocation } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const SanghshikshanFilter = () => {
  const { state } = useLocation();
  const { type } = state;
  const { data: swaymsevakList, isLoading } = useQuery(
    ["getSwaymsevaks"],
    getAllSwaymsevak
  );

  const [detailopen, setDetailOpen] = useState(false);
  const [swaymsevakData, setSwaymsevakData] = useState(false);

  const handleClickOpen = (type) => {
    setDetailOpen(true);
  };
  const handleClose = () => {
    setDetailOpen(false);
    setSwaymsevakData(false);
  };

  return (
    <>
      <Layout
        isLoading={isLoading || false}
        title={type}
        content={
          <Grid container spacing={0} sx={{ mb: "24px", mt: 0.5 }}>
            {swaymsevakList
              ?.filter(
                ({ sanghshikshan }) => sanghshikshan && sanghshikshan === type
              )
              .map((swaymsevak, index) => (
                <Grid container spacing={0} key={index}>
                  <Grid
                    sx={{ mt: 3 }}
                    item
                    lg={1.5}
                    xs={2.8}
                    onClick={() => {
                      setSwaymsevakData(swaymsevak);
                      handleClickOpen();
                    }}
                  >
                    <StyledCard
                      elevation={6}
                      sx={{ borderRadius: "8px 0 0 8px", height: "100%" }}
                    >
                      <ContentBox>
                        <Box>
                          <H3 sx={{ fontWeight: "bold", textAlign: "center" }}>
                            &nbsp;({index + 1})&nbsp;
                          </H3>
                        </Box>
                      </ContentBox>
                    </StyledCard>
                  </Grid>
                  <Grid
                    sx={{ mt: 3 }}
                    item
                    lg={10.5}
                    xs={9.2}
                    onClick={() => {
                      setSwaymsevakData(swaymsevak);
                      handleClickOpen();
                    }}
                  >
                    <StyledCard
                      elevation={6}
                      sx={{ borderRadius: "0 8px 8px 0" }}
                    >
                      <ContentBox>
                        <Box>
                          <H3 sx={{ fontWeight: "bold", textAlign: "center" }}>
                            {swaymsevak.name}
                          </H3>
                          <H3
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                              color: "#3b3ea1",
                            }}
                          >
                            ({swaymsevak?.shakha_id?.shakha_name})
                          </H3>
                        </Box>
                      </ContentBox>
                    </StyledCard>
                  </Grid>
                </Grid>
              ))}
            {swaymsevakList?.filter(
              ({ sanghshikshan }) => sanghshikshan && sanghshikshan === type
            )?.length < 1 && (
              <Alert severity="info" sx={{ mt: 2, width: "100%" }}>
                <b>કોઈ ડેટા મળ્યો નથી</b>
              </Alert>
            )}
          </Grid>
        }
      />
      {swaymsevakData && (
        <SwaymsevakDetail
          handleClose={handleClose}
          open={detailopen}
          swaymsevakdata={swaymsevakData}
        />
      )}
    </>
  );
};

export default SanghshikshanFilter;
