import * as React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  styled,
  MenuItem,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
  InputLabel,
  FormControl,
  Select,
  Chip,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MyFileInput from "app/components/MyFileInput";
import { validateYouTubeUrl } from "app/utils/utils";
import MyDialogBox from "app/components/MyFormDialogBox";
import YTItem from "app/components/YTItem";
import { useMutation, useQueryClient } from "react-query";
import {
  addCommunityPost,
  deleteCommunityPost,
  updateCommunityPost,
} from "app/service/api-service/community-service";
import { updatePracharkaryogallery } from "app/service/api-service/pracharkaryogallery";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const EditPracharKaryoGallery = (props) => {
  const editval = props.edit;
  const [state, setState] = useState({
    ...editval,
    access: editval?.community_post_id?.access
      ? Array.isArray(editval?.community_post_id?.access)
        ? editval?.community_post_id?.access
        : [editval?.community_post_id?.access]
      : "",
    community_post_id: editval?.community_post_id?._id
      ? editval?.community_post_id?._id
      : "",
    sharecommunity: editval?.community_post_id?._id ? true : false,
    fileError: false,
    accessError: false,
  });
  const queryClient = useQueryClient();

  React.useEffect(() => {
    ValidatorForm.addValidationRule("isValidYoutubeURL", async (value) => {
      const url_data = await validateYouTubeUrl(value.trim());
      if (!url_data) {
        setState({ ...state, embed_url: "" });
        return false;
      } else {
        setState({ ...state, embed_url: url_data.embed_url });
        return true;
      }
    });
    return () => ValidatorForm.removeValidationRule("isValidYoutubeURL");
  }, [state]);

  const handleSubmit = async () => {
    if (state.sharecommunity && state?.access?.length < 1) {
      setState({ ...state, accessError: true });
    } else {
      const formData = new FormData();
      if (state.sharecommunity) {
        formData.append("content", state.vishay);
        for (var x = 0; x < state?.file?.length; x++) {
          formData.append("file", state?.file[x]);
        }
        formData.append("youtube_link", state.youtube_link);
        formData.append("embed_url", state.embed_url);
        formData.append("access", state.access);
        if (!state.community_post_id) {
          AddCommunityPostMutation.mutate(formData);
        } else {
          formData.append("id", state.community_post_id);
          UpdateCommunityPostMutation.mutate(formData);
        }
      } else {
        formData.append("id", state._id);
        formData.append("vishay", state.vishay);
        for (var y = 0; y < state?.file?.length; y++) {
          formData.append("file", state?.file[y]);
        }
        formData.append("youtube_link", state.youtube_link);
        formData.append("embed_url", state.embed_url);
        UpdatePracharKaryogalleryMutation.mutate(formData);
        if (state.community_post_id) {
          DeleteCommunityPostMutation.mutate(state.community_post_id);
        }
      }
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "media_type") {
      setState({
        ...state,
        [event.target.name]: event.target.value,
        embed_url: "",
        youtube_link: "",
        file: null,
        filename: null,
        initFile: "",
      });
    } else if (event.target.name === "access") {
      if (event?.target?.value?.length < 1) {
        setState({
          ...state,
          [event.target.name]:
            typeof value === "string"
              ? event.target.value.split(",")
              : event.target.value,
          accessError: true,
        });
      } else {
        setState({
          ...state,
          [event.target.name]:
            typeof value === "string"
              ? event.target.value.split(",")
              : event.target.value,
          accessError: false,
        });
      }
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleFile = (files) => {
    setState({
      ...state,
      file: files.length > 0 ? files : null,
      filename: files[0]?.name ? files[0]?.name : "",
    });
  };

  const handleCommunityShare = () => {
    setState({
      ...state,
      sharecommunity: !state?.sharecommunity,
      access: [],
    });
  };

  const UpdateCommunityPostMutation = useMutation(updateCommunityPost, {
    onSuccess: ({ data }) => {
      const formData = new FormData();
      formData.append("id", state._id);
      formData.append("community_post_id", data?.communitypostData?._id);
      formData.append("vishay", state.vishay);
      for (var x = 0; x < state?.file?.length; x++) {
        formData.append("file", state?.file[x]);
      }
      formData.append("youtube_link", state.youtube_link);
      formData.append("embed_url", state.embed_url);
      UpdatePracharKaryogalleryMutation.mutate(formData);
    },
  });
  const DeleteCommunityPostMutation = useMutation(deleteCommunityPost);
  const AddCommunityPostMutation = useMutation(addCommunityPost, {
    onSuccess: ({ data }) => {
      const formData = new FormData();
      formData.append("id", state._id);
      formData.append("community_post_id", data?.communitypostData?._id);
      formData.append("vishay", state.vishay);
      for (var x = 0; x < state?.file?.length; x++) {
        formData.append("file", state?.file[x]);
      }
      formData.append("youtube_link", state.youtube_link);
      formData.append("embed_url", state.embed_url);
      UpdatePracharKaryogalleryMutation.mutate(formData);
    },
  });

  const UpdatePracharKaryogalleryMutation = useMutation(
    updatePracharkaryogallery,
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(["getPracharKaryogallery"], (MainData) => {
          MainData.map((main, index) => {
            if (main._id === data.galleryData._id) {
              MainData[index] = data.galleryData;
            }
            return main;
          });
          return MainData;
        });
        setState({});
        props.handleClose();
      },
    }
  );

  return (
    <MyDialogBox
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({});
      }}
      title="ગેલેરી સંપાદિત કરો"
      content={
        <ValidatorForm
          encType="multipart/form-data"
          onSubmit={handleSubmit}
          onError={(errors) => {
            errors?.map((error) => {
              if (error.props.name === "filename") {
                setState({ ...state, fileError: true });
              } else {
                setState({ ...state, fileError: false });
              }
              return true;
            });
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                type="text"
                name="vishay"
                id="standard-basic"
                value={state.vishay || ""}
                onChange={handleChange}
                errorMessages={["વિષય દાખલ કરો"]}
                label="વિષય *"
                validators={["required"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="media_type"
                value={state.media_type || ""}
                label="મીડિયા ટાઈપ"
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["મીડિયા ટાઈપ પસંદ કરો"]}
              >
                <MenuItem value="">
                  <em>વિકલ્પ પસંદ કરો</em>
                </MenuItem>
                <MenuItem value="YouTube લીંક" key="0">
                  YouTube લીંક
                </MenuItem>
                <MenuItem value="IMAGES" key="1">
                  IMAGES
                </MenuItem>
              </SelectField>
            </Grid>
            {state?.media_type === "YouTube લીંક" && (
              <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    type="text"
                    name="youtube_link"
                    id="standard-basic"
                    value={state.youtube_link || ""}
                    onChange={handleChange}
                    errorMessages={[
                      "YouTube લીંક દાખલ કરો",
                      "માન્ય YouTube લિંક દાખલ કરો",
                    ]}
                    label="YouTube લીંક *"
                    validators={["required", "isValidYoutubeURL"]}
                  />
                </Grid>
                {state?.embed_url && (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ margin: " 0 auto" }}
                  >
                    <YTItem embed_url={state?.embed_url} />
                  </Grid>
                )}
              </>
            )}
            {state?.media_type === "IMAGES" && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <MyFileInput
                  dropzoneText="IMAGES ઉમેરવા અહીં ક્લીક કરો"
                  previewText="પસંદ કરેલી IMAGE(S) :"
                  state={state}
                  acceptedFiles={["image/*"]}
                  filesLimit={10}
                  maxFileSize={10485760}
                  handleFile={handleFile}
                  initialFiles={state?.initFile || []}
                />
              </Grid>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onClick={handleCommunityShare} />}
                label="આ ગેલેરી કૉમ્યુનિટી માં પણ ઉમેરવા માંગો છો ?"
                sx={{ "& span": { fontWeight: "bold", fontSize: "16px" } }}
                checked={state?.sharecommunity || false}
              />
            </FormGroup>
          </Grid>
          {state?.sharecommunity && (
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <FormControl
                sx={{ width: "100%", mb: 2 }}
                error={state?.accessError}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  પોસ્ટ કયા સ્તરે જોઈ શકાય ?
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  label="પોસ્ટ કયા સ્તરે જોઈ શકાય ?"
                  id="demo-multiple-checkbox"
                  multiple
                  value={state?.access || []}
                  name="access"
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="પોસ્ટ કયા સ્તરે જોઈ શકાય ?"
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  <MenuItem value="બધા સ્તરે" key="બધા સ્તરે">
                    <Checkbox
                      checked={state.access.indexOf("બધા સ્તરે") > -1}
                    />
                    <ListItemText primary="બધા સ્તરે" />
                  </MenuItem>
                  <MenuItem value="નગર સ્તર" key="નગર સ્તર">
                    <Checkbox checked={state.access.indexOf("નગર સ્તર") > -1} />
                    <ListItemText primary="નગર સ્તર" />
                  </MenuItem>
                  <MenuItem value="ઉપનગર સ્તર" key="ઉપનગર સ્તર">
                    <Checkbox
                      checked={state.access.indexOf("ઉપનગર સ્તર") > -1}
                    />
                    <ListItemText primary="ઉપનગર સ્તર" />
                  </MenuItem>
                  <MenuItem value="વસ્તી સ્તર" key="વસ્તી સ્તર">
                    <Checkbox
                      checked={state.access.indexOf("વસ્તી સ્તર") > -1}
                    />
                    <ListItemText primary="વસ્તી સ્તર" />
                  </MenuItem>
                  <MenuItem value="શાખા સ્તર" key="શાખા સ્તર">
                    <Checkbox
                      checked={state.access.indexOf("શાખા સ્તર") > -1}
                    />
                    <ListItemText primary="શાખા સ્તર" />
                  </MenuItem>
                  <MenuItem value="સીટી સ્તર" key="સીટી સ્તર">
                    <Checkbox
                      checked={state.access.indexOf("સીટી સ્તર") > -1}
                    />
                    <ListItemText primary="સીટી સ્તર" />
                  </MenuItem>
                </Select>
                {state?.accessError && (
                  <FormHelperText>કોઈ એક વિકલ્પ પસંદ કરો</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={
                UpdateCommunityPostMutation.isLoading ||
                UpdatePracharKaryogalleryMutation.isLoading
              }
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default EditPracharKaryoGallery;
