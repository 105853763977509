import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box, CircularProgress, Alert } from "@mui/material";
import { useState } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useMutation, useQuery, useQueryClient } from "react-query";
import MenuItem from "@mui/material/MenuItem";
import {
  addGramSamelan,
  getGramSamelanDistrict,
  getGramSamelanMandalbyTaluka,
  getGramSamelanTalukabyDistrict,
  getGramSamelanVillagebyMandal,
} from "app/service/api-service/gramsamelan-service";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
  marginTop: "0px !important",
}));

const AddGramSamelan = (props) => {
  const [state, setState] = useState({});
  React.useEffect(() => {
    ValidatorForm.addValidationRule("isValidInput", async (value) => {
      let letterNumber = /[0-9a-zA-Z]+/g;
      if (value.match(letterNumber)) {
        return false;
      } else {
        return true;
      }
    });
    return () => ValidatorForm.removeValidationRule("isValidInput");
  }, [state]);

  const queryClient = useQueryClient();
  const { data: districtList } = useQuery(
    ["getGramSamelanDistrict"],
    getGramSamelanDistrict
  );

  const getGramSamelanTalukabyDistrictMutation = useMutation(
    getGramSamelanTalukabyDistrict
  );
  let talukaList = getGramSamelanTalukabyDistrictMutation?.data?.data;

  const getGramSamelanMandalbyTalukaMutation = useMutation(
    getGramSamelanMandalbyTaluka
  );
  let mandalList = getGramSamelanMandalbyTalukaMutation?.data?.data;

  const getGramSamelanVillagebyMandalMutation = useMutation(
    getGramSamelanVillagebyMandal
  );
  let villageList = getGramSamelanVillagebyMandalMutation?.data?.data;

  const AddGramSamelanMutation = useMutation(addGramSamelan, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["getGramSamelan"], (MainData) => {
        return [data.yadiData, ...MainData];
      });
      setState({});
      props.handleClose();
    },
  });
  const handleSubmit = async () => {
    AddGramSamelanMutation.mutate(state);
  };

  const handleChange = (event) => {
    if (event.target.name === "district_name") {
      if (event.target.value !== "") {
        getGramSamelanTalukabyDistrictMutation.mutate(event.target.value);
      } else {
        getGramSamelanTalukabyDistrictMutation.reset();
        getGramSamelanMandalbyTalukaMutation.reset();
        getGramSamelanVillagebyMandalMutation.reset();
      }
      setState({
        ...state,
        district_name: event.target.value,
        taluka_name: "",
        mandal_name: "",
        village_name: "",
      });
    } else if (event.target.name === "taluka_name") {
      if (event.target.value !== "") {
        getGramSamelanMandalbyTalukaMutation.mutate(event.target.value);
      } else {
        getGramSamelanMandalbyTalukaMutation.reset();
        getGramSamelanVillagebyMandalMutation.reset();
      }
      setState({
        ...state,
        taluka_name: event.target.value,
        mandal_name: "",
        village_name: "",
      });
    } else if (event.target.name === "mandal_name") {
      if (event.target.value !== "") {
        getGramSamelanVillagebyMandalMutation.mutate(event.target.value);
      } else {
        getGramSamelanVillagebyMandalMutation.reset();
      }
      setState({
        ...state,
        mandal_name: event.target.value,
        village_name: "",
      });
    } else if (event.target.name === "pasandagi_kam") {
      setState({
        ...state,
        pasandagi_kam: event.target.value,
        pasandagi_sub_kam: "",
        pasandagi_jodayel_karya: "",
      });
    } else if (event.target.name === "pasandagi_sub_kam") {
      setState({
        ...state,
        pasandagi_sub_kam: event.target.value,
        pasandagi_jodayel_karya: "",
      });
    } else if (event.target.name === "mobileno") {
      event.target.value.replace(/\D/g, "");
      event.target.value.substr(0, 10);
      setState({
        ...state,
        mobileno: event.target.validity.valid ? event.target.value : "",
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  return (
    <MyDialogBox
      maxWidth="md"
      open={props.open}
      handleClose={() => {
        setState({});
        props.handleClose();
      }}
      title="યાદી ઉમેરો"
      content={
        <ValidatorForm
          autoComplete="new-password"
          onSubmit={handleSubmit}
          onError={() => null}
        >
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Alert sx={{ mb: 2, fontWeight: "bold" }} severity="info">
                નીચેના ફોર્મમાં માહિતી ગુજરાતી ભાષામાં જ ભરવી.
              </Alert>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="district_name"
                value={state.district_name || ""}
                errorMessages={["આપનો જિલ્લો પસંદ કરો"]}
                label="આપનો જિલ્લો પસંદ કરો *"
                validators={["required"]}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>આપનો જિલ્લો પસંદ કરો</em>
                </MenuItem>
                {districtList?.map((district, index) => (
                  <MenuItem value={district.district_name} key={index}>
                    {district.district_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="taluka_name"
                value={state.taluka_name || ""}
                errorMessages={["આપનો તાલુકો પસંદ કરો"]}
                label="આપનો તાલુકો પસંદ કરો *"
                validators={["required"]}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {!state?.district_name ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>આપનો તાલુકો પસંદ કરો</em>
                </MenuItem>
                {talukaList?.map((taluka, index) => (
                  <MenuItem value={taluka.taluka_name} key={index}>
                    {taluka.taluka_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="mandal_name"
                value={state.mandal_name || ""}
                errorMessages={["આપનું મંડલ પસંદ કરો"]}
                label="આપનું મંડલ પસંદ કરો *"
                validators={["required"]}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {!state?.taluka_name ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>આપનું મંડલ પસંદ કરો</em>
                </MenuItem>
                {mandalList?.map((mandal, index) => (
                  <MenuItem value={mandal.mandal_name} key={index}>
                    {mandal.mandal_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="village_name"
                value={state.village_name || ""}
                errorMessages={["આપનું ગામ પસંદ કરો"]}
                label="આપનું ગામ પસંદ કરો *"
                validators={["required"]}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {!state?.mandal_name ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>આપનું ગામ પસંદ કરો</em>
                </MenuItem>
                {villageList?.map((village, index) => (
                  <MenuItem value={village.village_name} key={index}>
                    {village.village_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="pasandagi_kam"
                value={state?.pasandagi_kam || ""}
                label="પસંદગીનું કામ પસંદ કરો *"
                onChange={handleChange}
                errorMessages={["પસંદગીનું કામ પસંદ કરો"]}
                validators={["required"]}
              >
                <MenuItem value="">
                  <em>પસંદગીનું કામ પસંદ કરો</em>
                </MenuItem>
                <MenuItem value="જાગરણ" key="0">
                  જાગરણ
                </MenuItem>
                <MenuItem value="ગતિવિધિ" key="1">
                  ગતિવિધિ
                </MenuItem>
              </SelectField>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="pasandagi_sub_kam"
                value={state?.pasandagi_sub_kam || ""}
                label="પસંદગીનું કામનો પ્રકાર પસંદ કરો *"
                onChange={handleChange}
                errorMessages={["પસંદગીનું કામનો પ્રકાર પસંદ કરો"]}
                validators={["required"]}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {!state?.pasandagi_kam ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>પસંદગીનું કામનો પ્રકાર પસંદ કરો</em>
                </MenuItem>
                {state?.pasandagi_kam === "જાગરણ" && [
                  <MenuItem value="મુખ્ય માર્ગ કાર્ય" key="1">
                    મુખ્ય માર્ગ કાર્ય
                  </MenuItem>,
                  <MenuItem value="સેવા કાર્ય" key="2">
                    સેવા કાર્ય
                  </MenuItem>,
                  <MenuItem value="સામાજિક સદભાવ કાર્ય" key="3">
                    સામાજિક સદભાવ કાર્ય
                  </MenuItem>,
                  <MenuItem value="ગ્રામીણ વ્યવસાયિક કાર્ય" key="4">
                    ગ્રામીણ વ્યવસાયિક કાર્ય
                  </MenuItem>,
                  <MenuItem value="આયુર્વેદ સેવા કાર્ય" key="5">
                    આયુર્વેદ સેવા કાર્ય
                  </MenuItem>,
                ]}
                {state?.pasandagi_kam === "ગતિવિધિ" && [
                  <MenuItem value="પર્યાવરણ" key="6">
                    પર્યાવરણ
                  </MenuItem>,
                  <MenuItem value="સામાજિક સમરસતા" key="7">
                    સામાજિક સમરસતા
                  </MenuItem>,
                  <MenuItem value="ગૌ સેવા" key="8">
                    ગૌ સેવા
                  </MenuItem>,
                  <MenuItem value="કુટુંબ પ્રબોધન" key="9">
                    કુટુંબ પ્રબોધન
                  </MenuItem>,
                  <MenuItem value="ગ્રામ વિકાસ" key="10">
                    ગ્રામ વિકાસ
                  </MenuItem>,
                ]}
              </SelectField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="pasandagi_jodayel_karya"
                value={state?.pasandagi_jodayel_karya || ""}
                label="કયા કાર્ય સાથે જોડાયેલ છો ? *"
                onChange={handleChange}
                errorMessages={["કયા કાર્ય સાથે જોડાયેલ છો પસંદ કરો"]}
                validators={["required"]}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {!state?.pasandagi_sub_kam ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>કયા કાર્ય સાથે જોડાયેલ છો પસંદ કરો</em>
                </MenuItem>
                {state?.pasandagi_sub_kam === "મુખ્ય માર્ગ કાર્ય" && [
                  <MenuItem value="સંસ્કાર કેન્દ્ર" key="1">
                    સંસ્કાર કેન્દ્ર
                  </MenuItem>,
                  <MenuItem value="મંદિર કેન્દ્રિત" key="2">
                    મંદિર કેન્દ્રિત
                  </MenuItem>,
                  <MenuItem value="શક્તિ કેન્દ્ર" key="3">
                    શક્તિ કેન્દ્ર
                  </MenuItem>,
                  <MenuItem value="સ્વાવલંબનને લગતા કાર્યો" key="4">
                    સ્વાવલંબનને લગતા કાર્યો
                  </MenuItem>,
                  <MenuItem value="ગતિવિધિ કાર્ય" key="5">
                    ગતિવિધિ કાર્ય
                  </MenuItem>,
                ]}
                {state?.pasandagi_sub_kam === "સેવા કાર્ય" && [
                  <MenuItem value="સ્વાસ્થ્યને લગતા કાર્યો" key="6">
                    સ્વાસ્થ્યને લગતા કાર્યો
                  </MenuItem>,
                  <MenuItem value="સંસ્કાર કેન્દ્ર" key="7">
                    સંસ્કાર કેન્દ્ર
                  </MenuItem>,
                  <MenuItem value="શિક્ષણને લગતા કાર્ય" key="8">
                    શિક્ષણને લગતા કાર્ય
                  </MenuItem>,
                  <MenuItem value="આરોગ્ય ટોળી" key="9">
                    આરોગ્ય ટોળી
                  </MenuItem>,
                ]}
                {state?.pasandagi_sub_kam === "સામાજિક સદભાવ કાર્ય" && [
                  <MenuItem value="ગામના સામાજિક આગેવાનોની ટોળી" key="10">
                    ગામના સામાજિક આગેવાનોની ટોળી
                  </MenuItem>,
                  <MenuItem value="ગામમાં ધાર્મિક જાગરણકાર્યો" key="11">
                    ગામમાં ધાર્મિક જાગરણકાર્યો
                  </MenuItem>,
                  <MenuItem value="ગામમાં રાષ્ટ્રભક્તિને લગતા કાર્યો" key="12">
                    ગામમાં રાષ્ટ્રભક્તિને લગતા કાર્યો
                  </MenuItem>,
                ]}
                {state?.pasandagi_sub_kam === "ગ્રામીણ વ્યવસાયિક કાર્ય" && [
                  <MenuItem value="શાખાની નિયમિત બેઠક થવી" key="13">
                    શાખાની નિયમિત બેઠક થવી
                  </MenuItem>,
                  <MenuItem value="રમત કેન્દ્ર" key="14">
                    રમત કેન્દ્ર
                  </MenuItem>,
                  <MenuItem value="ગામને ઉપયોગી વિવિધ કાર્યો" key="15">
                    ગામને ઉપયોગી વિવિધ કાર્યો
                  </MenuItem>,
                ]}
                {state?.pasandagi_sub_kam === "આયુર્વેદ સેવા કાર્ય" && [
                  <MenuItem value="ગ્રામ આરોગ્ય ટોળીની બેઠક" key="16">
                    ગ્રામ આરોગ્ય ટોળીની બેઠક
                  </MenuItem>,
                  <MenuItem value="ખેલકુદ કેન્દ્ર" key="17">
                    ખેલકુદ કેન્દ્ર
                  </MenuItem>,
                  <MenuItem value="ભારત માતા પૂજન કાર્યક્રમ" key="18">
                    ભારત માતા પૂજન કાર્યક્રમ
                  </MenuItem>,
                ]}
                {state?.pasandagi_sub_kam === "પર્યાવરણ" && [
                  <MenuItem value="હરિત ઘર" key="19">
                    હરિત ઘર
                  </MenuItem>,
                  <MenuItem value="પાણીને લગતા કાર્યો" key="20">
                    પાણીને લગતા કાર્યો
                  </MenuItem>,
                  <MenuItem value="પ્લાસ્ટિકને લગતા કાર્યો" key="21">
                    પ્લાસ્ટિકને લગતા કાર્યો
                  </MenuItem>,
                  <MenuItem value="વૃક્ષને લગતા કાર્યો" key="22">
                    વૃક્ષને લગતા કાર્યો
                  </MenuItem>,
                  <MenuItem value="ઇકોમિત્રમ એપ્લિકેશન" key="23">
                    ઇકોમિત્રમ એપ્લિકેશન
                  </MenuItem>,
                ]}
                {state?.pasandagi_sub_kam === "સામાજિક સમરસતા" && [
                  <MenuItem value="જાતિગત વિષમતા દૂર કરવાના કાર્યો" key="24">
                    જાતિગત વિષમતા દૂર કરવાના કાર્યો
                  </MenuItem>,
                  <MenuItem value="રૂઢિગત કુરિવાજો દૂર કરવાના કાર્યો" key="25">
                    રૂઢિગત કુરિવાજો દૂર કરવાના કાર્યો
                  </MenuItem>,
                  <MenuItem value="બંધુતાપૂર્ણ વ્યવહારના કાર્યો" key="26">
                    બંધુતાપૂર્ણ વ્યવહારના કાર્યો
                  </MenuItem>,
                  <MenuItem value="પૂજ્ય વાલ્મીકિ જયંતિ કાર્યક્રમ" key="27">
                    પૂજ્ય વાલ્મીકિ જયંતિ કાર્યક્રમ
                  </MenuItem>,
                  <MenuItem value="મકરસંક્રાંતિ ઉત્સવ" key="28">
                    મકરસંક્રાંતિ ઉત્સવ
                  </MenuItem>,
                  <MenuItem value="ભારત માતા પૂજન કાર્યક્રમ" key="29">
                    ભારત માતા પૂજન કાર્યક્રમ
                  </MenuItem>,
                  <MenuItem value="સંત રવિદાસ જયંતિ કાર્યક્રમ " key="30">
                    સંત રવિદાસ જયંતિ કાર્યક્રમ
                  </MenuItem>,
                  <MenuItem value="એક મંદિર એક સ્મશાનને લગતા કાર્યો" key="31">
                    એક મંદિર એક સ્મશાનને લગતા કાર્યો
                  </MenuItem>,
                ]}
                {state?.pasandagi_sub_kam === "ગૌ સેવા" && [
                  <MenuItem value="ગૌ આધારિત કૃષિ" key="32">
                    ગૌ આધારિત કૃષિ
                  </MenuItem>,
                  <MenuItem value="પંચગવ્ય ઉત્પાદન" key="33">
                    પંચગવ્ય ઉત્પાદન
                  </MenuItem>,
                  <MenuItem value="ગૌ પાલન" key="34">
                    ગૌ પાલન
                  </MenuItem>,
                  <MenuItem value="ગૌ ચિકિત્સા" key="35">
                    ગૌ ચિકિત્સા
                  </MenuItem>,
                  <MenuItem value="પંચગવ્ય વેચાણ કેન્દ્ર" key="36">
                    પંચગવ્ય વેચાણ કેન્દ્ર
                  </MenuItem>,
                ]}
                {state?.pasandagi_sub_kam === "કુટુંબ પ્રબોધન" && [
                  <MenuItem value="મંગલ સંવાદ" key="37">
                    મંગલ સંવાદ
                  </MenuItem>,
                  <MenuItem value="પરિવાર વાર્તાલાપ" key="38">
                    પરિવાર વાર્તાલાપ
                  </MenuItem>,
                  <MenuItem value="પરિવાર મિલન" key="39">
                    પરિવાર મિલન
                  </MenuItem>,
                  <MenuItem value="પરિવાર સંપર્ક" key="40">
                    પરિવાર સંપર્ક
                  </MenuItem>,
                  <MenuItem value="માતૃ પિતૃ વંદન પૂજન" key="41">
                    માતૃ પિતૃ વંદન પૂજન
                  </MenuItem>,
                  <MenuItem value="ગૃહસ્થ પ્રબોધન" key="42">
                    ગૃહસ્થ પ્રબોધન
                  </MenuItem>,
                  <MenuItem value="પ્રૌઢ પ્રબોધન" key="43">
                    પ્રૌઢ પ્રબોધન
                  </MenuItem>,
                ]}
                {state?.pasandagi_sub_kam === "ગ્રામ વિકાસ" && [
                  <MenuItem value="સંસ્કાર કેન્દ્ર" key="44">
                    સંસ્કાર કેન્દ્ર
                  </MenuItem>,
                  <MenuItem value="શિક્ષણ કેન્દ્ર" key="45">
                    શિક્ષણ કેન્દ્ર
                  </MenuItem>,
                  <MenuItem value="સ્વાસ્થ્યને લગતા કાર્યો" key="46">
                    સ્વાસ્થ્યને લગતા કાર્યો
                  </MenuItem>,
                  <MenuItem value="સ્વાવલંબનને લગતા કાર્યો" key="47">
                    સ્વાવલંબનને લગતા કાર્યો
                  </MenuItem>,
                  <MenuItem value="જૈવિક ખેતી" key="48">
                    જૈવિક ખેતી
                  </MenuItem>,
                ]}
                <MenuItem value="અન્ય કાર્ય" key="49">
                  અન્ય કાર્ય
                </MenuItem>
              </SelectField>
            </Grid>
            {state?.pasandagi_jodayel_karya === "અન્ય કાર્ય" && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="text"
                  name="anya_karya"
                  id="standard-basic"
                  value={state.anya_karya || ""}
                  onChange={handleChange}
                  errorMessages={[
                    "અન્ય કાર્ય દાખલ કરો",
                    "ગુજરાતી ભાષામાં માહિતી ઉમેરો",
                  ]}
                  label="અન્ય કાર્ય *"
                  validators={["required", "isValidInput"]}
                />
              </Grid>
            )}
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <TextField
                type="text"
                name="full_name"
                id="standard-basic"
                value={state.full_name || ""}
                onChange={handleChange}
                errorMessages={[
                  "આપનું પુરુ નામ દાખલ કરો",
                  "ગુજરાતી ભાષામાં માહિતી ઉમેરો",
                ]}
                label="આપનું પુરુ નામ *"
                validators={["required", "isValidInput"]}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: "10",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="mobileno"
                id="standard-basic1"
                value={state.mobileno || ""}
                onChange={handleChange}
                errorMessages={[
                  "મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                ]}
                label="મોબાઇલ નંબર *"
                validators={[
                  "required",
                  "isNumber",
                  "minStringLength: 10",
                  "maxStringLength: 10",
                ]}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="gender"
                value={state?.gender || ""}
                label="જાતિ *"
                onChange={handleChange}
                errorMessages={["જાતિનો વિકલ્પ પસંદ કરો"]}
                validators={["required"]}
              >
                <MenuItem value="">
                  <em>જાતિનો વિકલ્પ પસંદ કરો</em>
                </MenuItem>
                <MenuItem value="પુરૂષ" key="0">
                  પુરૂષ
                </MenuItem>
                <MenuItem value="સ્ત્રી" key="1">
                  સ્ત્રી
                </MenuItem>
              </SelectField>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <TextField
                type="text"
                name="street_name"
                id="standard-basic3"
                value={state.street_name || ""}
                onChange={handleChange}
                label="શેરી / મહોલ્લા નું નામ"
                errorMessages={["ગુજરાતી ભાષામાં માહિતી ઉમેરો"]}
                validators={["isValidInput"]}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TextField
                type="text"
                name="email"
                id="standard-basic3"
                value={state.email || ""}
                onChange={handleChange}
                label="ઈ. મેઈલ આઈ. ડી"
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="abhyas"
                value={state?.abhyas || ""}
                label="અભ્યાસ"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>અભ્યાસનો વિકલ્પ પસંદ કરો</em>
                </MenuItem>
                <MenuItem value="10 પાસ" key="0">
                  10 પાસ
                </MenuItem>
                <MenuItem value="12 પાસ" key="1">
                  12 પાસ
                </MenuItem>
                <MenuItem value="ગ્રેજ્યુએટ" key="2">
                  ગ્રેજ્યુએટ
                </MenuItem>
                <MenuItem value="પોસ્ટ ગ્રેજ્યુએટ" key="3">
                  પોસ્ટ ગ્રેજ્યુએટ
                </MenuItem>
                <MenuItem value="આઈ.ટી.આઈ" key="4">
                  આઈ.ટી.આઈ
                </MenuItem>
                <MenuItem value="ડિપ્લોમા" key="5">
                  ડિપ્લોમા
                </MenuItem>
                <MenuItem value="નર્સિંગ" key="6">
                  નર્સિંગ
                </MenuItem>
                <MenuItem value="પી.ટી.સી" key="7">
                  પી.ટી.સી
                </MenuItem>
                <MenuItem value="બી.એડ." key="8">
                  બી.એડ.
                </MenuItem>
                <MenuItem value="એન્જિનિયરિંગ" key="9">
                  એન્જિનિયરિંગ
                </MenuItem>
                <MenuItem value="મેડિકલ વિદ્યાર્થી" key="10">
                  મેડિકલ વિદ્યાર્થી
                </MenuItem>
              </SelectField>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="vyavasay"
                value={state?.vyavasay || ""}
                label="વ્યવસાય"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>વ્યવસાયનો વિકલ્પ પસંદ કરો</em>
                </MenuItem>
                <MenuItem value="ખેડૂત" key="0">
                  ખેડૂત
                </MenuItem>
                <MenuItem value="શિક્ષક" key="1">
                  શિક્ષક
                </MenuItem>
                <MenuItem value="શાળાના આચાર્ય" key="2">
                  શાળાના આચાર્ય
                </MenuItem>
                <MenuItem value="કોલેજના પ્રાધ્યાપક" key="3">
                  કોલેજના પ્રાધ્યાપક
                </MenuItem>
                <MenuItem value="ડોક્ટર" key="4">
                  ડોક્ટર
                </MenuItem>
                <MenuItem value="અઘીવક્તા (વકીલ)" key="5">
                  અઘીવક્તા (વકીલ)
                </MenuItem>
                <MenuItem value="બિલ્ડર" key="6">
                  બિલ્ડર
                </MenuItem>
                <MenuItem value="ઉદ્યોગપતિ" key="7">
                  ઉદ્યોગપતિ
                </MenuItem>
                <MenuItem value="અન્ય વ્યવસાય" key="8">
                  અન્ય વ્યવસાય
                </MenuItem>
              </SelectField>
            </Grid>
            {state?.vyavasay === "અન્ય વ્યવસાય" && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="text"
                  name="anya_vyavasay"
                  id="standard-basic"
                  value={state.anya_vyavasay || ""}
                  onChange={handleChange}
                  label="અન્ય વ્યવસાય *"
                  errorMessages={[
                    "અન્ય વ્યવસાય દાખલ કરો",
                    "ગુજરાતી ભાષામાં માહિતી ઉમેરો",
                  ]}
                  validators={["required", "isValidInput"]}
                />
              </Grid>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                type="text"
                name="sanstha"
                id="standard-basic3"
                value={state.sanstha || ""}
                onChange={handleChange}
                label="આપ કઈ સંસ્થા સાથે જોડાયેલ છો? (સંસ્થાનું પુરુ નામ લખો)"
                errorMessages={["ગુજરાતી ભાષામાં માહિતી ઉમેરો"]}
                validators={["isValidInput"]}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={AddGramSamelanMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default AddGramSamelan;
