import BethakoCategory from "./BethakoCategory";
import SaptahikBethako from "./SaptahikBethako";
import VarshikBethako from "./VarshikBethako";
import PratimasBethako from "./PratimasBethako";
import NagarPravasdin from "./nagar_pravasdin/NagarPravasdin";
import SharirikAbhyasvarg from "./sharirik_varg/SharirikAbhyasvarg";
import BaudhikVarg from "./baudhik_varg/BaudhikVarg";
import KaryakariniBethak from "./karyakarini_bethak/KaryakariniBethak";
import UpnagarSaptahik from "./upnagar_saptahik/UpnagarSaptahik";
import VishaysahBethak from "./vishaysah_bethak/VishaysahBethak";
import UpnagarEkatrikaran from "./upnagar_ekatrikaran/UpnagarEkatrikaran";
import BalVarg from "./bal_varg/BalVarg";
import Vistrutbethak from "./vistrut_bethak/Vistrutbethak";
import VistarSaptah from "./vistar_saptah/VistarSaptah";
import VastiPramukhvarg from "./vasti_pramukhvarg/VastiPramukhvarg";
import VastiEkatrikaran from "./vasti_ekatrikaran/VastiEkatrikaran";
import GanveshdinVrut from "./ganveshdin_vrut/GanveshdinVrut";
import PraharVrut from "./prahar_vrut/PraharVrut";

const bethakoRoutes = [
  {
    path: "/dashboard/bethako",
    element: <BethakoCategory />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/saptahik_bethako",
    element: <SaptahikBethako />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/nagar_pravas_din",
    element: <NagarPravasdin />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/pratimas_bethako",
    element: <PratimasBethako />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/sharirik_abhyas_varg",
    element: <SharirikAbhyasvarg />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/baudhik_varg",
    element: <BaudhikVarg />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/karyakarini_bethak",
    element: <KaryakariniBethak />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/upnagar_saptahik_bethak",
    element: <UpnagarSaptahik />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vishaysah_bethak",
    element: <VishaysahBethak />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/upnagar_ekatrikaran",
    element: <UpnagarEkatrikaran />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/varshik_bethako",
    element: <VarshikBethako />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/bal_varg",
    element: <BalVarg />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/nagar_vistrut_bethak",
    element: <Vistrutbethak />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vistar_saptah",
    element: <VistarSaptah />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vasti_pramukhvarg",
    element: <VastiPramukhvarg />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vasti_ekatrikaran",
    element: <VastiEkatrikaran />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/ganveshdin_vrut",
    element: <GanveshdinVrut />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/prahar_vrut",
    element: <PraharVrut />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
];

export default bethakoRoutes;
