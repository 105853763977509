import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getUpsthiti(date, shakha_id) {
  const response = await axiosInstance.get("api/upsthiti/getupsthiti", {
    params: {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      shakha_id,
    },
  });
  return response.data;
}

export async function getUpsthitibyMonth({ month, year, shakha_id }) {
  const response = await axiosInstance.post("api/upsthiti/getupsthitibymonth", {
    month,
    year,
    shakha_id,
  });
  return response;
}

export async function addUpsthiti({
  date,
  shakha_id,
  staff_id,
  bal,
  tarun,
  yog,
  shishu,
  vishesh,
}) {
  const response = await axiosInstance.post("api/upsthiti/addupsthiti", {
    date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
    shakha_id,
    staff_id,
    bal,
    tarun,
    yog,
    shishu,
    vishesh,
  });
  return response;
}
