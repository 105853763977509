import { Card } from "@mui/material";
import { Box, styled } from "@mui/system";

const CardRoot = styled(Card)(() => ({
  height: "100%",
  padding: "20px 24px",
  boxShadow: "-3px 4px 15px 0px rgb(92 92 92 / 75%) !important",
}));

const CardTitle = styled("div")(({ subtitle, title }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  marginBottom: !subtitle && "16px",
  color: "#2c3c6e",
  textAlign: "center",
  borderBottom: title && "2px dashed #2c3c6e",
  paddingBottom: "10px",
}));

const SimpleCard = ({ children, title, subtitle, icon }) => {
  return (
    <CardRoot elevation={6}>
      <CardTitle subtitle={subtitle} title={title}>
        {title}
      </CardTitle>
      {subtitle && <Box sx={{ mb: 2, color: "#2c3c6e" }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
