import * as React from "react";
import {
  Box,
  Card,
  styled,
  Icon,
  Tooltip,
  IconButton,
  Alert,
  Grid,
} from "@mui/material";
import Layout from "../../../Layout";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";
import EditLocalSamiti from "./EditLocalSamiti";
import { H3 } from "app/components/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteLocalSamiti,
  getLocalSamitibyVasti,
} from "app/service/api-service/localsamiti-service";
import AddPersonSamiti from "./AddPersonSamiti";
import ViewLocalSamitiSabhyo from "./ViewLocalSamitiSabhyo";
import OnlyLocalSamitiPDF from "./OnlyLocalSamitiPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { LoadingButton } from "@mui/lab";

const StyledCard = styled(Card)(({ theme }) => ({
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const ViewLocalSamiti = () => {
  const confirm = useConfirm();
  const [editopen, setEditopen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [addpersonopen, setAddpersonopen] = useState(false);
  const [addperson, setAddperson] = useState(false);
  const [viewpersonopen, setViewpersonopen] = useState(false);
  const [viewperson, setViewperson] = useState(false);
  const [PDFLoading, setPDFLoading] = useState(false);
  const { state, pathname } = useLocation();
  const { vasti } = state;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: MainData, isLoading } = useQuery(
    ["getLocalSamitibyVasti", vasti._id],
    () => getLocalSamitibyVasti(vasti._id)
  );

  const DeleteLocalSamitiMutation = useMutation(deleteLocalSamiti, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["getLocalSamitibyVasti", vasti._id],
        (MainData) => {
          return MainData.filter((samiti) => {
            return samiti._id !== data.localsamiti._id;
          });
        }
      );
    },
  });

  const handleDelete = async (id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ લોકલ સમિતિ કાઢી નાખવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        DeleteLocalSamitiMutation.mutate(id);
      })
      .catch(() => {});
  };

  const handleClickOpen = (type) => {
    if (type === "add_person") {
      setAddpersonopen(true);
    } else if (type === "view_person") {
      setViewpersonopen(true);
    } else {
      setEditopen(true);
    }
    navigate(pathname + "#alert", {
      state: { vasti: vasti },
    });
  };

  const handleClose = () => {
    setEditopen(false);
    setEdit(false);
    setAddpersonopen(false);
    setAddperson(false);
    setViewpersonopen(false);
    setViewperson(false);
  };
  const DownloadPDF = async () => {
    setPDFLoading(true);
    const doc = (
      <OnlyLocalSamitiPDF
        localsamitiList={MainData}
        vasti_name={vasti.vasti_name}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, vasti.vasti_name + " લોકલ સમિતિ.pdf");
    setPDFLoading(false);
  };
  return (
    <Layout
      isLoading={isLoading || false}
      title={vasti.vasti_name + " લોકલ સમિતિ"}
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              pb: 2,
              borderBottom: "2px dotted #2c3c6e",
            }}
          >
            <LoadingButton
              onClick={DownloadPDF}
              variant="contained"
              loading={isLoading || PDFLoading}
              color="primary"
              style={{
                minHeight: 36,
                margin: "10px 0 10px 10px",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">picture_as_pdf</Icon> &nbsp;ડાઉનલોડ રિપોર્ટ
            </LoadingButton>
          </Box>
          <Grid container spacing={1.5} sx={{ mb: "24px", mt: 0.5 }}>
            {MainData?.map((val, index) => (
              <Grid
                sx={{ mt: 1 }}
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                key={index}
              >
                <StyledCard elevation={6} sx={{ borderRadius: "8px" }}>
                  <ContentBox>
                    <H3
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        borderBottom: "1px dashed #2c3c6e",
                        width: "100%",
                        padding: "10px 5px 10px 5px",
                      }}
                    >
                      {val?.local_samiti_name}
                    </H3>
                    <Box
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        padding: "5px",
                      }}
                    >
                      <Tooltip title="સભ્યો ઉમેરો" placement="bottom">
                        <IconButton
                          onClick={() => {
                            setAddperson({
                              samiti_id: val._id,
                              persons: val.persons,
                            });
                            handleClickOpen("add_person");
                          }}
                        >
                          <Icon color="inherit">add</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="સભ્યો જુઓ" placement="bottom">
                        <IconButton
                          onClick={() => {
                            setViewperson({
                              ...val,
                            });
                            handleClickOpen("view_person");
                          }}
                        >
                          <Icon color="primary">reorder</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="સંપાદિત કરો" placement="bottom">
                        <IconButton
                          onClick={() => {
                            setEdit({
                              ...val,
                              vasti_id: val.vasti_id._id,
                            });
                            handleClickOpen("edit");
                          }}
                        >
                          <Icon color="primary">edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="કાઢી નાખો" placement="bottom">
                        <IconButton
                          onClick={() => {
                            handleDelete(val._id);
                          }}
                        >
                          <Icon color="error">delete</Icon>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ContentBox>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
          {MainData?.length < 1 && (
            <Alert severity="info">
              <b>કોઈ ડેટા મળ્યો નથી</b>
            </Alert>
          )}
          {addpersonopen && (
            <AddPersonSamiti
              handleClose={handleClose}
              open={addpersonopen}
              addperson={addperson}
              value={vasti._id}
            />
          )}
          {viewpersonopen && (
            <ViewLocalSamitiSabhyo
              handleClose={handleClose}
              open={viewpersonopen}
              viewperson={viewperson}
            />
          )}
          {edit && (
            <EditLocalSamiti
              handleClose={handleClose}
              open={editopen}
              edit={edit}
              value={vasti._id}
            />
          )}
        </>
      }
    />
  );
};

export default ViewLocalSamiti;
