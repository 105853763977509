import * as React from "react";
import {
  Box,
  Card,
  styled,
  Alert,
  Grid,
  Chip,
  IconButton,
  Tooltip,
  Icon,
} from "@mui/material";
import Layout from "../../Layout";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteSamparkshreniyadi,
  getSamparkshrenibyShreniprakar,
} from "app/service/api-service/samparkshreniyadi-service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { H3 } from "app/components/Typography";
import ViewYadiDetails from "./ViewYadiDetails";
import EditSamparkshreniYadi from "./EditSamparkshreniYadi";
import { useConfirm } from "material-ui-confirm";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const ViewSamparkYadi = () => {
  const { state, pathname } = useLocation();
  const { samparkshreni_prakar } = state;
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [detailopen, setDetailOpen] = useState(false);
  const [yadiData, setYadiData] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [edit, setEdit] = useState(false);

  const queryClient = useQueryClient();
  const { data: YadiList, isLoading } = useQuery(
    ["getSamparkshrenibyShreniprakar", samparkshreni_prakar],
    () => getSamparkshrenibyShreniprakar(samparkshreni_prakar)
  );

  const DeleteSamparkshreniyadiMutation = useMutation(deleteSamparkshreniyadi, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["getSamparkshrenibyShreniprakar", samparkshreni_prakar],
        (YadiList) => {
          return YadiList.filter((yadi) => {
            return yadi._id !== data.samparkshreni._id;
          });
        }
      );
    },
  });

  const handleClickOpen = (type) => {
    if (type === "edit") {
      setEditopen(true);
      navigate(pathname + "#alert", {
        state: { samparkshreni_prakar: samparkshreni_prakar },
      });
    } else {
      setDetailOpen(true);
    }
  };

  const handleClose = () => {
    setDetailOpen(false);
    setYadiData(false);
    setEditopen(false);
    setEdit(false);
  };

  const handleDelete = async (id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ યાદી કાઢી નાખવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        DeleteSamparkshreniyadiMutation.mutate(id);
      })
      .catch(() => {});
  };

  return (
    <Layout
      isLoading={isLoading || false}
      title={samparkshreni_prakar}
      content={
        <>
          <Grid container spacing={0} sx={{ mb: "24px", mt: 0.5 }}>
            {YadiList?.map((val, index) => (
              <Grid container spacing={0} key={index}>
                <Grid
                  sx={{ mt: 3 }}
                  item
                  lg={1.5}
                  xs={2.8}
                  onClick={() => {
                    setYadiData(val);
                    handleClickOpen();
                  }}
                >
                  <StyledCard
                    elevation={6}
                    sx={{ borderRadius: "8px 0 0 8px", height: "100%" }}
                  >
                    <ContentBox>
                      <Box>
                        <H3 sx={{ fontWeight: "bold", textAlign: "center" }}>
                          &nbsp;({index + 1})&nbsp;
                        </H3>
                      </Box>
                    </ContentBox>
                  </StyledCard>
                </Grid>
                <Grid sx={{ mt: 3 }} item lg={10.5} xs={9.2}>
                  <StyledCard
                    elevation={6}
                    sx={{ borderRadius: "0 8px 8px 0" }}
                  >
                    <ContentBox>
                      <Box sx={{ fontWeight: "bold", textAlign: "center" }}>
                        <H3 sx={{ fontWeight: "bold", textAlign: "center" }}>
                          {val.name}
                        </H3>
                        <Tooltip title="વધુ વિગતો" placement="bottom">
                          <IconButton
                            onClick={() => {
                              setYadiData(val);
                              handleClickOpen();
                            }}
                            sx={{ pb: 0 }}
                          >
                            <Icon color="primary">reorder</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="સંપાદિત કરો" placement="bottom">
                          <IconButton
                            onClick={() => {
                              setEdit({
                                ...val,
                                samparkshreni_prakar_sel: samparkshreni_prakar,
                              });
                              handleClickOpen("edit");
                            }}
                          >
                            <Icon color="primary">edit</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="કાઢી નાખો" placement="bottom">
                          <IconButton
                            onClick={() => {
                              handleDelete(val._id);
                            }}
                          >
                            <Icon color="error">delete</Icon>
                          </IconButton>
                        </Tooltip>
                        {val.is_sampark_pramukh === 1 && (
                          <>
                            <Chip
                              sx={{ mt: 1 }}
                              label={<b>સંપર્ક પ્રમુખ</b>}
                              color="primary"
                            />
                          </>
                        )}
                      </Box>
                    </ContentBox>
                  </StyledCard>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {YadiList?.length < 1 && (
            <Alert severity="info">
              <b>કોઈ ડેટા મળ્યો નથી</b>
            </Alert>
          )}
          {yadiData && (
            <ViewYadiDetails
              handleClose={handleClose}
              open={detailopen}
              yadiData={yadiData}
            />
          )}
          {edit && (
            <EditSamparkshreniYadi
              handleClose={handleClose}
              open={editopen}
              edit={edit}
            />
          )}
        </>
      }
    />
  );
};

export default ViewSamparkYadi;
