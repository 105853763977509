import * as React from "react";
import { Icon, Tooltip, IconButton, Button } from "@mui/material";
import Layout from "../../../Layout";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";
import MyDashDataTable from "app/components/MyDashDataTable";
import AddNagarSamiti from "./AddNagarSamiti";
import EditNagarSamiti from "./EditNagarSamiti";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import {
  deleteNagarSamiti,
  getNagarSamiti,
} from "app/service/api-service/nagarsamiti-service";
import { LoadingButton } from "@mui/lab";
import NagarSamitiPDF from "./NagarSamitiPDF";

const NagarSamiti = () => {
  const confirm = useConfirm();
  const [addopen, setAddopen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [PDFLoading, setPDFLoading] = useState(false);
  const queryClient = useQueryClient();

  const { data: nagarsamitiList, isLoading } = useQuery(
    ["getNagarSamiti"],
    getNagarSamiti
  );

  const DeleteNagarSamitiMutation = useMutation(deleteNagarSamiti, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["getNagarSamiti"], (MainData) => {
        return MainData.filter((samiti) => {
          return samiti._id !== data.nagarsamiti._id;
        });
      });
    },
  });

  const handleDelete = async (id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ નગર સમિતિ માંથી કાઢી નાખવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        DeleteNagarSamitiMutation.mutate(id);
      })
      .catch(() => {});
  };

  const handleClickOpen = (type) => {
    if (type === "edit") {
      setEditopen(true);
    } else {
      setAddopen(true);
    }
  };
  const handleClose = () => {
    setAddopen(false);
    setEditopen(false);
    setEdit(false);
  };
  const DownloadPDF = async () => {
    setPDFLoading(true);
    const doc = <NagarSamitiPDF nagarsamitiList={nagarsamitiList} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "નગર સમિતિ.pdf");
    setPDFLoading(false);
  };
  const columns = [
    { name: "ક્રમાંક", options: { filterOptions: { fullWidth: true } } },
    "નામ",
    "મોબાઈલ નંબર",
    "સંઘ પરિવાર",
    "વિશેષ નોંધ",
    {
      name: "ક્રિયાઓ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false,
      },
    },
  ];
  const options = {
    customToolbar: () => {
      return (
        <>
          <Tooltip title="ઉમેરો" placement="bottom">
            <Button
              variant="contained"
              color="primary"
              style={{ height: 36, margin: "10px 0 10px 10px" }}
              onClick={() => handleClickOpen("add")}
            >
              ઉમેરો
            </Button>
          </Tooltip>
          <Tooltip title="ડાઉનલોડ રિપોર્ટ" placement="bottom">
            <LoadingButton
              loading={PDFLoading}
              onClick={DownloadPDF}
              variant="contained"
              color="primary"
              style={{
                minHeight: 36,
                margin: "10px 0 10px 10px",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">picture_as_pdf</Icon> &nbsp;ડાઉનલોડ રિપોર્ટ
            </LoadingButton>
          </Tooltip>
        </>
      );
    },
  };
  const data = nagarsamitiList?.map((samiti, index) => [
    index + 1,
    samiti.name + (samiti.sanyojak ? " (સંયોજક)" : ""),
    samiti.mobileno,
    samiti.sangh_parivar,
    samiti.vishesh_nondh,
    <>
      <Tooltip title="સંપાદિત કરો" placement="bottom">
        <IconButton
          onClick={() => {
            setEdit({
              ...samiti,
            });
            handleClickOpen("edit");
          }}
        >
          <Icon color="primary">edit</Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title="કાઢી નાખો" placement="bottom">
        <IconButton
          onClick={() => {
            handleDelete(samiti._id);
          }}
        >
          <Icon color="error">delete</Icon>
        </IconButton>
      </Tooltip>
    </>,
  ]);
  return (
    <Layout
      isLoading={isLoading || false}
      title="નગર સમિતિ"
      content={
        <>
          <MyDashDataTable
            filename={"નગર સમિતિ.csv"}
            data={data}
            columns={columns}
            options={options}
          />
          {addopen && (
            <AddNagarSamiti handleClose={handleClose} open={addopen} />
          )}
          {edit && (
            <EditNagarSamiti
              handleClose={handleClose}
              open={editopen}
              edit={edit}
            />
          )}
        </>
      }
    />
  );
};

export default NagarSamiti;
