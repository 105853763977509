import axiosInstance from "axios-instance";

export async function getSamparkshrenibyShreniprakar(samparkshreni_prakar) {
  const response = await axiosInstance.get(
    "api/sampark_shreni/get_by_prakar/",
    {
      params: {
        samparkshreni_prakar,
      },
    }
  );
  return response.data;
}

export async function addSamparkshreniyadi({
  samparkshreni_prakar,
  name,
  mobileno,
  address,
  is_sampark_pramukh,
}) {
  const response = await axiosInstance.post("api/sampark_shreni/add_yadi", {
    samparkshreni_prakar,
    name,
    mobileno,
    address,
    is_sampark_pramukh,
  });
  return response;
}

export async function updateSamparkshreniyadi({
  _id: id,
  samparkshreni_prakar,
  name,
  mobileno,
  address,
  is_sampark_pramukh,
}) {
  const response = await axiosInstance.put(
    `api/sampark_shreni/update_yadi/${id}`,
    {
      samparkshreni_prakar,
      name,
      mobileno,
      address,
      is_sampark_pramukh,
    }
  );
  return response;
}

export async function deleteSamparkshreniyadi(id) {
  const response = await axiosInstance.delete(
    `api/sampark_shreni/delete_yadi/${id}`
  );
  return response;
}
