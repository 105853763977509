import axiosInstance from "axios-instance";

export async function getBlobFile(url) {
  const response = await axiosInstance.get(url, {
    responseType: "blob",
  });
  return response.data;
}

export async function getPracharkaryogallery() {
  const response = await axiosInstance.get(
    "api/pracharkaryogallery/fetchallpracharkaryogallery"
  );
  return response.data;
}

export async function addPracharkaryogallery(formData) {
  const response = await axiosInstance.post(
    "api/pracharkaryogallery/addpracharkaryogallery",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=XXX",
      },
    }
  );
  return response;
}

export async function updatePracharkaryogallery(formData) {
  const response = await axiosInstance.put(
    "api/pracharkaryogallery/updatepracharkaryogallery",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=XXX",
      },
    }
  );
  return response;
}

export async function deletePracharkaryogallery(id) {
  const response = await axiosInstance.delete(
    `api/pracharkaryogallery/deletepracharkaryogallery/${id}`
  );
  return response;
}
