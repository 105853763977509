import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getAdhikaripravas() {
  const response = await axiosInstance.get(
    "api/adhikaripravas/fetchalladhikaripravas"
  );
  return response.data;
}

export async function getAdhikaripravasYears() {
  const response = await axiosInstance.get(
    "api/adhikaripravas/fetchadhikaripravasyears"
  );
  return response.data;
}

export async function getAdhikaripravasbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/adhikaripravas/fetchbymonthadhikaripravas",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addAdhikaripravas({
  date,
  adhikari_name,
  samparkit_vyakti_name,
  samparkit_vyakti_shreni,
  address,
  mobileno,
  vishesh_nondh,
}) {
  const response = await axiosInstance.post(
    "api/adhikaripravas/addadhikaripravas",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      adhikari_name,
      samparkit_vyakti_name,
      samparkit_vyakti_shreni,
      address,
      mobileno,
      vishesh_nondh,
    }
  );
  return response;
}

export async function updateAdhikaripravas({
  _id: id,
  date,
  adhikari_name,
  samparkit_vyakti_name,
  samparkit_vyakti_shreni,
  address,
  mobileno,
  vishesh_nondh,
}) {
  const response = await axiosInstance.put(
    `api/adhikaripravas/updateadhikaripravas/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      adhikari_name,
      samparkit_vyakti_name,
      samparkit_vyakti_shreni,
      address,
      mobileno,
      vishesh_nondh,
    }
  );
  return response;
}

export async function deleteAdhikaripravas(id) {
  const response = await axiosInstance.delete(
    `api/adhikaripravas/deleteadhikaripravas/${id}`
  );
  return response;
}
