import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import GujaratiFont from "../../../../../pdf_fonts/MuktaVaani-Regular.ttf";
import BoldGujaratiFont from "../../../../../pdf_fonts/MuktaVaani-Bold.ttf";

Font.register({
  family: "GujaratiFont",
  src: GujaratiFont,
});
Font.register({
  family: "GujaratiFont",
  src: BoldGujaratiFont,
});
const styles = StyleSheet.create({
  page: {
    fontFamily: "GujaratiFont",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  header: {
    fontWeight: "bold",
    fontSize: "25px",
    textAlign: "center",
    margin: "0 0 10px 0",
  },
  table: {
    display: "table",
    width: "100%",
    flexDirection: "column",
  },
  tableRow: {
    flexGrow: 1,
    height: "auto",
    flexDirection: "row",
  },
  tableHeaderCol: {
    borderWidth: 2,
    borderTop: "2px solid black",
    padding: "5px",
  },
  tableCol: {
    borderWidth: 2,
    borderStyle: "solid",
    padding: "5px",
  },
  firstCell: {
    borderLeftWidth: 2,
  },
  tableColCell: {
    flexGrow: 1,
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    flexGrow: 1,
    marginTop: 5,
    marginBottom: 5,
    fontSize: "14px",
    textAlign: "center",
  },
});

const ShakhachitraPDF = ({ shakhas }) => {
  let count = 1;
  let count1 = 1;

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape" wrap>
        <View style={styles.header}>
          <Text>શાખા</Text>
        </View>
        {shakhas?.map(
          (upnagar, index) =>
            upnagar?.shakhas?.filter(({ is_saptahik }) => is_saptahik === 0)
              .length > 0 && (
              <View style={styles.table} key={index + 1}>
                <View
                  style={[
                    styles.tableRow,
                    {
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "10px 0px",
                      width: "100%",
                      border: "2px solid black",
                    },
                  ]}
                  wrap={false}
                >
                  <Text
                    style={{
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {upnagar?.upnagar_name}
                  </Text>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View
                    style={[
                      styles.firstCell,
                      styles.tableHeaderCol,
                      { width: "7%" },
                    ]}
                  >
                    <Text style={styles.tableColCell}>ક્રમાંક</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "17%" }]}>
                    <Text style={styles.tableColCell}>શાખા</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "18%" }]}>
                    <Text style={styles.tableColCell}>શાખાનો સમય</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "22%" }]}>
                    <Text style={styles.tableColCell}>શાખાનું સ્થાન</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "18%" }]}>
                    <Text style={styles.tableColCell}>શાખા કાર્યવાહ</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "18%" }]}>
                    <Text style={styles.tableColCell}>મુખ્ય શિક્ષક</Text>
                  </View>
                </View>
                {upnagar?.shakhas
                  ?.filter(({ is_saptahik }) => is_saptahik === 0)
                  .map((shakha, index1) => (
                    <View style={styles.tableRow} key={index1 + 1} wrap={false}>
                      <View
                        style={[
                          styles.tableCol,
                          styles.firstCell,
                          { width: "7%" },
                        ]}
                      >
                        <Text style={styles.tableCell}>{count++}</Text>
                      </View>
                      <View style={[styles.tableCol, { width: "17%" }]}>
                        <Text style={styles.tableCell}>
                          {shakha?.shakha_name}
                        </Text>
                      </View>
                      <View style={[styles.tableCol, { width: "18%" }]}>
                        <Text style={styles.tableCell}>{shakha?.samay}</Text>
                      </View>
                      <View style={[styles.tableCol, { width: "22%" }]}>
                        <Text style={styles.tableCell}>{shakha?.sthan}</Text>
                      </View>
                      <View style={[styles.tableCol, { width: "18%" }]}>
                        <Text style={styles.tableCell}>
                          {shakha?.shakha_user[0]?.name +
                            "\n (" +
                            shakha?.shakha_user[0]?.mobileno +
                            ")"}
                        </Text>
                      </View>
                      <View style={[styles.tableCol, { width: "18%" }]}>
                        <Text style={styles.tableCell}>
                          {shakha?.shakha_user1[0]?.name +
                            "\n (" +
                            shakha?.shakha_user1[0]?.mobileno +
                            ")"}
                        </Text>
                      </View>
                    </View>
                  ))}
              </View>
            )
        )}
        <View break style={styles.header}>
          <Text>સાપ્તાહિક મિલન</Text>
        </View>
        {shakhas?.map(
          (upnagar, index) =>
            upnagar?.shakhas?.filter(({ is_saptahik }) => is_saptahik === 1)
              .length > 0 && (
              <View style={styles.table} key={index + 1}>
                <View
                  style={[
                    styles.tableRow,
                    {
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "10px 0px",
                      width: "100%",
                      border: "2px solid black",
                    },
                  ]}
                  wrap={false}
                >
                  <Text
                    style={{
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {upnagar?.upnagar_name}
                  </Text>
                </View>
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.firstCell,
                      styles.tableHeaderCol,
                      { width: "7%" },
                    ]}
                    wrap={false}
                  >
                    <Text style={styles.tableColCell}>ક્રમાંક</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "17%" }]}>
                    <Text style={styles.tableColCell}>શાખા</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "18%" }]}>
                    <Text style={styles.tableColCell}>શાખાનો સમય</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "22%" }]}>
                    <Text style={styles.tableColCell}>શાખાનું સ્થાન</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "18%" }]}>
                    <Text style={styles.tableColCell}>શાખા કાર્યવાહ</Text>
                  </View>
                  <View style={[styles.tableHeaderCol, { width: "18%" }]}>
                    <Text style={styles.tableColCell}>મુખ્ય શિક્ષક</Text>
                  </View>
                </View>
                {upnagar?.shakhas
                  ?.filter(({ is_saptahik }) => is_saptahik === 1)
                  .map((shakha, index1) => (
                    <View style={styles.tableRow} key={index1 + 1} wrap={false}>
                      <View
                        style={[
                          styles.tableCol,
                          styles.firstCell,
                          { width: "7%" },
                        ]}
                      >
                        <Text style={styles.tableCell}>{count1++}</Text>
                      </View>
                      <View style={[styles.tableCol, { width: "17%" }]}>
                        <Text style={styles.tableCell}>
                          {shakha?.shakha_name}
                        </Text>
                      </View>
                      <View style={[styles.tableCol, { width: "18%" }]}>
                        <Text style={styles.tableCell}>{shakha?.samay}</Text>
                      </View>
                      <View style={[styles.tableCol, { width: "22%" }]}>
                        <Text style={styles.tableCell}>{shakha?.sthan}</Text>
                      </View>
                      <View style={[styles.tableCol, { width: "18%" }]}>
                        <Text style={styles.tableCell}>
                          {shakha?.shakha_user[0]?.name +
                            "\n (" +
                            shakha?.shakha_user[0]?.mobileno +
                            ")"}
                        </Text>
                      </View>
                      <View style={[styles.tableCol, { width: "18%" }]}>
                        <Text style={styles.tableCell}>
                          {shakha?.shakha_user1[0]?.name +
                            "\n (" +
                            shakha?.shakha_user1[0]?.mobileno +
                            ")"}
                        </Text>
                      </View>
                    </View>
                  ))}
              </View>
            )
        )}
      </Page>
    </Document>
  );
};
export default ShakhachitraPDF;
