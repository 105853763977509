import {
  Icon,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MyFormDialogBox from "app/components/MyFormDialogBox";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& tbody": {
    "& tr": {
      "& td": {
        paddingLeft: 0,
        textTransform: "capitalize",
        fontSize: "17px",
        "& a": {
          color: "#3b3ea1",
        },
      },
      "& td:nth-of-type(3)": {
        fontWeight: "600",
      },
    },
  },
}));

const ViewGramSamelan = (props) => {
  const yadidata = props.yadiData;

  return (
    <MyFormDialogBox
      isDetail={true}
      open={props.open}
      handleClose={() => {
        props.handleClose();
      }}
      title="વિગતો"
      content={
        <StyledTable sx={{ mt: "-15px" }}>
          <TableBody>
            <TableRow>
              <TableCell align="left" width="30%">
                નામ
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{yadidata.full_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" width="30%">
                જાતિ
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{yadidata.gender}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" width="30%">
                મોબાઇલ નંબર
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                <a
                  href={"tel:" + yadidata.mobileno}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "inline-flex" }}
                >
                  <Icon>phone</Icon>
                  &nbsp;{yadidata.mobileno}
                </a>
                <br />
                <a
                  href={`https://api.whatsapp.com/send?phone=91${yadidata.mobileno}&text=Hello`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "inline-flex" }}
                >
                  <WhatsAppIcon />
                  &nbsp;WhatsApp
                </a>
              </TableCell>
            </TableRow>
            {yadidata.email !== "" && (
              <TableRow>
                <TableCell align="left" width="30%">
                  ઈ. મેઈલ આઈ. ડી
                </TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{yadidata.email}</TableCell>
              </TableRow>
            )}
            {yadidata.abhyas !== "" && (
              <TableRow>
                <TableCell align="left" width="30%">
                  અભ્યાસ
                </TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{yadidata.abhyas}</TableCell>
              </TableRow>
            )}
            {yadidata.vyavasay !== "" && (
              <TableRow>
                <TableCell align="left" width="30%">
                  વ્યવસાય
                </TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{yadidata.vyavasay}</TableCell>
              </TableRow>
            )}
            {yadidata.anya_vyavasay !== "" && (
              <TableRow>
                <TableCell align="left" width="30%">
                  અન્ય વ્યવસાય
                </TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{yadidata.anya_vyavasay}</TableCell>
              </TableRow>
            )}
            {yadidata.street_name !== "" && (
              <TableRow>
                <TableCell align="left" width="30%">
                  શેરી / મહોલ્લા
                </TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{yadidata.street_name}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="left" width="30%">
                ગામ
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{yadidata.village_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" width="30%">
                મંડલ
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{yadidata.mandal_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" width="30%">
                તાલુકો
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{yadidata.taluka_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" width="30%">
                જિલ્લો
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{yadidata.district_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" width="30%">
                પસંદગીનું કામ
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{yadidata.pasandagi_kam}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" width="30%">
                પસંદગીનું કામનો પ્રકાર
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{yadidata.pasandagi_sub_kam}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" width="30%">
                કાર્ય સાથે જોડાયેલ
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                {yadidata.pasandagi_jodayel_karya}
              </TableCell>
            </TableRow>
            {yadidata.anya_karya !== "" && (
              <TableRow>
                <TableCell align="left" width="30%">
                  અન્ય કાર્ય
                </TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{yadidata.anya_karya}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      }
    />
  );
};

export default ViewGramSamelan;
