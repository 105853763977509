import axiosInstance from "axios-instance";
import jwtDecode from "jwt-decode";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

export async function getProfile() {
  const accessToken = window.localStorage.getItem("accessToken");
  if (accessToken && isValidToken(accessToken)) {
    const response = await axiosInstance.get("api/auth/profile");
    return response.data;
  } else {
    return false;
  }
}

export async function Login({ mobileno, password }) {
  const response = await axiosInstance.post("api/auth/login", {
    mobileno,
    password,
  });
  return response;
}

export async function SwaymsevakLogin({ mobileno }) {
  const response = await axiosInstance.post("api/auth/swaymsevaklogin", {
    mobileno,
  });
  return response;
}
