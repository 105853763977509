import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Shakha = Loadable(lazy(() => import("./Shakha")));
const Profile = Loadable(lazy(() => import("./Profile")));

const ShakhaRoutes = [
  {
    path: "/shakha/allshakha",
    element: <Shakha />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/shakha/profile",
    element: <Profile />,
    auth: ["શાખા સ્તર"],
  },
];

export default ShakhaRoutes;
