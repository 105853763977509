import { styled, Table, TableBody, TableCell, TableRow } from "@mui/material";
import MyFormDialogBox from "app/components/MyFormDialogBox";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& tbody": {
    "& tr": {
      "& td": {
        paddingLeft: 0,
        textTransform: "none",
        fontSize: "17px",
        textAlign: "center",
      },
    },
    "& tr:nth-of-type(1)": {
      "& td": {
        fontWeight: "600",
        textAlign: "center",
      },
    },
  },
}));
const ViewLocalSamitiSabhyo = (props) => {
  return (
    <MyFormDialogBox
      open={props.open}
      handleClose={() => {
        props.handleClose();
      }}
      title={props.viewperson.local_samiti_name + " ના સભ્યોની વિગતો"}
      content={
        <StyledTable sx={{ mt: "-15px", width: "100%" }}>
          <TableBody>
            <TableRow>
              <TableCell align="left" width="10%">
                ક્રમાંક
              </TableCell>
              <TableCell align="left" width="35%">
                નામ
              </TableCell>
              <TableCell align="left" width="21%">
                મોબાઇલ નંબર
              </TableCell>
              <TableCell align="left" width="34%">
                વિશેષ નોંધ
              </TableCell>
            </TableRow>
            {props.viewperson.persons.map((person, index) => (
              <TableRow key={index + 1}>
                <TableCell align="left" width="10%">
                  {index + 1}
                </TableCell>
                <TableCell align="left" width="35%">
                  {person.name}
                </TableCell>
                <TableCell align="left" width="21%">
                  {person.mobileno}
                </TableCell>
                <TableCell align="left" width="34%">
                  {person.vishesh_nondh}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      }
    />
  );
};

export default ViewLocalSamitiSabhyo;
