import * as React from "react";
import { Box, Icon, Tooltip } from "@mui/material";
import { useQuery } from "react-query";
import { useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { LoadingButton } from "@mui/lab";
import Layout from "../Layout";
import MyReportTable from "app/components/MyReportTable";
import { getVasti } from "app/service/api-service/vasti-service";
import VastiPramukhReportPDF from "./VastiPramukhReportPDF";

const VastiPramukhReport = () => {
  const [PDFLoading, setPDFLoading] = useState(false);
  const [List, setList] = useState([]);

  const { data: PDFList, isLoading } = useQuery(["getVasti"], getVasti, {
    onSuccess: (data) => {
      let count = 1;
      setList(
        data.map((vasti, index) => [
          count++,
          vasti?.vasti_name,
          vasti?.vasti_pramukh_name,
          vasti?.vasti_pramukh_mobileno,
        ])
      );
    },
  });

  const DownloadPDF = async () => {
    setPDFLoading(true);
    const doc = <VastiPramukhReportPDF List={PDFList} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "વસ્તી પ્રમુખ રિપોર્ટ.pdf");
    setPDFLoading(false);
  };
  const columns = [
    { name: "ક્રમાંક", options: { filterOptions: { fullWidth: true } } },
    "વસ્તી",
    "વસ્તી પ્રમુખ",
    "મોબાઇલ નંબર",
  ];
  const options = {};

  return (
    <Layout
      isLoading={isLoading || false}
      title="વસ્તી પ્રમુખ રિપોર્ટ"
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              borderBottom: "1px dashed #ccc",
            }}
          >
            <Tooltip title="ડાઉનલોડ રિપોર્ટ" placement="bottom">
              <LoadingButton
                loading={PDFLoading}
                onClick={DownloadPDF}
                variant="contained"
                color="primary"
                style={{
                  minHeight: 36,
                  margin: "10px 0 10px 10px",
                  fontWeight: "bold",
                }}
              >
                <Icon className="icon">picture_as_pdf</Icon> &nbsp;ડાઉનલોડ
                રિપોર્ટ
              </LoadingButton>
            </Tooltip>
          </Box>
          <MyReportTable data={List} columns={columns} options={options} />
        </>
      }
    />
  );
};

export default VastiPramukhReport;
