import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Roles = Loadable(lazy(() => import("./Roles")));

const roleRoutes = [
  {
    path: "/role/allroles",
    element: <Roles />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
];

export default roleRoutes;
