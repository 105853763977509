import Loadable from "app/components/Loadable";
import { lazy } from "react";
import SangathanShreni from "./menus/SangathanShreni";
import Shakha from "./menus/shakha/Shakha";
import ShakhaDetails from "./menus/shakha/ShakhaDetails";
import Upnagar from "./menus/Upnagar";
import Vasti from "./menus/vasti/Vasti";
import VastiDetails from "./menus/vasti/VastiDetails";
import VarshikCalender from "./menus/VarshikCalender";
import ShakhaProfile from "./menus/shakha/ShakhaProfile";
import Swaymsevak from "./menus/Swaymsevak";
import ShakhaToli from "./menus/shakha/ShakhaToli";
import AshtabinduPatrak from "./menus/shakha/AshtabinduPatrak";
import UpsthitiPatrak from "./menus/shakha/UpsthitiPatrak";
import VastiCategory from "./menus/vasti/VastiCategory";
import KaryavihinVasti from "./menus/vasti/KaryavihinVasti";
import PurnVasti from "./menus/vasti/PurnVasti";
import KaryaratVasti from "./menus/vasti/KaryaratVasti";
import TeamCategory from "./menus/team/TeamCategory";
import NagarTeam from "./menus/team/NagarTeam";
import UpnagarTeam from "./menus/team/UpnagarTeam";
import YadiCategory from "./menus/yadi/YadiCategory";
import SwaymsevakList from "./menus/yadi/SwaymsevakList";
import ShakhatoliCategory from "./menus/yadi/ShakhatoliCategory";
import ShakhatoliFilter from "./menus/yadi/ShakhatoliFilter";
import KaryaruchiCategory from "./menus/yadi/KaryaruchiCategory";
import KaryaruchiFilter from "./menus/yadi/KaryaruchiFilter";
import SanghshikshanCategory from "./menus/yadi/SanghshikshanCategory";
import SanghshikshanFilter from "./menus/yadi/SanghshikshanFilter";
import VrutCategory from "./menus/vrut/VrutCategory";
import VrutFilter from "./menus/vrut/VrutFilter";
import Reports from "./menus/reports/Reports";
import ShakhachitraReport from "./menus/reports/ShakhachitraReport";
import SwaymsevakReport from "./menus/reports/SwaymsevakReport";
import GurupurnimaUtsavReport from "./menus/reports/GurupurnimaUtsavReport";
import ShakhaReports from "./menus/shakha/reports/ShakhaReports";
import JagranShreni from "./menus/JagranShreni";
import SamparkVibhag from "./menus/sampark_vibhag/SamparkVibhag";
import SamparkshreniYadi from "./menus/sampark_vibhag/shreni_yadi/SamparkshreniYadi";
import ViewSamparkYadi from "./menus/sampark_vibhag/shreni_yadi/ViewSamparkYadi";
import ShreniVrut from "./menus/sampark_vibhag/shreni_vrut/ShreniVrut";
import VisheshNondh from "./menus/sampark_vibhag/vishesh_nondh/VisheshNondh";
import AdhikariPravas from "./menus/sampark_vibhag/adhikari_pravas/AdhikariPravas";
import SevaVibhag from "./menus/seva_vibhag/SevaVibhag";
import SevaKaryoGallery from "./menus/seva_vibhag/seva_karyo_gallery/SevaKaryoGallery";
import PracharKaryo from "./menus/prachar_vibhag/prachar_karyo/PracharKaryo";
import PracharKaryoGallery from "./menus/prachar_vibhag/prachar_karyo/PracharKaryoGallery";
import PracharVibhag from "./menus/prachar_vibhag/PracharVibhag";
import bethakoRoutes from "./menus/bethako/BethakoRoutes";
import SevaVasti from "./menus/seva_vibhag/seva_vasti/SevaVasti";
import rammandirRoutes from "./menus/ram_mandir/RammandirRoutes";
import GramSamelan from "./menus/gram_samelan/GramSamelan";
import Karyakarta from "./menus/karyakarta/Karyakarta";
import SamparkitVasti from "./menus/vasti/SamparkitVasti";
import VartmanSthiti from "./menus/vartman_sthiti/VartmanSthiti";
import VartmanSthitiReport from "./menus/reports/VartmanSthitiReport";
import SevaVastiReport from "./menus/reports/SevaVastiReport";
import VastiPramukhReport from "./menus/reports/VastiPramukhReport";

const Dashboard = Loadable(lazy(() => import("./Dashboard")));

const dashboardRoutes = [
  {
    path: "/dashboard/default",
    element: <Dashboard />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર", "શાખા સ્તર"],
  },
  {
    path: "/dashboard/sangathan_shreni",
    element: <SangathanShreni />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/jagran_shreni",
    element: <JagranShreni />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/sampark_vibhag",
    element: <SamparkVibhag />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/samparkshreni_yadi",
    element: <SamparkshreniYadi />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/view_samparkshreni_yadi",
    element: <ViewSamparkYadi />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/shreni_vrut",
    element: <ShreniVrut />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vishesh_nondh",
    element: <VisheshNondh />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/adhikari_pravas",
    element: <AdhikariPravas />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/seva_vibhag",
    element: <SevaVibhag />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/seva_vasti",
    element: <SevaVasti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/seva_karyo_gallery",
    element: <SevaKaryoGallery />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/prachar_vibhag",
    element: <PracharVibhag />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/prachar_karyo",
    element: <PracharKaryo />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/prachar_karyo/gallery",
    element: <PracharKaryoGallery />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/upnagar",
    element: <Upnagar />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/shakha",
    element: <Shakha />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/shakha/details",
    element: <ShakhaDetails />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/shakha/profile",
    element: <ShakhaProfile />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/shakha/swaymsevak",
    element: <Swaymsevak />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર", "શાખા સ્તર"],
  },
  {
    path: "/dashboard/shakha/shakha_toli",
    element: <ShakhaToli />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર", "શાખા સ્તર"],
  },
  {
    path: "/dashboard/shakhachitra",
    element: <Upnagar />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vasti",
    element: <VastiCategory />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/kulvasti",
    element: <Vasti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/karyavihinvasti",
    element: <KaryavihinVasti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/karyaratvasti",
    element: <KaryaratVasti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/team",
    element: <TeamCategory />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/nagar_team",
    element: <NagarTeam />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/upnagar_team",
    element: <UpnagarTeam />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/yadi",
    element: <YadiCategory />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/yadi/swaymsevak_yadi",
    element: <SwaymsevakList />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/yadi/shakha_toli",
    element: <ShakhatoliCategory />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/yadi/shakha_toli/filter",
    element: <ShakhatoliFilter />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/yadi/karyaruchi",
    element: <KaryaruchiCategory />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/yadi/karyaruchi/filter",
    element: <KaryaruchiFilter />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/yadi/sangh_shikshan",
    element: <SanghshikshanCategory />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/yadi/sangh_shikshan/filter",
    element: <SanghshikshanFilter />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vrut",
    element: <VrutCategory />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vrut/filter",
    element: <VrutFilter />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/purnvasti",
    element: <PurnVasti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vartman_sthiti",
    element: <VartmanSthiti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vartman_sthiti_report",
    element: <VartmanSthitiReport />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/seva_vasti_report",
    element: <SevaVastiReport />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vasti_pramukh_report",
    element: <VastiPramukhReport />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/samparkitvasti",
    element: <SamparkitVasti />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/vasti/details",
    element: <VastiDetails />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/varshik_calender",
    element: <VarshikCalender />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/ashtabindu/view_patrak",
    element: <AshtabinduPatrak />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર", "શાખા સ્તર"],
  },
  {
    path: "/dashboard/upsthiti/view_patrak",
    element: <UpsthitiPatrak />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર", "શાખા સ્તર"],
  },
  {
    path: "/dashboard/reports",
    element: <Reports />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/shakha/reports",
    element: <ShakhaReports />,
    auth: ["શાખા સ્તર"],
  },
  {
    path: "/dashboard/reports/shakhachitra",
    element: <ShakhachitraReport />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/reports/swaymsevak_yadi",
    element: <SwaymsevakReport />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
  {
    path: "/dashboard/reports/gurupurnima_utsav",
    element: <GurupurnimaUtsavReport />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર", "શાખા સ્તર"],
  },
  {
    path: "/dashboard/gram_samelan",
    element: <GramSamelan />,
    auth: ["માહિતી સ્તર"],
  },
  {
    path: "/dashboard/mahiti_karyakarta",
    element: <Karyakarta />,
    auth: ["માહિતી સ્તર"],
  },
  ...rammandirRoutes,
  ...bethakoRoutes,
];

export default dashboardRoutes;
