import axiosInstance from "axios-instance";

export async function getUpnagarSamiti() {
  const response = await axiosInstance.get(
    "api/upnagarsamiti/fetchallupnagarsamiti"
  );
  return response.data;
}

export async function getUpnagarSamitibyGroup() {
  const response = await axiosInstance.get(
    "api/upnagarsamiti/fetchallupnagarsamitibygroup"
  );
  return response.data;
}

export async function getUpnagarSamitibyUpnagar(upnagar_id) {
  const response = await axiosInstance.get(
    `api/upnagarsamiti/getupnagarsamitibyupnagar/${upnagar_id}`
  );
  return response.data;
}

export async function addUpnagarSamiti({
  upnagar_id,
  name,
  mobileno,
  sangh_parivar,
  sanyojak,
  vishesh_nondh,
}) {
  const response = await axiosInstance.post(
    "api/upnagarsamiti/addupnagarsamiti",
    {
      upnagar_id,
      name,
      mobileno,
      sangh_parivar,
      sanyojak,
      vishesh_nondh,
    }
  );
  return response;
}

export async function updateUpnagarSamiti({
  _id: id,
  upnagar_id,
  name,
  mobileno,
  sangh_parivar,
  sanyojak,
  vishesh_nondh,
}) {
  const response = await axiosInstance.put(
    `api/upnagarsamiti/updateupnagarsamiti/${id}`,
    {
      upnagar_id,
      name,
      mobileno,
      sangh_parivar,
      sanyojak,
      vishesh_nondh,
    }
  );
  return response;
}

export async function deleteUpnagarSamiti(id) {
  const response = await axiosInstance.delete(
    `api/upnagarsamiti/deleteupnagarsamiti/${id}`
  );
  return response;
}
