import axiosInstance from "axios-instance";

export async function getVasti() {
  const response = await axiosInstance.get("api/vasti/fetchallvasti");
  return response.data;
}

export async function getVastibyShakhacount() {
  const response = await axiosInstance.get("api/vasti/fetchvastibyshakhacount");
  return response.data;
}

export async function getVastibyUpnagar(upnagar_id) {
  const response = await axiosInstance.get(
    `api/vasti/fetchvastibyupnagar/${upnagar_id}`
  );
  return response;
}

export async function getSocietybyVasti(vasti_id) {
  const response = await axiosInstance.get(
    `api/vasti/fetchsocietybyvasti/${vasti_id}`
  );
  return response.data;
}

export async function addVasti({
  nagar_id,
  upnagar_id,
  vasti_name,
  vasti_pramukh_name,
  vasti_pramukh_mobileno,
  vasti_vistar,
}) {
  const response = await axiosInstance.post("api/vasti/addvasti", {
    nagar_id,
    upnagar_id,
    vasti_name,
    vasti_pramukh_name,
    vasti_pramukh_mobileno,
    vasti_vistar,
  });
  return response;
}

export async function updateVasti({
  _id: id,
  nagar_id,
  upnagar_id,
  vasti_name,
  vasti_pramukh_name,
  vasti_pramukh_mobileno,
  vasti_vistar,
}) {
  const response = await axiosInstance.put(`api/vasti/updatevasti/${id}`, {
    nagar_id,
    upnagar_id,
    vasti_name,
    vasti_pramukh_name,
    vasti_pramukh_mobileno,
    vasti_vistar,
  });
  return response;
}

export async function deleteVasti(id) {
  const response = await axiosInstance.delete(`api/vasti/deletevasti/${id}`);
  return response;
}

export async function addVastiSociety(data) {
  const response = await axiosInstance.post("api/vasti/addvastisociety", {
    data,
  });
  return response;
}
