import { Alert, Box, Card, Grid, Icon, styled } from "@mui/material";
import { H3 } from "app/components/Typography";
import Layout from "../Layout";
import { useQuery } from "react-query";
import { useState } from "react";
import { getStaff } from "app/service/api-service/staff-service";
import ViewDetail from "./ViewDetail";
import { LoadingButton } from "@mui/lab";
import NagarteamPDF from "./NagarteamPDF";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const NagarTeam = () => {
  const { data: staffList, isLoading } = useQuery(["getStaff"], getStaff);

  const [detailopen, setDetailOpen] = useState(false);
  const [staffData, setStaffData] = useState(false);
  const [PDFLoading, setPDFLoading] = useState(false);

  const handleClickOpen = (type) => {
    setDetailOpen(true);
  };
  const handleClose = () => {
    setDetailOpen(false);
    setStaffData(false);
  };

  const DownloadPDF = async () => {
    setPDFLoading(true);
    const doc = (
      <NagarteamPDF
        stafflist={staffList
          ?.filter(
            ({ role_id }) => role_id && role_id.role_level === "નગર સ્તર"
          )
          ?.sort(function (a, b) {
            return a?.role_id?._id
              .toString()
              .localeCompare(b?.role_id?._id.toString());
          })}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "નગર ટીમ રિપોર્ટ.pdf");
    setPDFLoading(false);
  };

  return (
    <>
      <Layout
        isLoading={isLoading || false}
        title="નગર ટીમ"
        content={
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                mt: 2,
              }}
            >
              <LoadingButton
                onClick={DownloadPDF}
                variant="contained"
                loading={PDFLoading || isLoading}
                color="primary"
                style={{
                  minHeight: 36,
                  margin: "10px 0 10px 10px",
                  fontWeight: "bold",
                }}
              >
                <Icon className="icon">picture_as_pdf</Icon> &nbsp;ડાઉનલોડ
                રિપોર્ટ
              </LoadingButton>
            </Box>
            <Grid container spacing={0} sx={{ mb: "24px", mt: 0.5 }}>
              {staffList
                ?.filter(
                  ({ role_id }) => role_id && role_id.role_level === "નગર સ્તર"
                )
                ?.sort(function (a, b) {
                  return a?.role_id?._id
                    .toString()
                    .localeCompare(b?.role_id?._id.toString());
                })
                ?.map((staff, index) => (
                  <Grid container spacing={0} key={index}>
                    <Grid
                      sx={{ mt: 3 }}
                      item
                      lg={1.5}
                      xs={2.8}
                      onClick={() => {
                        setStaffData(staff);
                        handleClickOpen();
                      }}
                    >
                      <StyledCard
                        elevation={6}
                        sx={{ borderRadius: "8px 0 0 8px", height: "100%" }}
                      >
                        <ContentBox>
                          <Box>
                            <H3
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              &nbsp;({index + 1})&nbsp;
                            </H3>
                          </Box>
                        </ContentBox>
                      </StyledCard>
                    </Grid>
                    <Grid
                      sx={{ mt: 3 }}
                      item
                      lg={10.5}
                      xs={9.2}
                      onClick={() => {
                        setStaffData(staff);
                        handleClickOpen();
                      }}
                    >
                      <StyledCard
                        elevation={6}
                        sx={{ borderRadius: "0 8px 8px 0" }}
                      >
                        <ContentBox>
                          <Box>
                            <H3
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              {staff.name}
                            </H3>
                            <H3
                              sx={{
                                fontWeight: "bold",
                                textAlign: "center",
                                color: "#3b3ea1",
                              }}
                            >
                              ({staff.role_id.role_name})
                            </H3>
                          </Box>
                        </ContentBox>
                      </StyledCard>
                    </Grid>
                  </Grid>
                ))}
              {staffList?.filter(
                ({ role_id }) => role_id && role_id.role_level === "નગર સ્તર"
              )?.length < 1 && (
                <Alert severity="info" sx={{ mt: 2, width: "100%" }}>
                  <b>કોઈ ડેટા મળ્યો નથી</b>
                </Alert>
              )}
            </Grid>
          </>
        }
      />
      {staffData && (
        <ViewDetail
          handleClose={handleClose}
          open={detailopen}
          detail={staffData}
        />
      )}
    </>
  );
};

export default NagarTeam;
