import { styled, Table, TableBody, TableCell, TableRow } from "@mui/material";
import MyFormDialogBox from "app/components/MyFormDialogBox";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& tbody": {
    "& tr": {
      "& td": { paddingLeft: 0, textTransform: "none", fontSize: "17px" },
      "& td:nth-of-type(3)": {
        fontWeight: "600",
      },
    },
  },
}));
const SwaymsevakDetail = (props) => {
  return (
    <MyFormDialogBox
      isDetail={true}
      open={props.open}
      handleClose={() => {
        props.handleClose();
      }}
      title="સ્વયંસેવક વિગતો"
      content={
        <StyledTable sx={{ mt: "-15px" }}>
          <TableBody>
            <TableRow>
              <TableCell align="left" width="30%">
                નામ
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{props.swaymsevakdata.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">મોબાઇલ નંબર</TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                <a
                  href={"tel:" + props.swaymsevakdata.mobileno}
                  style={{ color: "#3b3ea1" }}
                >
                  {props.swaymsevakdata.mobileno}
                </a>
              </TableCell>
            </TableRow>
            {props.swaymsevakdata.address && (
              <TableRow>
                <TableCell align="left">સરનામું</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.swaymsevakdata.address}
                </TableCell>
              </TableRow>
            )}
            {props.swaymsevakdata.vyavsahay && (
              <TableRow>
                <TableCell align="left">વ્યવસાય</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.swaymsevakdata.vyavsahay}
                </TableCell>
              </TableRow>
            )}
            {props.swaymsevakdata.sanghshikshan && (
              <TableRow>
                <TableCell align="left">સંઘશિક્ષણ</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.swaymsevakdata.sanghshikshan}
                </TableCell>
              </TableRow>
            )}
            {props.swaymsevakdata.karyaruchi && (
              <TableRow>
                <TableCell align="left">કાર્યરૂચી</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.swaymsevakdata.karyaruchi}
                </TableCell>
              </TableRow>
            )}
            {props.swaymsevakdata.shakhatoli_role && (
              <TableRow>
                <TableCell align="left">શાખા ટોળી</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.swaymsevakdata.shakhatoli_role}
                </TableCell>
              </TableRow>
            )}
            {props.swaymsevakdata.shakha_id && (
              <TableRow>
                <TableCell align="left">નગર</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.swaymsevakdata.shakha_id.nagar_id.nagar_name}
                </TableCell>
              </TableRow>
            )}
            {props.swaymsevakdata.shakha_id && (
              <TableRow>
                <TableCell align="left">ઉપનગર</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.swaymsevakdata.shakha_id.upnagar_id.upnagar_name}
                </TableCell>
              </TableRow>
            )}
            {props.swaymsevakdata.shakha_id && (
              <TableRow>
                <TableCell align="left">વસ્તી</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.swaymsevakdata.shakha_id.vasti_id.vasti_name}
                </TableCell>
              </TableRow>
            )}
            {props.swaymsevakdata.shakha_id && (
              <TableRow>
                <TableCell align="left">શાખા</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {props.swaymsevakdata?.shakha_id?.shakha_name}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      }
    />
  );
};

export default SwaymsevakDetail;
