import { Card } from "@mui/material";
import { Box, styled } from "@mui/system";

const CardRoot = styled(Card)(() => ({
  height: "100%",
  boxShadow: "none !important",
}));

const CardTitle = styled("div")(({ subtitle }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  marginBottom: !subtitle && "16px",
  textAlign: "center",
  color: "#2c3c6e",
}));

const MyCard = ({ children, title, subtitle, icon }) => {
  return (
    <CardRoot elevation={6}>
      <CardTitle subtitle={subtitle}>{title}</CardTitle>
      {title && <hr />}
      {subtitle && <Box sx={{ mb: 2, color: "#2c3c6e" }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default MyCard;
