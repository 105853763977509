import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Ashtabindu = Loadable(lazy(() => import("./Ashtabindu")));

const AshtabinduRoutes = [
  {
    path: "/ashtabindu/addpatrak",
    element: <Ashtabindu />,
    auth: ["શાખા સ્તર"],
  },
];

export default AshtabinduRoutes;
