import Loadable from "app/components/Loadable";
import { lazy } from "react";

const VarshikCalender = Loadable(lazy(() => import("./VarshikCalender")));

const VarshikCalenderRoutes = [
  {
    path: "/varshik_calender/allvarshikcalender",
    element: <VarshikCalender />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
];

export default VarshikCalenderRoutes;
