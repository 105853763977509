import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { MatxTheme } from "./components";
import { SettingsProvider } from "./contexts/SettingsContext";
import GlobalSnackbar from "./components/GlobalSnackbar";
import { ConfirmProvider } from "material-ui-confirm";
import "./App.css";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
} from "react-query";
import { useDispatch } from "react-redux";
import { setSnackbar } from "app/redux/reducers/SnackbarReducer";
import App from "./App";

const App1 = () => {
  const dispatch = useDispatch();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: 1,
        staleTime: 5 * 1000,
      },
    },
    queryCache: new QueryCache({
      onError: (err) => {
        if (err?.response?.data?.is_logout) {
          dispatch(setSnackbar(true, "error", err.response.data.message));
          localStorage.removeItem("accessToken");
          setTimeout(() => {
            window.location.reload(true);
          }, 500);
        } else {
          if (err.response.data.message) {
            dispatch(setSnackbar(true, "error", err.response.data.message));
          } else if (err.response.data.errors) {
            let custom_err = "";
            err.response.data.errors.map((customerror) => {
              custom_err += customerror.msg + "\n";
              return false;
            });
            dispatch(setSnackbar(true, "error", custom_err));
          } else {
            dispatch(setSnackbar(true, "error", "Something Went Wrong"));
            console.log("Error", err.message);
          }
        }
      },
      onSuccess: (response) => {
        if (response.data.message) {
          dispatch(setSnackbar(true, "success", response.data.message));
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (err) => {
        if (err?.response?.data?.is_logout) {
          dispatch(setSnackbar(true, "error", err.response.data.message));
          localStorage.removeItem("accessToken");
          setTimeout(() => {
            window.location.href = true;
          }, 500);
        } else {
          if (err.response.data.message) {
            dispatch(setSnackbar(true, "error", err.response.data.message));
          } else if (err.response.data.errors) {
            let custom_err = "";
            err.response.data.errors.map((customerror) => {
              custom_err += customerror.msg + "\n";
              return false;
            });
            dispatch(setSnackbar(true, "error", custom_err));
          } else {
            dispatch(setSnackbar(true, "error", "Something Went Wrong"));
            console.log("Error", err.message);
          }
        }
      },
      onSuccess: (response) => {
        if (response.data.message) {
          dispatch(setSnackbar(true, "success", response.data.message));
          if (window.location.hash === "#alert") {
            window.history.back();
          }
        }
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <CssBaseline />
        <SettingsProvider>
          <MatxTheme>
            <GlobalSnackbar />
            <ConfirmProvider
              defaultOptions={{
                confirmationButtonProps: {
                  autoFocus: true,
                  color: "error",
                  variant: "contained",
                },
                cancellationButtonProps: {
                  color: "inherit",
                  variant: "contained",
                },
              }}
            >
              <App />
            </ConfirmProvider>
          </MatxTheme>
        </SettingsProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App1;
