import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box, CircularProgress, Alert } from "@mui/material";
import { useState } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useMutation, useQuery, useQueryClient } from "react-query";
import MenuItem from "@mui/material/MenuItem";
import {
  getKaryakartaDistrict,
  getKaryakartaTalukabyDistrict,
  updateKaryakarta,
} from "app/service/api-service/karyakarta-service";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
  marginTop: "0px !important",
}));

const EditKaryakarta = (props) => {
  const [state, setState] = useState(props?.edit);
  const queryClient = useQueryClient();
  const { data: districtList } = useQuery(
    ["getKaryakartaDistrict"],
    getKaryakartaDistrict
  );

  const getKaryakartaTalukabyDistrictMutation = useMutation(
    getKaryakartaTalukabyDistrict
  );
  let talukaList = getKaryakartaTalukabyDistrictMutation?.data?.data;

  React.useEffect(() => {
    getKaryakartaTalukabyDistrictMutation.mutate(state?.district_name);
    ValidatorForm.addValidationRule("isValidInput", async (value) => {
      let letterNumber = /[0-9a-zA-Z]+/g;
      if (value.match(letterNumber)) {
        return false;
      } else {
        return true;
      }
    });
    return () => ValidatorForm.removeValidationRule("isValidInput");
    // eslint-disable-next-line
  }, []);
  const UpdateKaryakartaMutation = useMutation(updateKaryakarta, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["getKaryakarta"], (MainData) => {
        MainData.map((main, index) => {
          if (main._id === data.yadiData._id) {
            MainData[index] = data.yadiData;
          }
          return main;
        });
        return MainData;
      });
      setState({});
      props.handleClose();
    },
  });
  const handleSubmit = async () => {
    UpdateKaryakartaMutation.mutate(state);
  };

  const handleChange = (event) => {
    if (event.target.name === "district_name") {
      if (event.target.value !== "") {
        getKaryakartaTalukabyDistrictMutation.mutate(event.target.value);
      } else {
        getKaryakartaTalukabyDistrictMutation.reset();
      }
      setState({
        ...state,
        district_name: event.target.value,
        taluka_name: "",
        mandal_name: "",
        village_name: "",
      });
    } else if (event.target.name === "mobileno") {
      event.target.value.replace(/\D/g, "");
      event.target.value.substr(0, 10);
      setState({
        ...state,
        mobileno: event.target.validity.valid ? event.target.value : "",
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  return (
    <MyDialogBox
      maxWidth="md"
      open={props.open}
      handleClose={() => {
        setState({});
        props.handleClose();
      }}
      title="યાદી સંપાદિત કરો"
      content={
        <ValidatorForm
          autoComplete="new-password"
          onSubmit={handleSubmit}
          onError={() => null}
        >
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Alert sx={{ mb: 2, fontWeight: "bold" }} severity="info">
                નીચેના ફોર્મમાં માહિતી ગુજરાતી ભાષામાં જ ભરવી.
              </Alert>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="district_name"
                value={(districtList && state?.district_name) || ""}
                errorMessages={["આપનો જિલ્લો પસંદ કરો"]}
                label="આપનો જિલ્લો પસંદ કરો *"
                validators={["required"]}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>આપનો જિલ્લો પસંદ કરો</em>
                </MenuItem>
                {districtList?.map((district, index) => (
                  <MenuItem value={district.district_name} key={index}>
                    {district.district_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectField
                sx={{ mt: 1 }}
                id="standard-basic"
                name="taluka_name"
                value={(talukaList && state?.taluka_name) || ""}
                errorMessages={["આપનો તાલુકો પસંદ કરો"]}
                label="આપનો તાલુકો પસંદ કરો *"
                validators={["required"]}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {!state?.district_name ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>આપનો તાલુકો પસંદ કરો</em>
                </MenuItem>
                {talukaList?.map((taluka, index) => (
                  <MenuItem value={taluka.taluka_name} key={index}>
                    {taluka.taluka_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <TextField
                type="text"
                name="full_name"
                id="standard-basic"
                value={state.full_name || ""}
                onChange={handleChange}
                errorMessages={[
                  "આપનું પુરુ નામ દાખલ કરો",
                  "ગુજરાતી ભાષામાં માહિતી ઉમેરો",
                ]}
                label="આપનું પુરુ નામ *"
                validators={["required", "isValidInput"]}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: "10",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="mobileno"
                id="standard-basic1"
                value={String(state.mobileno) || ""}
                onChange={handleChange}
                errorMessages={[
                  "મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                ]}
                label="મોબાઇલ નંબર *"
                validators={[
                  "required",
                  "isNumber",
                  "minStringLength: 10",
                  "maxStringLength: 10",
                ]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                type="text"
                name="sthan"
                id="standard-basic3"
                value={state.sthan || ""}
                onChange={handleChange}
                label="સ્થાન (ગામ) *"
                errorMessages={[
                  "સ્થાન (ગામ) દાખલ કરો",
                  "ગુજરાતી ભાષામાં માહિતી ઉમેરો",
                ]}
                validators={["required", "isValidInput"]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                type="text"
                name="dayitva"
                id="standard-basic3"
                value={state.dayitva || ""}
                onChange={handleChange}
                label="દાયિત્વ *"
                errorMessages={[
                  "દાયિત્વ દાખલ કરો",
                  "ગુજરાતી ભાષામાં માહિતી ઉમેરો",
                ]}
                validators={["required", "isValidInput"]}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={UpdateKaryakartaMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default EditKaryakarta;
