import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box } from "@mui/material";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { updatePraharVrut } from "app/service/api-service/praharvrut-service";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useQueryClient, useMutation } from "react-query";
import MyDatePicker from "app/components/MyDatePicker";
import dayjs from "dayjs";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const EditPraharVrut = (props) => {
  const editval = props.edit;
  const [state, setState] = useState(editval);

  React.useEffect(() => {
    ValidatorForm.addValidationRule("isValidDate", (value) => {
      return dayjs(value, "DD-MM-YYYY", true).isValid();
    });
    return () => ValidatorForm.removeValidationRule("isValidDate");
  }, [state.date]);

  const queryClient = useQueryClient();

  const UpdatePraharVrutMutation = useMutation(updatePraharVrut, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["getPraharVrutbyMonth", props?.value],
        (praharvrutList) => {
          praharvrutList.map((praharvrut, index) => {
            if (praharvrut._id === data.praharvrutData._id) {
              praharvrutList[index] = data.praharvrutData;
            }
            return praharvrut;
          });
          return praharvrutList;
        }
      );
      props.setValue({
        month: state.date.get("month"),
        year: state.date.get("year"),
      });
      setState({});
      props.handleClose();
    },
  });

  const handleSubmit = async () => {
    UpdatePraharVrutMutation.mutate(state);
  };

  const handleChange = (event) => {
    event.target.value.replace(/\D/g, "");
    event.target.value.substr(0, 10);
    setState({
      ...state,
      [event.target.name]: event.target.validity.valid
        ? event.target.value
        : "",
    });
  };

  const handleDateChange = (date) => {
    setState({ ...state, date });
  };

  return (
    <MyDialogBox
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({});
      }}
      title="સંપાદિત કરો"
      content={
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <MyDatePicker
                label="તારીખ *"
                value={state.date || ""}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="sahbhagi_shakha"
                id="standard-basic1"
                value={String(state?.sahbhagi_shakha) || ""}
                onChange={handleChange}
                errorMessages={[
                  "કુલ સહભાગી શાખા દાખલ કરો",
                  "માન્ય કુલ સહભાગી શાખા દાખલ કરો",
                ]}
                label="કુલ સહભાગી શાખા *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="sahbhagi_swaymsevak"
                id="standard-basic1"
                value={String(state?.sahbhagi_swaymsevak) || ""}
                onChange={handleChange}
                errorMessages={[
                  "કુલ સહભાગી સ્વયંસેવકો દાખલ કરો",
                  "માન્ય કુલ સહભાગી સ્વયંસેવકો દાખલ કરો",
                ]}
                label="કુલ સહભાગી સ્વયંસેવકો *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="kul_prahar"
                id="standard-basic1"
                value={String(state?.kul_prahar) || ""}
                onChange={handleChange}
                errorMessages={[
                  "કુલ પ્રહાર દાખલ કરો",
                  "માન્ય કુલ પ્રહાર દાખલ કરો",
                ]}
                label="કુલ પ્રહાર *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="prahar_500"
                id="standard-basic1"
                value={String(state?.prahar_500) || ""}
                onChange={handleChange}
                errorMessages={[
                  "500થી વધુ પ્રહાર મારનાર સ્વયંસેવકો દાખલ કરો",
                  "માન્ય 500થી વધુ પ્રહાર મારનાર સ્વયંસેવકો દાખલ કરો",
                ]}
                label="500થી વધુ પ્રહાર મારનાર સ્વયંસેવકો *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="bal"
                id="standard-basic1"
                value={String(state?.bal) || ""}
                onChange={handleChange}
                errorMessages={["બાલ દાખલ કરો", "માન્ય બાલ દાખલ કરો"]}
                label="બાલ *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="tarun"
                id="standard-basic1"
                value={String(state?.tarun) || ""}
                onChange={handleChange}
                errorMessages={["તરુણ દાખલ કરો", "માન્ય તરુણ દાખલ કરો"]}
                label="તરુણ *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="yog"
                id="standard-basic1"
                value={
                  (state.yog =
                    Number(state?.tarun || 0) + Number(state?.bal || 0))
                }
                onChange={handleChange}
                errorMessages={["યોગ દાખલ કરો", "માન્ય યોગ દાખલ કરો"]}
                label="યોગ *"
                disabled={true}
                validators={["required", "isNumber"]}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={UpdatePraharVrutMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default EditPraharVrut;
