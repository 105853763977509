import { Box, Card, Grid, styled } from "@mui/material";
import { H2, Span } from "app/components/Typography";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import { getVastibyShakhacount } from "app/service/api-service/vasti-service";
import { useQuery } from "react-query";
import { useState } from "react";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const ShakhaDetails = () => {
  const [state, setstate] = useState({
    kul_vasti: 0,
    karyavihin_vasti: 0,
    karyarat_vasti: 0,
    purna_vasti: 0,
    samparkit_vasti: 0,
  });
  useQuery(["getVastibyShakhacount"], getVastibyShakhacount, {
    onSuccess: (data) => {
      let total_purn_vasti = 0;

      data.filter(({ shakha_count }) => {
        let tarun_shakha = 0;
        let tarun_v_shakha = 0;
        let prodh_shakha = 0;
        shakha_count.filter(({ shakha_prakar_1 }) => {
          if (shakha_prakar_1 === "તરુણ વિધાર્થી (કોલેજીયન) શાખા") {
            tarun_shakha += 1;
          }
          if (shakha_prakar_1 === "તરુણ વ્યવસાયિક શાખા") {
            tarun_v_shakha += 1;
          }
          if (shakha_prakar_1 === "પ્રૌઢ વ્યવસાયિક શાખા") {
            prodh_shakha += 1;
          }
          return false;
        });
        if (prodh_shakha + tarun_v_shakha + tarun_shakha >= 3) {
          total_purn_vasti += 1;
        }
        return false;
      });

      setstate({
        ...state,
        kul_vasti: data.length,
        karyavihin_vasti: data.filter(
          ({ shakha_count }) => shakha_count.length === 0
        ).length,
        samparkit_vasti: data.filter(
          ({ shakha_count }) =>
            shakha_count.filter(({ is_saptahik }) => is_saptahik === 1).length >
            0
        ).length,
        karyarat_vasti: data.filter(
          ({ shakha_count }) => shakha_count.length > 0
        ).length,
        purna_vasti: total_purn_vasti,
      });
    },
  });

  const navigate = useNavigate();

  const cardList = [
    { name: "કુલ વસ્તી", to: "/dashboard/kulvasti", count: state.kul_vasti },
    {
      name: "પૂર્ણ વસ્તી",
      to: "/dashboard/purnvasti",
      count: state.purna_vasti,
    },
    {
      name: "કાર્યયુક્ત વસ્તી",
      to: "/dashboard/karyaratvasti",
      count: state.karyarat_vasti,
    },
    {
      name: "સંપર્કીત વસ્તી",
      to: "/dashboard/samparkitvasti",
      count: state.samparkit_vasti,
    },
    {
      name: "કાર્યવિહીન વસ્તી",
      to: "/dashboard/karyavihinvasti",
      count: state.karyavihin_vasti,
    },
  ];

  return (
    <Layout
      title="વસ્તી"
      content={
        <Grid container spacing={3} sx={{ mb: "24px", mt: 0.5 }}>
          {cardList.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={index}
              onClick={() => {
                navigate(item.to);
              }}
            >
              <StyledCard elevation={6}>
                <ContentBox>
                  <Box>
                    <H2
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        whiteSpace: "pre-wrap",
                        fontSize: "1.25rem",
                      }}
                    >
                      {item.name}
                      <Span sx={{ color: "#2d2b9b" }}>
                        &nbsp;({item.count})
                      </Span>
                    </H2>
                  </Box>
                </ContentBox>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};

export default ShakhaDetails;
