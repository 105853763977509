import * as React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  styled,
  Box,
  CircularProgress,
  Autocomplete,
  Checkbox,
  Divider,
} from "@mui/material";
import { useState } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useQueryClient, useMutation, useQuery } from "react-query";
import MenuItem from "@mui/material/MenuItem";
import { searchSwaymsevakbyShakha } from "app/service/api-service/swaymsevak-service";
import { getShakha } from "app/service/api-service/shakha-service";
import { updateSevaVasti } from "app/service/api-service/sevavasti-service";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { H5 } from "app/components/Typography";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const SearchSelect = styled(Autocomplete)(() => ({
  width: "100% !important",
}));

const EditSevaVasti = (props) => {
  const [state, setState] = useState({
    _id: props?.edit?._id,
    seva_vasti: props?.edit?.seva_vasti,
    shakha_id: props?.edit?.shakha_id?._id,
    seva_karyakarta: props?.edit?.seva_karyakarta?.map((karyakarta) => {
      return {
        _id: karyakarta.swayamsevak_id._id,
        label: karyakarta.swayamsevak_id.name,
      };
    }),
    pathdan_kendra: {
      name: props?.edit?.pathdan_kendra?.name,
      mobileno: props?.edit?.pathdan_kendra?.mobileno,
      vidhyarthi_sankhya: props?.edit?.pathdan_kendra?.vidhyarthi_sankhya,
    },
    sanskar_kendra: {
      name: props?.edit?.sanskar_kendra?.name,
      mobileno: props?.edit?.sanskar_kendra?.mobileno,
      vidhyarthi_sankhya: props?.edit?.sanskar_kendra?.vidhyarthi_sankhya,
    },
    aarogya_kendra: {
      name: props?.edit?.aarogya_kendra?.name,
      mobileno: props?.edit?.aarogya_kendra?.mobileno,
    },
    svavlamban: {
      name: props?.edit?.svavlamban?.name,
      mobileno: props?.edit?.svavlamban?.mobileno,
    },
  });
  const [shakhaList, setShakhaList] = useState();
  const [swaymsevakList, setSwaymsevakList] = useState([]);

  const queryClient = useQueryClient();

  const { isLoading: searchisLoading } = useQuery(
    ["searchSwaymsevakbyShakha", state.shakha_id],
    () => searchSwaymsevakbyShakha(state.shakha_id),
    {
      onSuccess: (data) => {
        setSwaymsevakList(data);
      },
    }
  );

  const { isLoading: shakhaLoading } = useQuery(["getShakha"], getShakha, {
    onSuccess: (data) => {
      setShakhaList(data);
    },
  });

  const UpdateSevaVastiMutation = useMutation(updateSevaVasti, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["getSevaVasti"], (vastiList) => {
        vastiList.map((vasti, index) => {
          if (vasti._id === data.sevavastiData._id) {
            vastiList[index] = data.sevavastiData;
          }
          return vasti;
        });
        return vastiList;
      });
      props.handleClose();
    },
  });

  const handleSubmit = async () => {
    UpdateSevaVastiMutation.mutate(state);
  };

  return (
    <MyDialogBox
      maxWidth="sm"
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({});
      }}
      title="સેવા વસ્તી સંપાદિત કરો"
      content={
        <ValidatorForm
          autoComplete="new-password"
          onSubmit={handleSubmit}
          onError={() => null}
        >
          <Grid container spacing={1}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <TextField
                type="text"
                name="seva_vasti"
                id="standard-basic3"
                value={state.seva_vasti || ""}
                onChange={(event) => {
                  setState({
                    ...state,
                    seva_vasti: event.target.value,
                  });
                }}
                errorMessages={["સેવા વસ્તીનું નામ દાખલ કરો"]}
                label="સેવા વસ્તીનું નામ *"
                validators={["required"]}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <SelectField
                id="standard-basic"
                name="shakha_id"
                value={(shakhaList && state.shakha_id) || ""}
                label="શાખા *"
                errorMessages={["શાખા પસંદ કરો"]}
                validators={["required"]}
                onChange={(event) => {
                  setState({
                    ...state,
                    shakha_id: event.target.value,
                    seva_karyakarta: [],
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {shakhaLoading ? (
                        <CircularProgress
                          color="inherit"
                          size={25}
                          style={{ height: "-1px" }}
                          sx={{ mr: 5 }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>શાખા પસંદ કરો</em>
                </MenuItem>
                {shakhaList?.map((shakha, index) => (
                  <MenuItem value={shakha._id} key={index}>
                    {shakha.shakha_name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SearchSelect
                multiple
                value={state.seva_karyakarta}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onChange={(event, newValue) => {
                  setState({
                    ...state,
                    seva_karyakarta: newValue.map((option) => option),
                  });
                }}
                id="checkboxes-tags-demo"
                options={swaymsevakList?.map(({ _id, name: label }) => ({
                  _id,
                  label,
                }))}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option._id}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="સેવા કાર્યકર્તા"
                    name="seva_karyakarta"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {!state.shakha_id || searchisLoading ? (
                            <CircularProgress
                              color="inherit"
                              size={25}
                              style={{ height: "-1px" }}
                              sx={{ mr: 5 }}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider sx={{ mb: 1.5 }}>
                <H5
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    border: "1px solid #ccc",
                    borderRadius: "15px",
                    p: "5px 10px",
                    fontSize: "16px",
                  }}
                >
                  પાઠદાન કેન્દ્ર
                </H5>
              </Divider>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                type="text"
                name="pathdankendra_name"
                id="standard-basic3"
                value={state.pathdan_kendra.name || ""}
                onChange={(event) => {
                  setState({
                    ...state,
                    pathdan_kendra: {
                      ...state.pathdan_kendra,
                      name: event.target.value,
                    },
                  });
                }}
                label="નામ"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: "10",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="pathdankendra_mobileno"
                id="standard-basic1"
                value={
                  (state.pathdan_kendra.mobileno &&
                    String(state.pathdan_kendra.mobileno)) ||
                  ""
                }
                onChange={(event) => {
                  event.target.value.replace(/\D/g, "");
                  event.target.value.substr(0, 10);
                  setState({
                    ...state,
                    pathdan_kendra: {
                      ...state.pathdan_kendra,
                      mobileno: event.target.validity.valid
                        ? event.target.value
                        : "",
                    },
                  });
                }}
                errorMessages={[
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                ]}
                label="મોબાઇલ નંબર"
                validators={[
                  "isNumber",
                  "minStringLength: 10",
                  "maxStringLength: 10",
                ]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="pathdankendra_vidhyarthi_sankhya"
                id="standard-basic1"
                value={state.pathdan_kendra.vidhyarthi_sankhya}
                onChange={(event) => {
                  event.target.value.replace(/\D/g, "");
                  setState({
                    ...state,
                    pathdan_kendra: {
                      ...state.pathdan_kendra,
                      vidhyarthi_sankhya: event.target.validity.valid
                        ? event.target.value
                        : "",
                    },
                  });
                }}
                errorMessages={["માન્ય સંખ્યા દાખલ કરો"]}
                label="કુલ વિદ્યાર્થીની સંખ્યા"
                validators={["isNumber"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider sx={{ mb: 1.5 }}>
                <H5
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    border: "1px solid #ccc",
                    borderRadius: "15px",
                    p: "5px 10px",
                    fontSize: "16px",
                  }}
                >
                  સંસ્કાર કેન્દ્ર
                </H5>
              </Divider>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                type="text"
                name="sanskar_kendra_name"
                id="standard-basic3"
                value={state.sanskar_kendra.name || ""}
                onChange={(event) => {
                  setState({
                    ...state,
                    sanskar_kendra: {
                      ...state.sanskar_kendra,
                      name: event.target.value,
                    },
                  });
                }}
                label="નામ"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: "10",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="sanskar_kendra_mobileno"
                id="standard-basic1"
                value={
                  (state.sanskar_kendra.mobileno &&
                    String(state.sanskar_kendra.mobileno)) ||
                  ""
                }
                onChange={(event) => {
                  event.target.value.replace(/\D/g, "");
                  event.target.value.substr(0, 10);
                  setState({
                    ...state,
                    sanskar_kendra: {
                      ...state.sanskar_kendra,
                      mobileno: event.target.validity.valid
                        ? event.target.value
                        : "",
                    },
                  });
                }}
                errorMessages={[
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                ]}
                label="મોબાઇલ નંબર"
                validators={[
                  "isNumber",
                  "minStringLength: 10",
                  "maxStringLength: 10",
                ]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="sanskar_kendra_vidhyarthi_sankhya"
                id="standard-basic1"
                value={state.sanskar_kendra.vidhyarthi_sankhya}
                onChange={(event) => {
                  event.target.value.replace(/\D/g, "");
                  setState({
                    ...state,
                    sanskar_kendra: {
                      ...state.sanskar_kendra,
                      vidhyarthi_sankhya: event.target.validity.valid
                        ? event.target.value
                        : "",
                    },
                  });
                }}
                errorMessages={["માન્ય સંખ્યા દાખલ કરો"]}
                label="કુલ વિદ્યાર્થીની સંખ્યા"
                validators={["isNumber"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider sx={{ mb: 1.5 }}>
                <H5
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    border: "1px solid #ccc",
                    borderRadius: "15px",
                    p: "5px 10px",
                    fontSize: "16px",
                  }}
                >
                  આરોગ્ય કેન્દ્ર
                </H5>
              </Divider>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <TextField
                type="text"
                name="aarogya_kendra_name"
                id="standard-basic3"
                value={state.aarogya_kendra.name || ""}
                onChange={(event) => {
                  setState({
                    ...state,
                    aarogya_kendra: {
                      ...state.aarogya_kendra,
                      name: event.target.value,
                    },
                  });
                }}
                label="નામ"
              />
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: "10",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="aarogya_kendra_mobileno"
                id="standard-basic1"
                value={
                  (state.aarogya_kendra.mobileno &&
                    String(state.aarogya_kendra.mobileno)) ||
                  ""
                }
                onChange={(event) => {
                  event.target.value.replace(/\D/g, "");
                  event.target.value.substr(0, 10);
                  setState({
                    ...state,
                    aarogya_kendra: {
                      ...state.aarogya_kendra,
                      mobileno: event.target.validity.valid
                        ? event.target.value
                        : "",
                    },
                  });
                }}
                errorMessages={[
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                ]}
                label="મોબાઇલ નંબર"
                validators={[
                  "isNumber",
                  "minStringLength: 10",
                  "maxStringLength: 10",
                ]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider sx={{ mb: 1.5 }}>
                <H5
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    border: "1px solid #ccc",
                    borderRadius: "15px",
                    p: "5px 10px",
                    fontSize: "16px",
                  }}
                >
                  સ્વાવલંબન
                </H5>
              </Divider>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <TextField
                type="text"
                name="svavlamban_name"
                id="standard-basic3"
                value={state.svavlamban.name || ""}
                onChange={(event) => {
                  setState({
                    ...state,
                    svavlamban: {
                      ...state.svavlamban,
                      name: event.target.value,
                    },
                  });
                }}
                label="નામ"
              />
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: "10",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="svavlamban_mobileno"
                id="standard-basic1"
                value={
                  (state.svavlamban.mobileno &&
                    String(state.svavlamban.mobileno)) ||
                  ""
                }
                onChange={(event) => {
                  event.target.value.replace(/\D/g, "");
                  event.target.value.substr(0, 10);
                  setState({
                    ...state,
                    svavlamban: {
                      ...state.svavlamban,
                      mobileno: event.target.validity.valid
                        ? event.target.value
                        : "",
                    },
                  });
                }}
                errorMessages={[
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                  "માન્ય મોબાઇલ નંબર દાખલ કરો",
                ]}
                label="મોબાઇલ નંબર"
                validators={[
                  "isNumber",
                  "minStringLength: 10",
                  "maxStringLength: 10",
                ]}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={UpdateSevaVastiMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default EditSevaVasti;
