import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getVarshikCalender() {
  const response = await axiosInstance.get(
    "api/varshikcalender/fetchallvarshikcalender"
  );
  return response.data;
}

export async function addVarshikCalender({ date, vishay, vigato }) {
  const response = await axiosInstance.post(
    "api/varshikcalender/addvarshikcalender",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      vishay,
      vigato,
    }
  );
  return response;
}

export async function getVarshikCalenderbyMonth({ month, year }) {
  const response = await axiosInstance.post(
    "api/varshikcalender/fetchvarshikcalenderbymonth",
    {
      month,
      year,
    }
  );
  return response;
}

export async function updateVarshikCalender({ _id: id, date, vishay, vigato }) {
  const response = await axiosInstance.put(
    `api/varshikcalender/updatevarshikcalender/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      vishay,
      vigato,
    }
  );
  return response;
}

export async function deleteVarshikCalender(id) {
  const response = await axiosInstance.delete(
    `api/varshikcalender/deletevarshikcalender/${id}`
  );
  return response;
}
