import { DropzoneArea } from "mui-file-dropzone";
import { Grid, styled } from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const MyFileInput = ({
  state,
  acceptedFiles,
  filesLimit,
  maxFileSize,
  handleFile,
  previewText,
  dropzoneText,
  initialFiles = [],
}) => {
  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  return (
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={{
        "& span": {
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          marginBottom: "15px",
        },
        "& div.MuiBox-root:nth-of-type(2)": {
          justifyContent: "center",
          "& div": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& img": {
              height: "100%",
              width: "100%",
            },
            "& button": {
              opacity: 1,
            },
          },
          "& p.MuiTypography-root": {
            marginTop: "15px",
          },
        },
        "& .DropzoneArea": {
          minHeight: "160px",
          marginBottom: "15px",
          display:
            !state?.filename && state?.filename === "" && !state?.file
              ? " block"
              : "none",
          "& .DropzoneText ": {
            fontWeight: "bold",
            fontSize: "1.2rem",
          },
        },
        "& .DropzoneArea-Error": {
          minHeight: "160px",
          marginBottom: "15px",
          backgroundColor: "#ffdada",
          display:
            !state?.filename && state?.filename === "" && !state?.file
              ? " block"
              : "none",
        },
      }}
    >
      <DropzoneArea
        initialFiles={initialFiles}
        dropzoneClass={
          !state?.fileError || state?.filename !== ""
            ? "DropzoneArea"
            : "DropzoneArea-Error"
        }
        dropzoneParagraphClass="DropzoneText"
        dropzoneText={dropzoneText}
        acceptedFiles={acceptedFiles}
        filesLimit={filesLimit}
        showPreviews={true}
        showFileNames={true}
        showFileNamesInPreview={true}
        showPreviewsInDropzone={false}
        // useChipsForPreview
        previewGridProps={{
          container: { spacing: 1, direction: "row" },
        }}
        alertSnackbarProps={{
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }}
        getFileLimitExceedMessage={(filesLimit) =>
          `${filesLimit} ફાઈલ ઉમેરી શકાય છે`
        }
        getDropRejectMessage={(acceptedFiles, maxFileSize) => {
          if (acceptedFiles.size > maxFileSize) {
            return `ફાઇલ કદ મર્યાદા ${formatBytes(maxFileSize)} છે`;
          } else {
            return `ફાઇલ પ્રકાર સમર્થિત નથી`;
          }
        }}
        previewText={previewText}
        maxFileSize={maxFileSize}
        showAlerts={["error"]}
        onChange={handleFile}
      />

      <TextField
        sx={{
          "& div": {
            display: "none !important",
          },
        }}
        name="filename"
        value={state?.filename || ""}
        errorMessages={["ફાઈલ પસંદ કરો"]}
        validators={["required"]}
      />
    </Grid>
  );
};

export default MyFileInput;
