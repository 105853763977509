import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getGanveshdinVrut() {
  const response = await axiosInstance.get(
    "api/ganveshdinvrut/fetchallganveshdinvrut"
  );
  return response.data;
}

export async function getGanveshdinVrutYears() {
  const response = await axiosInstance.get(
    "api/ganveshdinvrut/fetchganveshdinvrutyears"
  );
  return response.data;
}

export async function getGanveshdinVrutbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/ganveshdinvrut/fetchbymonthganveshdinvrut",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addGanveshdinVrut({
  date,
  sahbhagi_shakha,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/ganveshdinvrut/addganveshdinvrut",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sahbhagi_shakha,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateGanveshdinVrut({
  _id: id,
  date,
  sahbhagi_shakha,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/ganveshdinvrut/updateganveshdinvrut/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sahbhagi_shakha,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteGanveshdinVrut(id) {
  const response = await axiosInstance.delete(
    `api/ganveshdinvrut/deleteganveshdinvrut/${id}`
  );
  return response;
}
