import axiosInstance from "axios-instance";

export async function getStaff() {
  const response = await axiosInstance.get("api/staff/fetchallstaff");
  return response.data;
}

export async function addStaff({
  name,
  mobileno,
  password,
  address,
  role_id,
  nagar_id,
  upnagar_id,
  vasti_id,
  shakha_id,
  rahenak_vasti_id,
}) {
  const response = await axiosInstance.post("api/staff/addstaff", {
    name,
    mobileno,
    password,
    address,
    role_id,
    nagar_id,
    upnagar_id,
    vasti_id,
    shakha_id,
    rahenak_vasti_id,
  });
  return response;
}

export async function updateStaff({
  _id: id,
  name,
  mobileno,
  address,
  role_id,
  nagar_id,
  upnagar_id,
  vasti_id,
  shakha_id,
  rahenak_vasti_id,
}) {
  const response = await axiosInstance.put(`api/staff/updatestaff/${id}`, {
    name,
    mobileno,
    address,
    role_id,
    nagar_id,
    upnagar_id,
    vasti_id,
    shakha_id,
    rahenak_vasti_id,
  });
  return response;
}

export async function deleteStaff(id) {
  const response = await axiosInstance.delete(`api/staff/deletestaff/${id}`);
  return response;
}

export async function changePassword({ _id: id, password, cpassword }) {
  const response = await axiosInstance.put(`api/staff/changepassword/${id}`, {
    password,
    cpassword,
  });
  return response;
}
