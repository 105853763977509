import * as React from "react";
import {
  Box,
  Card,
  styled,
  Icon,
  Tooltip,
  IconButton,
  Alert,
  Grid,
} from "@mui/material";
import Layout from "../../Layout";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import AddPracharKaryoGallery from "./AddPracharKaryoGallery";
import { useConfirm } from "material-ui-confirm";
import EditPracharKaryoGallery from "./EditPracharKaryoGallery";
import { H3 } from "app/components/Typography";
import YTItem from "app/components/YTItem";
import PhotoGrid from "app/components/photogrid/PhotoGrid";
import { deleteCommunityPost } from "app/service/api-service/community-service";
import {
  deletePracharkaryogallery,
  getPracharkaryogallery,
  getBlobFile,
} from "app/service/api-service/pracharkaryogallery";

const StyledCard = styled(Card)(({ theme }) => ({
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const PracharKaryoGallery = () => {
  const { data: MainData, isLoading } = useQuery(
    ["getPracharKaryogallery"],
    getPracharkaryogallery
  );

  const confirm = useConfirm();
  const [addopen, setAddopen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();
  const DeleteCommunityPostMutation = useMutation(deleteCommunityPost);
  const DeletePracharKaryogalleryMutation = useMutation(
    deletePracharkaryogallery,
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(["getPracharKaryogallery"], (MainData) => {
          return MainData.filter((gallery) => {
            return gallery._id !== data.galleryData._id;
          });
        });
      },
    }
  );

  const handleDelete = async (id, community_post_id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ ગેલેરી કાઢી નાખવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        if (community_post_id !== "") {
          DeleteCommunityPostMutation.mutate(community_post_id);
        }
        DeletePracharKaryogalleryMutation.mutate(id);
      })
      .catch(() => {});
  };

  const handleClickOpen = (type) => {
    if (type === "edit") {
      setEditopen(true);
    } else {
      setAddopen(true);
    }
  };
  const handleClose = () => {
    setAddopen(false);
    setEditopen(false);
    setEdit(false);
  };

  const handleEditOpen = async (setval) => {
    setEditopen(true);
    const initFileObject = await Promise.all(
      setval?.files?.map(async (file) => {
        const initFileBlob = await getBlobFile(
          process.env.REACT_APP_API_URL +
            "pracharkaryo_gallery/" +
            file?.filename
        );
        const fileobj = new File([initFileBlob], file?.filename, {
          type: file?.filetype,
        });
        return fileobj;
      })
    );
    if (setval?.youtube_link !== "") {
      setEdit({
        ...setval,
        initFile: initFileObject,
        media_type: "YouTube લીંક",
      });
    } else if (setval?.files.length > 0) {
      setEdit({ ...setval, initFile: initFileObject, media_type: "IMAGES" });
    } else {
      setEdit({ ...setval, initFile: initFileObject, media_type: "" });
    }
  };

  return (
    <Layout
      isLoading={isLoading || false}
      title="પ્રચાર કાર્યો ગેલેરી"
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              pb: 2,
              borderBottom: "2px dotted #2c3c6e",
            }}
          >
            <LoadingButton
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              style={{
                minHeight: 40,
                margin: "10px 0",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">add</Icon> &nbsp;ગેલેરી ઉમેરો
            </LoadingButton>
          </Box>
          <Grid container spacing={1.5} sx={{ mb: "24px", mt: 0.5 }}>
            {MainData?.map((val, index) => (
              <Grid
                sx={{ mt: 1 }}
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                key={index}
              >
                <StyledCard elevation={6} sx={{ borderRadius: "8px" }}>
                  {val?.files?.length > 0 && (
                    <PhotoGrid
                      images={val?.files?.map(
                        (file) =>
                          `${process.env.REACT_APP_API_URL}pracharkaryo_gallery/${file.filename}`
                      )}
                    />
                  )}
                  {val?.youtube_link && <YTItem embed_url={val?.embed_url} />}
                  <ContentBox>
                    <H3
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        borderBottom: "1px dashed #2c3c6e",
                        borderTop: "1px solid #2c3c6e",
                        width: "100%",
                        padding: "10px 5px 10px 5px",
                      }}
                    >
                      {val?.vishay}
                    </H3>
                    <Box
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        padding: "5px",
                      }}
                    >
                      <Tooltip title="સંપાદિત કરો" placement="bottom">
                        <IconButton
                          onClick={() => {
                            handleEditOpen(val);
                          }}
                        >
                          <Icon color="primary">edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="કાઢી નાખો" placement="bottom">
                        <IconButton
                          onClick={() => {
                            handleDelete(val._id, val?.community_post_id?._id);
                          }}
                        >
                          <Icon color="error">delete</Icon>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ContentBox>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
          {MainData?.length < 1 && (
            <Alert severity="info">
              <b>કોઈ ડેટા મળ્યો નથી</b>
            </Alert>
          )}
          {addopen && (
            <AddPracharKaryoGallery handleClose={handleClose} open={addopen} />
          )}
          {edit && (
            <EditPracharKaryoGallery
              handleClose={handleClose}
              open={editopen}
              edit={edit}
            />
          )}
        </>
      }
    />
  );
};

export default PracharKaryoGallery;
