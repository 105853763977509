import { Box, Card, Grid, styled } from "@mui/material";
import { H3 } from "app/components/Typography";
import { useLocation } from "react-router-dom";
import Layout from "../Layout";
import { useQuery } from "react-query";
import { getSwaymsevakbyShakha } from "app/service/api-service/swaymsevak-service";
import { useState } from "react";
import SwaymsevakDetail from "../SwaymsevakDetail";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const ShakhaToli = () => {
  const { state } = useLocation();
  const { shakha } = state;
  const { data: swaymsevakList, isLoading } = useQuery(
    ["getSwaymsevakbyShakha", shakha._id],
    () => getSwaymsevakbyShakha(shakha._id)
  );

  const [detailopen, setDetailOpen] = useState(false);
  const [swaymsevakData, setSwaymsevakData] = useState(false);

  const handleClickOpen = (type) => {
    setDetailOpen(true);
  };
  const handleClose = () => {
    setDetailOpen(false);
    setSwaymsevakData(false);
  };

  return (
    <>
      <Layout
        isLoading={isLoading || false}
        title="શાખા ટોળી"
        content={
          <Grid container spacing={0} sx={{ mb: "24px", mt: 0.5 }}>
            {swaymsevakList
              ?.filter(
                ({ shakhatoli_role }) =>
                  shakhatoli_role && shakhatoli_role !== ""
              )
              .map((swaymsevak, index) => (
                <Grid container spacing={0} key={index}>
                  <Grid
                    sx={{ mt: 3 }}
                    item
                    lg={1.5}
                    xs={2.8}
                    onClick={() => {
                      setSwaymsevakData(swaymsevak);
                      handleClickOpen();
                    }}
                  >
                    <StyledCard
                      elevation={6}
                      sx={{ borderRadius: "8px 0 0 8px", height: "100%" }}
                    >
                      <ContentBox>
                        <Box>
                          <H3 sx={{ fontWeight: "bold", textAlign: "center" }}>
                            &nbsp;({index + 1})&nbsp;
                          </H3>
                        </Box>
                      </ContentBox>
                    </StyledCard>
                  </Grid>
                  <Grid
                    sx={{ mt: 3 }}
                    item
                    lg={10.5}
                    xs={9.2}
                    onClick={() => {
                      setSwaymsevakData(swaymsevak);
                      handleClickOpen();
                    }}
                  >
                    <StyledCard
                      elevation={6}
                      sx={{ borderRadius: "0 8px 8px 0" }}
                    >
                      <ContentBox>
                        <Box>
                          <H3 sx={{ fontWeight: "bold", textAlign: "center" }}>
                            {swaymsevak.name}
                          </H3>
                          <H3
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                              color: "#3b3ea1",
                            }}
                          >
                            ({swaymsevak.shakhatoli_role})
                          </H3>
                        </Box>
                      </ContentBox>
                    </StyledCard>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        }
      />
      {swaymsevakData && (
        <SwaymsevakDetail
          handleClose={handleClose}
          open={detailopen}
          swaymsevakdata={swaymsevakData}
        />
      )}
    </>
  );
};

export default ShakhaToli;
