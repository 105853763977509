import * as React from "react";
import { styled } from "@mui/material";
import { useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const MyDatePicker = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
      <DatePicker
        {...props}
        closeOnSelect={true}
        sx={{ width: "100%", marginBottom: "16px" }}
        format="DD-MM-YYYY"
        views={["year", "month", "day"]}
        defaultValue={dayjs() || ""}
        slots={{
          textField: TextField,
        }}
        slotProps={{
          textField: {
            name: "date",
            errorMessages: ["તારીખ દાખલ કરો", "તારીખ દાખલ કરો"],
            validators: ["required", "isValidDate"],
            onClick: (e) => setOpen(!open),
          },
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      />
    </LocalizationProvider>
  );
};

export default MyDatePicker;
