import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getUpnagarEkatrikaran() {
  const response = await axiosInstance.get(
    "api/upnagarekatrikaran/fetchallupnagarekatrikaran"
  );
  return response.data;
}

export async function getUpnagarEkatrikaranYears() {
  const response = await axiosInstance.get(
    "api/upnagarekatrikaran/fetchupnagarekatrikaranyears"
  );
  return response.data;
}

export async function getUpnagarEkatrikaranbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/upnagarekatrikaran/fetchbymonthupnagarekatrikaran",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addUpnagarEkatrikaran({
  upnagar_id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/upnagarekatrikaran/addupnagarekatrikaran",
    {
      upnagar_id,
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateUpnagarEkatrikaran({
  _id: id,
  upnagar_id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/upnagarekatrikaran/updateupnagarekatrikaran/${id}`,
    {
      upnagar_id,
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteUpnagarEkatrikaran(id) {
  const response = await axiosInstance.delete(
    `api/upnagarekatrikaran/deleteupnagarekatrikaran/${id}`
  );
  return response;
}
