import * as React from "react";
import {
  Box,
  Card,
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  tabsClasses,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  CircularProgress,
  Grid,
  Icon,
  Alert,
  Tooltip,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Layout from "../../Layout";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  deleteVastiEkatriKaran,
  getVastiEkatriKaranYears,
  getVastiEkatriKaranbyMonth,
} from "app/service/api-service/vastiekatrikaran-service";
import {
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";
import AddVastiEkatriKaran from "./AddVastiEkatriKaran";
import EditVastiEkatriKaran from "./EditVastiEkatriKaran";
import dayjs from "dayjs";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  padding: "24px !important",
  background: theme.palette.background.paper,
  boxShadow: "none !important",
  [theme.breakpoints.down("sm")]: { padding: "5px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  flexGrow: 1,
  maxWidth: "100%",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    fontSize: "25px",
    color: "#34314c",
  },
}));
const MyTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: "1px solid #ccc",
  "& .MuiTabs-scroller": {
    width: "100px",
  },
}));
const StyledTable = styled(Table)(({ theme }) => ({
  "& tbody": {
    "& tr": {
      "& td": { paddingLeft: 0, textTransform: "none", fontSize: "17px" },
      "& td:nth-of-type(3)": {
        fontWeight: "600",
      },
    },
  },
}));
const SelectField = styled(SelectValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const VastiEkatriKaran = () => {
  let today = new Date();
  const confirm = useConfirm();
  const [addopen, setAddopen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();
  const DeleteVastiEkatriKaranMutation = useMutation(deleteVastiEkatriKaran, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["getVastiEkatriKaranbyMonth", value],
        (vastiekatrikaranList) => {
          return vastiekatrikaranList.filter((vastiekatrikaran) => {
            return vastiekatrikaran._id !== data.vastiekatrikaran._id;
          });
        }
      );
    },
  });

  const handleDelete = async (id) => {
    confirm({
      title: "ખાતરી કરો",
      description: "શું તમે ખરેખર આ વસ્તી એકત્રીકરણ કાઢી નાખવા માંગો છો ?",
      confirmationText: "કાઢી નાખો",
      cancellationText: "રદ કરો",
    })
      .then(() => {
        DeleteVastiEkatriKaranMutation.mutate(id);
      })
      .catch(() => {});
  };
  const [value, setValue] = React.useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  });

  const { data: MainData, isLoading } = useQuery(
    ["getVastiEkatriKaranbyMonth", value],
    () => getVastiEkatriKaranbyMonth(value)
  );

  const { data: YearList, isLoading: YearLoading } = useQuery(
    ["getVastiEkatriKaranYears", value],
    getVastiEkatriKaranYears
  );

  const handleChange = (event, newValue) => {
    setValue({ ...value, month: newValue });
  };

  const handleChangeYear = (event, newValue) => {
    setValue({ ...value, year: newValue });
  };

  const handleClickOpen = (type) => {
    if (type === "edit") {
      setEditopen(true);
    } else {
      setAddopen(true);
    }
  };
  const handleClose = () => {
    setAddopen(false);
    setEditopen(false);
    setEdit(false);
  };

  function getMonthName(monthNumber) {
    const monthArray = [
      "જાન્યુઆરી",
      "ફેબ્રુઆરી",
      "માર્ચ",
      "એપ્રિલ",
      "મે",
      "જૂન",
      "જુલાઈ",
      "ઑગસ્ટ",
      "સપ્ટેમ્બર",
      "ઑક્ટોબર",
      "નવેમ્બર",
      "ડિસેમ્બર",
    ];
    return monthArray[monthNumber];
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  return (
    <Layout
      isLoading={isLoading || false}
      title="વસ્તી એકત્રીકરણ"
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              pb: 2,
              borderBottom: "2px dotted #2c3c6e",
            }}
          >
            <LoadingButton
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              style={{
                minHeight: 40,
                margin: "10px 0",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">add</Icon> &nbsp;ઉમેરો
            </LoadingButton>
          </Box>
          <Grid
            container
            spacing={1}
            sx={{ justifyContent: "center", mt: 2, mb: 1 }}
          >
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <ValidatorForm
                autoComplete="new-password"
                onSubmit={() => {}}
                onError={() => null}
              >
                <SelectField
                  id="standard-basic"
                  name="year"
                  value={(YearList && value?.year) || ""}
                  label="વર્ષ પસંદ કરો"
                  onChange={(event) => {
                    handleChangeYear(event, event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        {YearLoading ? (
                          <CircularProgress
                            color="inherit"
                            size={25}
                            style={{ height: "-1px" }}
                            sx={{ mr: 5 }}
                          />
                        ) : null}
                      </React.Fragment>
                    ),
                  }}
                >
                  {YearList?.map((year, index) => (
                    <MenuItem value={year} key={index}>
                      {year}
                    </MenuItem>
                  ))}
                  {YearList?.length > 0 &&
                    !YearList?.includes(new Date().getFullYear()) && (
                      <MenuItem value={new Date().getFullYear()} key={-1}>
                        {new Date().getFullYear()}
                      </MenuItem>
                    )}
                  {YearList?.length < 1 && (
                    <MenuItem value={new Date().getFullYear()} key={0}>
                      {new Date().getFullYear()}
                    </MenuItem>
                  )}
                </SelectField>
              </ValidatorForm>
            </Grid>
          </Grid>
          <StyledCard>
            <ContentBox>
              <MyTabs
                value={value.month}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="Month List"
                sx={{
                  mb: 3,
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                {[...Array(12)].map((x, i) => (
                  <Tab
                    key={i}
                    label={getMonthName(i)}
                    {...a11yProps(i)}
                    sx={{ fontSize: "17px", fontWeight: "bold" }}
                  />
                ))}
              </MyTabs>
              {MainData?.map((val, index) => (
                <Accordion key={index} sx={{ border: "1px solid #ccc", mb: 2 }}>
                  <AccordionSummary
                    id="panel1a-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    sx={{
                      borderBottom: "1px solid #ccc",
                      ".MuiAccordionSummary-content": {
                        justifyContent: "center",
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Typography
                      component={"span"}
                      className="heading"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        whiteSpace: " pre-wrap",
                        textAlign: "center",
                        color: "#2c469e",
                      }}
                    >
                      {new Date(val.date).toLocaleString("guj-IN", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </Typography>
                    <Typography
                      component={"span"}
                      className="heading"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        whiteSpace: " pre-wrap",
                        textAlign: "center",
                        lineHeight: 2.1,
                      }}
                    >
                      {val.sthan}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "center",
                      mt: 1,
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#787272",
                        letterSpacing: "0.5px",
                      }}
                    >
                      <StyledTable sx={{ mt: "-15px" }}>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" width="30%">
                              તારીખ
                            </TableCell>
                            <TableCell align="left" width="4%">
                              :
                            </TableCell>
                            <TableCell align="left">
                              {new Date(val.date).toLocaleString("guj-IN", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" width="30%">
                              સ્થાન
                            </TableCell>
                            <TableCell align="left" width="4%">
                              :
                            </TableCell>
                            <TableCell align="left">{val.sthan}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" width="30%">
                              ઉપસ્થિતિ
                            </TableCell>
                            <TableCell align="left" width="4%">
                              :
                            </TableCell>
                            <TableCell align="left">{val.upsthiti}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" width="30%">
                              બાલ
                            </TableCell>
                            <TableCell align="left" width="4%">
                              :
                            </TableCell>
                            <TableCell align="left">{val.bal}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" width="30%">
                              તરુણ
                            </TableCell>
                            <TableCell align="left" width="4%">
                              :
                            </TableCell>
                            <TableCell align="left">{val.tarun}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" width="30%">
                              યોગ
                            </TableCell>
                            <TableCell align="left" width="4%">
                              :
                            </TableCell>
                            <TableCell align="left">{val.yog}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" width="30%">
                              ક્રિયાઓ
                            </TableCell>
                            <TableCell align="left" width="4%">
                              :
                            </TableCell>
                            <TableCell align="left">
                              <Tooltip title="સંપાદિત કરો" placement="bottom">
                                <IconButton
                                  onClick={() => {
                                    setEdit({
                                      ...val,
                                      date: dayjs(val.date),
                                    });
                                    handleClickOpen("edit");
                                  }}
                                >
                                  <Icon color="primary">edit</Icon>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="કાઢી નાખો" placement="bottom">
                                <IconButton
                                  onClick={() => {
                                    handleDelete(val._id);
                                  }}
                                >
                                  <Icon color="error">delete</Icon>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </StyledTable>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
              {MainData?.length < 1 && (
                <Alert severity="info">
                  <b>કોઈ ડેટા મળ્યો નથી</b>
                </Alert>
              )}
            </ContentBox>
          </StyledCard>
          {addopen && (
            <AddVastiEkatriKaran
              handleClose={handleClose}
              open={addopen}
              value={value}
              setValue={setValue}
            />
          )}
          {edit && (
            <EditVastiEkatriKaran
              handleClose={handleClose}
              open={editopen}
              edit={edit}
              value={value}
              setValue={setValue}
            />
          )}
        </>
      }
    />
  );
};

export default VastiEkatriKaran;
