import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getShrenivrut() {
  const response = await axiosInstance.get("api/shrenivrut/fetchallshrenivrut");
  return response.data;
}

export async function getShrenivrutYears() {
  const response = await axiosInstance.get(
    "api/shrenivrut/fetchshrenivrutyears"
  );
  return response.data;
}

export async function getShrenivrutbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/shrenivrut/fetchbymonthshrenivrut",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addShrenivrut({ date, sthan, upsthiti, vishay, vakta }) {
  const response = await axiosInstance.post("api/shrenivrut/addshrenivrut", {
    date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
    sthan,
    upsthiti,
    vishay,
    vakta,
  });
  return response;
}

export async function updateShrenivrut({
  _id: id,
  date,
  sthan,
  upsthiti,
  vishay,
  vakta,
}) {
  const response = await axiosInstance.put(
    `api/shrenivrut/updateshrenivrut/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      vishay,
      vakta,
    }
  );
  return response;
}

export async function deleteShrenivrut(id) {
  const response = await axiosInstance.delete(
    `api/shrenivrut/deleteshrenivrut/${id}`
  );
  return response;
}
