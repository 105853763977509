import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Upsthiti = Loadable(lazy(() => import("./Upsthiti")));

const UpsthitiRoutes = [
  {
    path: "/upsthiti/addpatrak",
    element: <Upsthiti />,
    auth: ["શાખા સ્તર"],
  },
];

export default UpsthitiRoutes;
