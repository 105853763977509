import { Box, Icon, Typography } from "@mui/material";
import { H2 } from "app/components/Typography";
import Layout from "../Layout";
import MyReportTable from "app/components/MyReportTable";
import { useQuery } from "react-query";
import { getUpnagarwithShakha } from "app/service/api-service/upnagar-service";
import { pdf } from "@react-pdf/renderer";
import ShakhachitraPDF from "./ShakhachitraPDF";
import { LoadingButton } from "@mui/lab";
import { MyCard } from "app/components";
import { useState } from "react";
import { saveAs } from "file-saver";

const ShakhachitraReport = () => {
  const { data: shakhas, isLoading: shakhaLoading } = useQuery(
    ["getUpnagarwithShakha"],
    getUpnagarwithShakha
  );
  const [PDFLoading, setPDFLoading] = useState(false);

  const columns = [
    { name: "ક્રમાંક", options: { filterOptions: { fullWidth: true } } },
    "શાખા",
    "શાખાનો સમય",
    "શાખાનું સ્થાન",
    "શાખા કાર્યવાહ",
    "મુખ્ય શિક્ષક",
  ];

  const DownloadPDF = async () => {
    setPDFLoading(true);
    const doc = <ShakhachitraPDF shakhas={shakhas} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "શાખા ચિત્ર રિપોર્ટ.pdf");
    setPDFLoading(false);
  };

  let count = 1;
  const non_saptahik_shakha_data = shakhas?.map(
    (upnagar, index) =>
      upnagar?.shakhas?.filter(({ is_saptahik }) => is_saptahik === 0).length >
        0 && (
        <MyReportTable
          key={index + 1}
          title={
            <Typography gutterBottom variant="h5" component="div">
              {upnagar.upnagar_name}
            </Typography>
          }
          data={upnagar?.shakhas
            ?.filter(({ is_saptahik }) => is_saptahik === 0)
            .map((shakha, index1) => [
              count++,
              shakha.shakha_name,
              shakha.samay,
              shakha.sthan,
              shakha?.shakha_user[0]?.name +
                "\n (" +
                shakha?.shakha_user[0]?.mobileno +
                ")",
              shakha?.shakha_user1[0]?.name +
                "\n (" +
                shakha?.shakha_user1[0]?.mobileno +
                ")",
            ])}
          columns={columns}
        />
      )
  );

  let count1 = 1;
  const saptahik_shakha_data = shakhas?.map(
    (upnagar, index) =>
      upnagar?.shakhas?.filter(({ is_saptahik }) => is_saptahik === 1).length >
        0 && (
        <MyReportTable
          key={index + 100}
          title={
            <Typography gutterBottom variant="h5" component="div">
              {upnagar.upnagar_name}
            </Typography>
          }
          data={upnagar?.shakhas
            ?.filter(({ is_saptahik }) => is_saptahik === 1)
            .map((shakha, index1) => [
              count1++,
              shakha.shakha_name,
              shakha.samay,
              shakha.sthan,
              shakha?.shakha_user[0]?.name +
                "\n (" +
                shakha?.shakha_user[0]?.mobileno +
                ")",
              shakha?.shakha_user1[0]?.name +
                "\n (" +
                shakha?.shakha_user1[0]?.mobileno +
                ")",
            ])}
          columns={columns}
        />
      )
  );

  return (
    <Layout
      title="શાખા ચિત્ર રિપોર્ટ"
      content={
        <MyCard
          title={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                mt: 2,
              }}
            >
              <LoadingButton
                onClick={DownloadPDF}
                variant="contained"
                loading={PDFLoading || shakhaLoading}
                color="primary"
                style={{
                  minHeight: 36,
                  margin: "10px 0 10px 10px",
                  fontWeight: "bold",
                }}
              >
                <Icon className="icon">picture_as_pdf</Icon> &nbsp;ડાઉનલોડ
                રિપોર્ટ
              </LoadingButton>
            </Box>
          }
        >
          <H2 sx={{ textAlign: "center", fontWeight: "bold", mb: 2, mt: 2 }}>
            શાખા
          </H2>
          <div>{non_saptahik_shakha_data}</div>
          <H2 sx={{ textAlign: "center", fontWeight: "bold", mb: 3, mt: 4 }}>
            સાપ્તાહિક મિલન
          </H2>
          <div>{saptahik_shakha_data}</div>
        </MyCard>
      }
    />
  );
};

export default ShakhachitraReport;
