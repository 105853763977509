import { combineReducers } from "redux";
import EcommerceReducer from "./EcommerceReducer";
import NavigationReducer from "./NavigationReducer";
import NotificationReducer from "./NotificationReducer";
import SnackbarReducer from "./SnackbarReducer";

const RootReducer = combineReducers({
  notifications: NotificationReducer,
  navigations: NavigationReducer,
  ecommerce: EcommerceReducer,
  snackbar: SnackbarReducer,
});

export default RootReducer;
