import axiosInstance from "axios-instance";

export async function getBlobFile(url) {
  const response = await axiosInstance.get(url, {
    responseType: "blob",
  });
  return response.data;
}

export async function getSevakaryogallery() {
  const response = await axiosInstance.get(
    "api/sevakaryogallery/fetchallsevakaryogallery"
  );
  return response.data;
}

export async function addSevakaryogallery(formData) {
  const response = await axiosInstance.post(
    "api/sevakaryogallery/addsevakaryogallery",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=XXX",
      },
    }
  );
  return response;
}

export async function updateSevakaryogallery(formData) {
  const response = await axiosInstance.put(
    "api/sevakaryogallery/updatesevakaryogallery",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=XXX",
      },
    }
  );
  return response;
}

export async function deleteSevakaryogallery(id) {
  const response = await axiosInstance.delete(
    `api/sevakaryogallery/deletesevakaryogallery/${id}`
  );
  return response;
}
