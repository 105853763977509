import Loadable from "app/components/Loadable";
import { lazy } from "react";

const DownloadCenter = Loadable(lazy(() => import("./DownloadCenter")));

const DownloadCenterRoutes = [
  {
    path: "/download_center/alldownloads",
    element: <DownloadCenter />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર", "શાખા સ્તર"],
  },
];

export default DownloadCenterRoutes;
