import * as React from "react";
import Layout from "../../../Layout";
import { useQuery } from "react-query";
import { useState } from "react";
import AddUpnagarSamiti from "./AddUpnagarSamiti";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import UpnagarSamitiPDF from "./UpnagarSamitiPDF";
import { getUpnagar } from "app/service/api-service/upnagar-service";
import { getUpnagarSamitibyGroup } from "app/service/api-service/upnagarsamiti-service";
import { Alert, Box, Card, Grid, Icon, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { H2 } from "app/components/Typography";
import { LoadingButton } from "@mui/lab";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const UpnagarSamiti = () => {
  const [addopen, setAddopen] = useState(false);
  const [PDFLoading, setPDFLoading] = useState(false);
  const navigate = useNavigate();

  const { data: upnagarList, isLoading } = useQuery(["getUpnagar"], getUpnagar);
  const { data: upnagarsamitiList, isLoading: samitiIsLoading } = useQuery(
    ["getUpnagarSamitibyGroup"],
    getUpnagarSamitibyGroup
  );

  const handleClickOpen = (type) => {
    setAddopen(true);
  };
  const handleClose = () => {
    setAddopen(false);
  };
  const DownloadPDF = async () => {
    setPDFLoading(true);
    const doc = <UpnagarSamitiPDF upnagarsamitiList={upnagarsamitiList} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "ઉપનગર સમિતિ.pdf");
    setPDFLoading(false);
  };

  return (
    <Layout
      isLoading={isLoading || false}
      title="ઉપનગર સમિતિ"
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              pb: 2,
              borderBottom: "2px dotted #2c3c6e",
            }}
          >
            <LoadingButton
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              style={{
                minHeight: 40,
                margin: "10px 0",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">add</Icon> &nbsp;ઉમેરો
            </LoadingButton>
            <LoadingButton
              onClick={DownloadPDF}
              variant="contained"
              loading={samitiIsLoading || isLoading || PDFLoading}
              color="primary"
              style={{
                minHeight: 36,
                margin: "10px 0 10px 10px",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">picture_as_pdf</Icon> &nbsp;ડાઉનલોડ રિપોર્ટ
            </LoadingButton>
          </Box>
          <Grid container spacing={0} sx={{ mb: "24px", mt: 0.5 }}>
            {upnagarList?.map((upnagar, index) => (
              <Grid container spacing={0} key={index}>
                <Grid
                  sx={{ mt: 3 }}
                  item
                  lg={1.5}
                  xs={2.8}
                  onClick={() =>
                    navigate("/dashboard/ram_madir_sampark/samiti/view", {
                      state: { upnagar: upnagar },
                    })
                  }
                >
                  <StyledCard
                    elevation={6}
                    sx={{ borderRadius: "8px 0 0 8px", height: "100%" }}
                  >
                    <ContentBox>
                      <Box>
                        <H2
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            whiteSpace: "pre-wrap",
                            fontSize: "1.25rem",
                          }}
                        >
                          &nbsp;({index + 1})&nbsp;
                        </H2>
                      </Box>
                    </ContentBox>
                  </StyledCard>
                </Grid>
                <Grid
                  sx={{ mt: 3 }}
                  item
                  lg={10.5}
                  xs={9.2}
                  onClick={() =>
                    navigate(
                      "/dashboard/ram_madir_sampark/samiti/upnagar/view",
                      {
                        state: { upnagar: upnagar },
                      }
                    )
                  }
                >
                  <StyledCard
                    elevation={6}
                    sx={{ borderRadius: "0 8px 8px 0" }}
                  >
                    <ContentBox>
                      <Box>
                        <H2
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            whiteSpace: "pre-wrap",
                            fontSize: "1.25rem",
                          }}
                        >
                          {upnagar.upnagar_name}
                        </H2>
                      </Box>
                    </ContentBox>
                  </StyledCard>
                </Grid>
              </Grid>
            ))}
            {upnagarList?.length < 1 && (
              <Alert severity="info" sx={{ mt: 2, width: "100%" }}>
                <b>કોઈ ડેટા મળ્યો નથી</b>
              </Alert>
            )}
          </Grid>
          {addopen && (
            <AddUpnagarSamiti handleClose={handleClose} open={addopen} />
          )}
        </>
      }
    />
  );
};

export default UpnagarSamiti;
