import React from "react";

const YTItem = ({ embed_url }) => {
  return (
    <iframe
      loading="lazy"
      style={{
        borderWidth: 0,
      }}
      title="Youtube Video"
      id="videoObject"
      src={embed_url}
      type="text/html"
      width="100%"
      height="265"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  );
};
export default YTItem;
