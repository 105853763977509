import * as React from "react";
import { Box, Card, styled, Tabs, tabsClasses, Tab } from "@mui/material";
import { H3 } from "app/components/Typography";
import Layout from "../Layout";
import { useMutation } from "react-query";
import { getAshtabindubyMonth } from "app/service/api-service/ashtabindu-service";
import { useLocation } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  padding: "24px !important",
  background: theme.palette.background.paper,
  boxShadow: "none !important",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  flexGrow: 1,
  maxWidth: "100%",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    fontSize: "25px",
    color: "#34314c",
  },
}));
const MyTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: "1px solid #ccc",
  "& .MuiTabs-scroller": {
    width: "100px",
  },
}));
const Itembox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "15px",
  paddingBottom: "10px",
  borderBottom: "1px dashed #d5d4d4",
  ":last-child": {
    borderBottom: "0px",
  },
}));
const Title = styled(H3)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "bold",
  textAlign: "center",
  borderBottom: "2px dashed #414c76",
  marginBottom: "15px",
}));
const Content = styled(H3)(({ theme }) => ({
  lineHeight: 1.8,
  fontSize: "20px",
  textAlign: "center",
  color: theme.palette.primary.main,
}));

const AshtabinduPatrak = () => {
  let today = new Date();
  const { state } = useLocation();
  const { shakha } = state;
  const [value, setValue] = React.useState({
    month: today.getMonth(),
    year: today.getFullYear(),
    shakha_id: shakha._id,
  });

  const [data, setData] = React.useState({
    git: 0,
    git_all_records: 0,
    subhashit: 0,
    amrutvachan: 0,
    bodhkatha: 0,
    charcha: 0,
    samachar_samiksha: 0,
    baudhik: 0,
    dirgh_varta: 0,
    total_bodhkatha: 4,
  });

  const getAshtabindubyMonthMutation = useMutation(getAshtabindubyMonth, {
    onSuccess: ({ data: serverdata }) => {
      let main_data = serverdata[0];
      setData({
        ...data,
        git:
          typeof main_data["git"] !== "undefined" && main_data["git"]
            ? main_data["git"]
            : 0,
        git_all_records:
          typeof main_data["all_records"] !== "undefined" &&
          main_data["all_records"]
            ? main_data["all_records"]
            : 0,
        subhashit:
          typeof main_data["subhashit"] !== "undefined" &&
          main_data["subhashit"]
            ? main_data["subhashit"]
            : 0,
        amrutvachan:
          typeof main_data["amrutvachan"] !== "undefined" &&
          main_data["amrutvachan"]
            ? main_data["amrutvachan"]
            : 0,
        bodhkatha:
          typeof main_data["bodhkatha"] !== "undefined" &&
          main_data["bodhkatha"]
            ? main_data["bodhkatha"]
            : 0,
        charcha:
          typeof main_data["charcha"] !== "undefined" && main_data["charcha"]
            ? main_data["charcha"]
            : 0,
        samachar_samiksha:
          typeof main_data["samachar_samiksha"] !== "undefined" &&
          main_data["samachar_samiksha"]
            ? main_data["samachar_samiksha"]
            : 0,
        baudhik:
          typeof main_data["baudhik"] !== "undefined" && main_data["baudhik"]
            ? main_data["baudhik"]
            : 0,
        dirgh_varta:
          typeof main_data["dirgh_varta"] !== "undefined" &&
          main_data["dirgh_varta"]
            ? main_data["dirgh_varta"]
            : 0,
      });
    },
  });

  React.useEffect(() => {
    getAshtabindubyMonthMutation.mutate(value);
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setValue({ ...value, month: newValue });
    getAshtabindubyMonthMutation.mutate({
      month: newValue,
      year: value.year,
      shakha_id: shakha._id,
    });
  };

  function getMonthName(monthNumber) {
    const monthArray = [
      "જાન્યુઆરી",
      "ફેબ્રુઆરી",
      "માર્ચ",
      "એપ્રિલ",
      "મે",
      "જૂન",
      "જુલાઈ",
      "ઑગસ્ટ",
      "સપ્ટેમ્બર",
      "ઑક્ટોબર",
      "નવેમ્બર",
      "ડિસેમ્બર",
    ];
    return monthArray[monthNumber];
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  return (
    <Layout
      isLoading={getAshtabindubyMonthMutation.isLoading || false}
      title="અષ્ટબિંદુ વૃત પત્રક"
      content={
        <StyledCard>
          <ContentBox>
            <MyTabs
              value={value.month}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="Month List"
              sx={{
                mb: 3,
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              {[...Array(12)].map((x, i) => (
                <Tab
                  key={i}
                  label={getMonthName(i)}
                  {...a11yProps(i)}
                  sx={{ fontSize: "17px", fontWeight: "bold" }}
                />
              ))}
            </MyTabs>

            <Itembox>
              <Title>ગીત</Title>
              <Content>{data?.git + " / " + data?.git_all_records}</Content>
            </Itembox>
            <Itembox>
              <Title>સુભાષિત</Title>
              <Content>
                {data?.subhashit +
                  " / " +
                  Math.round(
                    new Date(value.year, value.month + 1, 0).getDate() / 2
                  )}
              </Content>
            </Itembox>
            <Itembox>
              <Title>અમૃત વચન</Title>
              <Content>
                {data?.amrutvachan +
                  " / " +
                  (new Date(value.year, value.month + 1, 0).getDate() -
                    Math.round(
                      new Date(value.year, value.month + 1, 0).getDate() / 2
                    ))}
              </Content>
            </Itembox>
            <Itembox>
              <Title>બોધ કથા</Title>
              <Content>
                {data?.bodhkatha + " / " + data?.total_bodhkatha}
              </Content>
            </Itembox>
            <Itembox>
              <Title>ચર્ચા</Title>
              <Content>{data?.charcha + " / 1"}</Content>
            </Itembox>
            <Itembox>
              <Title>સમાચાર સમીક્ષા</Title>
              <Content>{data?.samachar_samiksha + " / 1"}</Content>
            </Itembox>
            <Itembox>
              <Title>બૌદ્ધિક</Title>
              <Content>{data?.baudhik + " / 1"}</Content>
            </Itembox>
            <Itembox>
              <Title>દીર્ઘ વાર્તા</Title>
              <Content>{data?.dirgh_varta + " / 1"}</Content>
            </Itembox>
          </ContentBox>
        </StyledCard>
      }
    />
  );
};

export default AshtabinduPatrak;
