import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import GujaratiFont from "../../../../../../../pdf_fonts/MuktaVaani-Regular.ttf";
import BoldGujaratiFont from "../../../../../../../pdf_fonts/MuktaVaani-Bold.ttf";

Font.register({
  family: "GujaratiFont",
  src: GujaratiFont,
});
Font.register({
  family: "GujaratiFont",
  src: BoldGujaratiFont,
});
const styles = StyleSheet.create({
  page: {
    fontFamily: "GujaratiFont",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  header: {
    fontWeight: "bold",
    fontSize: "25px",
    textAlign: "center",
    margin: "0 0 10px 0",
  },
  table: {
    display: "table",
    width: "100%",
    flexDirection: "column",
  },
  tableRow: {
    flexGrow: 1,
    height: "auto",
    flexDirection: "row",
  },
  tableHeaderCol: {
    borderWidth: 2,
    borderTop: "2px solid black",
    padding: "5px",
  },
  tableCol: {
    borderWidth: 2,
    borderStyle: "solid",
    padding: "5px",
  },
  firstCell: {
    borderLeftWidth: 2,
  },
  tableColCell: {
    flexGrow: 1,
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    flexGrow: 1,
    marginTop: 5,
    marginBottom: 5,
    fontSize: "14px",
    textAlign: "center",
  },
});

const VastiSamitiPDF = ({ vastisamitiList }) => {
  function listSamitis() {
    let i = 0;
    const iteratedVruts = [];
    Object.entries(vastisamitiList).forEach(([key, vruts]) => {
      iteratedVruts.push(
        <View style={styles.table} key={i + 1}>
          <View
            style={[
              styles.tableRow,
              {
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                padding: "10px 0px",
                width: "100%",
                border: "2px solid black",
              },
            ]}
            wrap={false}
          >
            <Text
              style={{
                fontSize: "18px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {key}
            </Text>
          </View>
          <View style={styles.tableRow} wrap={false}>
            <View
              style={[styles.firstCell, styles.tableHeaderCol, { width: "9%" }]}
            >
              <Text style={styles.tableColCell}>ક્રમાંક</Text>
            </View>
            <View style={[styles.tableHeaderCol, { width: "28%" }]}>
              <Text style={styles.tableColCell}>નામ</Text>
            </View>
            <View style={[styles.tableHeaderCol, { width: "18%" }]}>
              <Text style={styles.tableColCell}>મોબાઈલ નંબર</Text>
            </View>
            <View style={[styles.tableHeaderCol, { width: "20%" }]}>
              <Text style={styles.tableColCell}>સંઘ પરિવાર</Text>
            </View>
            <View style={[styles.tableHeaderCol, { width: "25%" }]}>
              <Text style={styles.tableColCell}>વિશેષ નોંધ</Text>
            </View>
          </View>
          {vruts?.map((samiti, index1) => {
            return (
              <View style={styles.tableRow} key={index1 + 1} wrap={false}>
                <View
                  style={[styles.tableCol, styles.firstCell, { width: "9%" }]}
                >
                  <Text style={styles.tableCell}>{index1 + 1}</Text>
                </View>
                <View style={[styles.tableCol, { width: "28%" }]}>
                  <Text style={styles.tableCell}>
                    {samiti.name + (samiti.sanyojak ? " (સંયોજક)" : "")}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: "18%" }]}>
                  <Text style={styles.tableCell}>{samiti?.mobileno}</Text>
                </View>
                <View style={[styles.tableCol, { width: "20%" }]}>
                  <Text style={styles.tableCell}>{samiti?.sangh_parivar}</Text>
                </View>
                <View style={[styles.tableCol, { width: "25%" }]}>
                  <Text style={styles.tableCell}>{samiti?.vishesh_nondh}</Text>
                </View>
              </View>
            );
          })}
        </View>
      );
    });
    return iteratedVruts;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.header}>
          <Text>વસ્તી સમિતિ</Text>
        </View>
        <View>{listSamitis()}</View>
      </Page>
    </Document>
  );
};
export default VastiSamitiPDF;
