import { styled, Table, TableBody, TableCell, TableRow } from "@mui/material";
import MyFormDialogBox from "app/components/MyFormDialogBox";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& tbody": {
    "& tr": {
      "& td": {
        paddingLeft: 0,
        textTransform: "capitalize",
        fontSize: "17px",
        "& a": {
          color: "#3b3ea1",
        },
      },
      "& td:nth-of-type(3)": {
        fontWeight: "600",
      },
    },
  },
}));

const ShakhaProfile = (props) => {
  const shakhadata = props.shakhadata;
  const shakhauser = props.shakhauser;
  const sevavasti = props.sevavasti;

  return (
    <MyFormDialogBox
      isDetail={true}
      open={props.open}
      handleClose={() => {
        props.handleClose();
      }}
      title="શાખા પ્રોફાઈલ"
      content={
        <StyledTable sx={{ mt: "-15px" }}>
          <TableBody>
            <TableRow>
              <TableCell align="left" width="30%">
                શાખા
              </TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">{shakhadata.shakha_name}</TableCell>
            </TableRow>
            {shakhadata?.shakha_prakar_1 ? (
              <TableRow>
                <TableCell align="left">શાખા પ્રકાર</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {shakhadata.shakha_prakar_1}
                  {shakhadata?.shakha_prakar_2 &&
                    "\n" + shakhadata.shakha_prakar_2}
                  {shakhadata?.is_saptahik ? "\nસાપ્તાહિક શાખા" : ""}
                </TableCell>
              </TableRow>
            ) : null}
            {shakhadata?.samay ? (
              <TableRow>
                <TableCell align="left">સમય</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{shakhadata.samay}</TableCell>
              </TableRow>
            ) : null}
            {shakhadata?.sthan ? (
              <TableRow>
                <TableCell align="left">સ્થાન</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{shakhadata.sthan}</TableCell>
              </TableRow>
            ) : null}
            {shakhauser?.karyavah_user_name ? (
              <TableRow>
                <TableCell align="left">શાખા કાર્યવાહ</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {shakhauser?.karyavah_user_name}
                  {shakhauser?.karyavah_user_mobileno ? (
                    <a href={"tel:" + shakhauser?.karyavah_user_mobileno}>
                      {"\n" + shakhauser?.karyavah_user_mobileno}
                    </a>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
            {shakhauser?.shikshak_user_name ? (
              <TableRow>
                <TableCell align="left">મુખ્ય શિક્ષક</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {shakhauser?.shikshak_user_name}
                  {shakhauser?.karyavah_user_mobileno ? (
                    <a href={"tel:" + shakhauser?.shikshak_user_mobileno}>
                      {"\n" + shakhauser?.shikshak_user_mobileno}
                    </a>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
            {shakhadata?.saptahik_bethak_divas ? (
              <TableRow>
                <TableCell align="left">સાપ્તાહિક બેઠક દિવસ</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {shakhadata.saptahik_bethak_divas}
                </TableCell>
              </TableRow>
            ) : null}
            {shakhadata?.seva_divas ? (
              <TableRow>
                <TableCell align="left">સેવા દિન</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{shakhadata.seva_divas}</TableCell>
              </TableRow>
            ) : null}
            {shakhadata?.ganvesh_din ? (
              <TableRow>
                <TableCell align="left">ગણવેશ દિન</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{shakhadata.ganvesh_din}</TableCell>
              </TableRow>
            ) : null}
            {shakhadata?.upsthiti_din ? (
              <TableRow>
                <TableCell align="left">ઉપસ્થિતિ દિન</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{shakhadata.upsthiti_din}</TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell align="left">શાખા ધ્વજ</TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                {!shakhadata?.is_shakha_dhvaj ? "ના" : "હા"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">શાખા વિશલ</TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                {!shakhadata?.is_shakha_vishal ? "ના" : "હા"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">શાખામાં વાર્ષિક ઉત્સવ</TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                {!shakhadata?.is_shakha_varshikutsav ? "ના" : "હા"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">નગર</TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                {shakhadata?.nagar_id?.nagar_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">ઉપનગર</TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                {shakhadata?.upnagar_id?.upnagar_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">વસ્તી</TableCell>
              <TableCell align="left" width="4%">
                :
              </TableCell>
              <TableCell align="left">
                {shakhadata?.vasti_id?.vasti_name}
              </TableCell>
            </TableRow>
            {sevavasti[0]?.seva_vasti ? (
              <TableRow>
                <TableCell align="left">સેવા વસ્તી</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">{sevavasti[0]?.seva_vasti}</TableCell>
              </TableRow>
            ) : null}
            {sevavasti[0]?.pathdan_kendra?.name ? (
              <TableRow>
                <TableCell align="left">પાઠદાન કેન્દ્ર</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {sevavasti[0]?.pathdan_kendra?.name}
                </TableCell>
              </TableRow>
            ) : null}
            {sevavasti[0]?.aarogya_kendra?.name ? (
              <TableRow>
                <TableCell align="left">આરોગ્ય કેન્દ્ર</TableCell>
                <TableCell align="left" width="4%">
                  :
                </TableCell>
                <TableCell align="left">
                  {sevavasti[0]?.aarogya_kendra?.name}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </StyledTable>
      }
    />
  );
};

export default ShakhaProfile;
