import { Icon, IconButton, Menu, MenuItem } from "@mui/material";
import "./postitem.css";
import PhotoGrid from "../../../components/photogrid/PhotoGrid";
import { daysago } from "app/utils/utils";
import YTItem from "../../../components/YTItem";
import TextContent from "./TextContent";
import useAuth from "app/hooks/useAuth";
import { useEffect, useState } from "react";
import { AvatarGenerator } from "random-avatar-generator";
import { Span } from "app/components/Typography";

const PostItem = ({
  _id,
  access,
  content,
  youtube_link,
  embed_url,
  files,
  likes,
  comments,
  posted_by,
  createdAt,
  LikeCommunityPostMutation,
  handleDelete,
  handleEditOpen,
}) => {
  const { user } = useAuth();
  const [like, setlike] = useState(false);
  const [mtype, setmtype] = useState("");
  const [Totallike, setTotallike] = useState(0);
  const [isUserPost, setisUserPost] = useState(false);
  const [photogridarr, setphotogridarr] = useState([]);
  const generator = new AvatarGenerator();
  const like_btn = document.querySelector(".like-button-" + _id);
  const like_text = document.querySelector(".like-text-" + _id);

  useEffect(() => {
    setTotallike(likes?.length);

    if (
      likes?.filter((elements) => {
        return elements?.liked_by === user?.id;
      }).length > 0
    ) {
      setlike(true);
      like_btn?.classList.add("active");
      like_text?.classList.add("active");
    } else {
      setlike(false);
      like_btn?.classList.remove("active");
      like_text?.classList.remove("active");
    }

    if (user?.id === posted_by?._id || user?.role_level === "એડમિન સ્તર") {
      setisUserPost(true);
    }

    if (youtube_link !== "") {
      setmtype("YouTube લીંક");
    } else if (files.length > 0) {
      setmtype("IMAGES");
    } else {
      setmtype("");
    }

    setphotogridarr(
      files?.map(
        (file) =>
          `${process.env.REACT_APP_API_URL}community_post/${file.filename}`
      )
    );
  }, [
    user,
    youtube_link,
    files,
    likes,
    setlike,
    like_btn,
    like_text,
    posted_by,
  ]);

  const handleLike = () => {
    LikeCommunityPostMutation.mutate(_id);
    setlike(!like);
    if (like) {
      like_btn?.classList.remove("active");
      like_text?.classList.remove("active");
      setTotallike(Totallike - 1);
    } else {
      like_btn?.classList.add("active");
      like_text?.classList.add("active");
      generateClones(like_btn);
      setTotallike(Totallike + 1);
    }
  };

  function generateClones(button) {
    let clones = randomInt(2, 4);
    for (let it = 1; it <= clones; it++) {
      let clone = button.querySelector(".like-icon").cloneNode(true),
        size = randomInt(5, 16);
      button.appendChild(clone);
      clone.setAttribute("width", size);
      clone.setAttribute("height", size);
      clone.style.position = "absolute";
      clone.style.transition =
        "transform 0.5s cubic-bezier(0.12, 0.74, 0.58, 0.99) 0.3s, opacity 1s ease-out .5s";
      let animTimeout = setTimeout(function () {
        clearTimeout(animTimeout);
        clone.style.transform = "translate3d(5px,-50px,0)";
        clone.style.opacity = 0;
      }, 1);
      let removeNodeTimeout = setTimeout(function () {
        clone.parentNode.removeChild(clone);
        clearTimeout(removeNodeTimeout);
      }, 900);
      setTimeout(function () {
        button.classList.remove("animated");
      }, 600);
    }
  }

  function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <article className="common-post">
      <header className="common-post-header u-flex">
        <img
          src={generator.generateRandomAvatar(posted_by?.name)}
          className="user-image"
          width="40"
          height="40"
          alt=""
        />
        <div className="common-post-info">
          <div className="user-and-group u-flex">{posted_by?.name}</div>
          <div className="time-and-privacy">
            <time>{daysago(createdAt)}</time>
            {posted_by?.role_id?.role_name && (
              <span>{posted_by?.role_id?.role_name}</span>
            )}
            {posted_by?.shakha_id?.shakha_name && (
              <span>{posted_by?.shakha_id?.shakha_name}</span>
            )}
          </div>
        </div>
        {isUserPost && (
          <>
            <IconButton
              className="icon-button-2 u-margin-inline-start"
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Icon>more_horiz</Icon>
            </IconButton>

            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{ mt: 4 }}
            >
              <MenuItem
                key={"સંપાદિત કરો"}
                onClick={() =>
                  handleEditOpen({
                    _id,
                    access: Array.isArray(access) ? access : [access],
                    content,
                    media_type: mtype,
                    youtube_link,
                    embed_url,
                    files,
                  })
                }
              >
                <Icon color="primary">edit</Icon>
                <Span sx={{ pl: 1 }}>
                  <strong>સંપાદિત કરો</strong>
                </Span>
              </MenuItem>
              <MenuItem
                key={"કાઢી નાખો"}
                onClick={() => {
                  handleDelete(_id);
                  handleClose();
                }}
              >
                <Icon color="error">delete</Icon>
                <Span sx={{ pl: 1 }}>
                  <strong>કાઢી નાખો</strong>
                </Span>
              </MenuItem>
            </Menu>
          </>
        )}
      </header>
      <div className="common-post-content common-content">
        <TextContent
          description={content}
          limit={photogridarr.length > 0 || youtube_link ? 110 : 550}
        />
        <div className="embed-content">
          {photogridarr.length > 0 && <PhotoGrid images={photogridarr} />}
          {youtube_link && <YTItem embed_url={embed_url} />}
        </div>
      </div>
      {comments?.length > 0 || Totallike > 0 ? (
        <div className="summary u-flex">
          {Totallike > 0 && (
            <div className="reactions">
              <Icon>thumb_up</Icon>
              <div className="reactions-total">{Totallike}</div>
            </div>
          )}
          {comments?.length > 0 && (
            <div className="total-comments u-margin-inline-start">
              <Icon>chat_bubble_outline_icon</Icon>
              <b>
                {comments?.length > 1
                  ? comments?.length + "ટિપ્પણીઓ"
                  : comments?.length + "ટિપ્પણી"}
              </b>
            </div>
          )}
        </div>
      ) : null}
      {user ? (
        <section className="actions-buttons">
          <ul className="actions-buttons-list u-flex">
            <li className="actions-buttons-item">
              <div className="actions-buttons-button" onClick={handleLike}>
                <IconButton className={"like-button like-button-" + _id}>
                  <Icon className="like-icon">
                    {like ? `thumb_up` : `thumb_up_off_alt`}
                  </Icon>
                </IconButton>
                <span className={"text like-text like-text-" + _id}>
                  <b>{like ? `પસંદ કર્યું` : `પસંદ કરો`}</b>
                </span>
              </div>
            </li>
            <li className="actions-buttons-item">
              <div className="actions-buttons-button">
                <IconButton>
                  <Icon>chat_bubble_outline_icon</Icon>
                </IconButton>
                <span className="text">
                  <b>ટિપ્પણી</b>
                </span>
              </div>
            </li>
          </ul>
        </section>
      ) : (
        <div style={{ marginTop: "15px" }}></div>
      )}
    </article>
  );
};
export default PostItem;
