import { Alert, Box, Card, Grid, Icon, styled } from "@mui/material";
import { H2 } from "app/components/Typography";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layout";
import { useQuery } from "react-query";
import { getVasti } from "app/service/api-service/vasti-service";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import AddRamMandirVrut from "./AddRamMandirVrut";
import DateRamMandirVrut from "./DateRamMandirVrut";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const RamMandirVrut = () => {
  const { data: vastiList, isLoading } = useQuery(["getVasti"], getVasti);
  const [addopen, setAddopen] = useState(false);
  const [reportopen, setReportopen] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = (type) => {
    if (type === "report") {
      setReportopen(true);
    } else {
      setAddopen(true);
    }
  };

  const handleClose = () => {
    setReportopen(false);
    setAddopen(false);
  };

  return (
    <Layout
      isLoading={isLoading || false}
      title="વૃત"
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              pb: 2,
              borderBottom: "2px dotted #2c3c6e",
            }}
          >
            <LoadingButton
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              style={{
                minHeight: 40,
                margin: "10px 0",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">add</Icon> &nbsp;વૃત ઉમેરો
            </LoadingButton>
            <LoadingButton
              onClick={() => handleClickOpen("report")}
              variant="contained"
              color="primary"
              style={{
                minHeight: 36,
                margin: "10px 0 10px 10px",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">picture_as_pdf</Icon> &nbsp;તારીખ પ્રમાણે
              વૃત રિપોર્ટ
            </LoadingButton>
          </Box>
          <Grid container spacing={0} sx={{ mb: "24px", mt: 0.5 }}>
            {vastiList?.map((vasti, index) => (
              <Grid container spacing={0} key={index}>
                <Grid
                  sx={{ mt: 3 }}
                  item
                  lg={1.5}
                  xs={2.8}
                  onClick={() =>
                    navigate("/dashboard/ram_madir_sampark/vrut/vasti", {
                      state: { vasti: vasti },
                    })
                  }
                >
                  <StyledCard
                    elevation={6}
                    sx={{ borderRadius: "8px 0 0 8px", height: "100%" }}
                  >
                    <ContentBox>
                      <Box>
                        <H2
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            whiteSpace: "pre-wrap",
                            fontSize: "1.25rem",
                          }}
                        >
                          &nbsp;({index + 1})&nbsp;
                        </H2>
                      </Box>
                    </ContentBox>
                  </StyledCard>
                </Grid>
                <Grid
                  sx={{ mt: 3 }}
                  item
                  lg={10.5}
                  xs={9.2}
                  onClick={() =>
                    navigate("/dashboard/ram_madir_sampark/vrut/vasti", {
                      state: { vasti: vasti },
                    })
                  }
                >
                  <StyledCard
                    elevation={6}
                    sx={{ borderRadius: "0 8px 8px 0" }}
                  >
                    <ContentBox>
                      <Box>
                        <H2
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            whiteSpace: "pre-wrap",
                            fontSize: "1.25rem",
                          }}
                        >
                          {vasti.vasti_name}
                        </H2>
                      </Box>
                    </ContentBox>
                  </StyledCard>
                </Grid>
              </Grid>
            ))}
            {vastiList?.length < 1 && (
              <Alert severity="info" sx={{ mt: 2, width: "100%" }}>
                <b>કોઈ ડેટા મળ્યો નથી</b>
              </Alert>
            )}
          </Grid>
          {addopen && (
            <AddRamMandirVrut handleClose={handleClose} open={addopen} />
          )}
          {reportopen && (
            <DateRamMandirVrut handleClose={handleClose} open={reportopen} />
          )}
        </>
      }
    />
  );
};

export default RamMandirVrut;
