import { useState } from "react";
import { truncate } from "lodash";
import Linkify from "react-linkify";

const TextContent = ({ description, limit }) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <p>
      {description.length > limit ? (
        <div>
          {showAll ? (
            <div>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {description}
              </Linkify>
            </div>
          ) : (
            <div>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {truncate(description, {
                  length: limit,
                  separator: /,?\.* +/,
                })}
              </Linkify>
              &nbsp;
              <span className="see-more-link" onClick={() => setShowAll(true)}>
                વધુ જુઓ
              </span>
            </div>
          )}
        </div>
      ) : (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="_blank" href={decoratedHref} key={key} rel="noreferrer">
              {decoratedText}
            </a>
          )}
        >
          {description}
        </Linkify>
      )}
    </p>
  );
};
export default TextContent;
