import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { AuthProvider } from "./contexts/JWTAuthContext";
import routes from "./routes";
import React, { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { App as AndroidApp } from "@capacitor/app";

const App = () => {
  const content = useRoutes(routes);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(
    () => {
      if (Capacitor.isNativePlatform()) {
        AndroidApp.removeAllListeners().then(() => {
          AndroidApp.addListener("backButton", ({ canGoBack }) => {
            if (
              location.pathname === "/community/dashboard" ||
              location.pathname === "/session/signin" ||
              location.pathname === "/"
            ) {
              let ans = window.confirm(
                "શું તમે ખરેખર એપ્લિકેશનની બહાર જવા માંગો છો ?"
              );
              if (ans) {
                AndroidApp.exitApp();
              } else {
                return false;
              }
            } else {
              navigate(-1);
            }
          });
        });
      }
    }, // eslint-disable-next-line
    [location]
  );
  return <AuthProvider>{content}</AuthProvider>;
};

export default App;
