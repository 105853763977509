import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getVistarSaptah() {
  const response = await axiosInstance.get(
    "api/vistarsaptah/fetchallvistarsaptah"
  );
  return response.data;
}

export async function getVistarSaptahYears() {
  const response = await axiosInstance.get(
    "api/vistarsaptah/fetchvistarsaptahyears"
  );
  return response.data;
}

export async function getVistarSaptahbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/vistarsaptah/fetchbymonthvistarsaptah",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addVistarSaptah({
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/vistarsaptah/addvistarsaptah",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateVistarSaptah({
  _id: id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/vistarsaptah/updatevistarsaptah/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteVistarSaptah(id) {
  const response = await axiosInstance.delete(
    `api/vistarsaptah/deletevistarsaptah/${id}`
  );
  return response;
}
