import AuthGuard from "app/auth/AuthGuard";
import dashboardRoutes from "app/views/dashboard/DashboardRoutes";
import NotFound from "app/views/sessions/NotFound";
import roleRoutes from "app/views/roles/RolesRoutes";
import NagarRoutes from "./views/nagar/NagarRoutes";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import { Navigate } from "react-router-dom";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import UpnagarRoutes from "./views/upnagar/UpnagarRoutes";
import VastiRoutes from "./views/vasti/VastiRoutes";
import ShakhaRoutes from "./views/shakha/ShakhaRoutes";
import StaffRoutes from "./views/staff/StaffRoutes";
import AshtabinduRoutes from "./views/ashtabindu/AshtabinduRoutes";
import UpsthitiRoutes from "./views/upsthiti/UpsthitiRoutes";
import VarshikCalenderRoutes from "./views/varshik_calender/VarshikCalenderRoutes";
import SwaymsevakRoutes from "./views/swaymsevak/SwaymsevakRoutes";
import DownloadCenterRoutes from "./views/download_center/DownloadCenterRoutes";
import CommunityRoutes from "./views/community/CommunityRoutes";

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...roleRoutes,
      ...NagarRoutes,
      ...UpnagarRoutes,
      ...VastiRoutes,
      ...ShakhaRoutes,
      ...StaffRoutes,
      ...AshtabinduRoutes,
      ...UpsthitiRoutes,
      ...VarshikCalenderRoutes,
      ...SwaymsevakRoutes,
      ...DownloadCenterRoutes,
      ...CommunityRoutes,
    ],
  },
  ...sessionRoutes,
  { path: "/", element: <Navigate to="/dashboard/default/" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
