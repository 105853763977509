import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getKaryakariniBethak() {
  const response = await axiosInstance.get(
    "api/karyakarinibethak/fetchallkaryakarinibethak"
  );
  return response.data;
}

export async function getKaryakariniBethakYears() {
  const response = await axiosInstance.get(
    "api/karyakarinibethak/fetchkaryakarinibethakyears"
  );
  return response.data;
}

export async function getKaryakariniBethakbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/karyakarinibethak/fetchbymonthkaryakarinibethak",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addKaryakariniBethak({
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/karyakarinibethak/addkaryakarinibethak",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateKaryakariniBethak({
  _id: id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/karyakarinibethak/updatekaryakarinibethak/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteKaryakariniBethak(id) {
  const response = await axiosInstance.delete(
    `api/karyakarinibethak/deletekaryakarinibethak/${id}`
  );
  return response;
}
