import { Alert, Box, Card, Grid, styled } from "@mui/material";
import { H2 } from "app/components/Typography";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import { useQuery } from "react-query";
import { getVastibyShakhacount } from "app/service/api-service/vasti-service";
import { useState } from "react";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const PurnVasti = () => {
  const navigate = useNavigate();
  const [vastiList, setVastiList] = useState();
  const { isLoading } = useQuery(
    ["getVastibyShakhacount"],
    getVastibyShakhacount,
    {
      onSuccess: (data) => {
        data.filter((vasti) => {
          let tarun_shakha = 0;
          let tarun_v_shakha = 0;
          let prodh_shakha = 0;
          vasti?.shakha_count.filter(({ shakha_prakar_1 }) => {
            if (shakha_prakar_1 === "તરુણ વિધાર્થી (કોલેજીયન) શાખા") {
              tarun_shakha += 1;
            }
            if (shakha_prakar_1 === "તરુણ વ્યવસાયિક શાખા") {
              tarun_v_shakha += 1;
            }
            if (shakha_prakar_1 === "પ્રૌઢ વ્યવસાયિક શાખા") {
              prodh_shakha += 1;
            }
            return false;
          });
          if (prodh_shakha + tarun_v_shakha + tarun_shakha >= 3) {
            setVastiList([{ ...vastiList, ...vasti }]);
          }
          return false;
        });
      },
    }
  );

  return (
    <Layout
      isLoading={isLoading || false}
      title="પૂર્ણ વસ્તી"
      content={
        <Grid container spacing={0} sx={{ mb: "24px", mt: 0.5 }}>
          {vastiList?.map((vasti, index) => (
            <Grid container spacing={0} key={index}>
              <Grid
                sx={{ mt: 3 }}
                item
                lg={1.5}
                xs={2.8}
                onClick={() =>
                  navigate("/dashboard/vasti/details", {
                    state: { vasti: vasti },
                  })
                }
              >
                <StyledCard
                  elevation={6}
                  sx={{ borderRadius: "8px 0 0 8px", height: "100%" }}
                >
                  <ContentBox>
                    <Box>
                      <H2
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          whiteSpace: "pre-wrap",
                          fontSize: "1.25rem",
                        }}
                      >
                        &nbsp;({index + 1})&nbsp;
                      </H2>
                    </Box>
                  </ContentBox>
                </StyledCard>
              </Grid>
              <Grid
                sx={{ mt: 3 }}
                item
                lg={10.5}
                xs={9.2}
                onClick={() =>
                  navigate("/dashboard/vasti/details", {
                    state: { vasti: vasti },
                  })
                }
              >
                <StyledCard elevation={6} sx={{ borderRadius: "0 8px 8px 0" }}>
                  <ContentBox>
                    <Box>
                      <H2
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          whiteSpace: "pre-wrap",
                          fontSize: "1.25rem",
                        }}
                      >
                        {vasti.vasti_name}
                      </H2>
                    </Box>
                  </ContentBox>
                </StyledCard>
              </Grid>
            </Grid>
          ))}
          {vastiList?.length < 1 && (
            <Alert severity="info" sx={{ mt: 2, width: "100%" }}>
              <b>કોઈ ડેટા મળ્યો નથી</b>
            </Alert>
          )}
        </Grid>
      }
    />
  );
};

export default PurnVasti;
