import * as React from "react";
import MUIDataTable from "mui-datatables";
import { styled } from "@mui/system";

const ContentBox = styled("div")(({ theme }) => ({
  background: "#fff",
  borderRadius: "5px",
}));

const MyDashDataTable = (props) => {
  const columns = props.columns;
  const data = props.data;
  const toolbar = props.options;
  const filename = props.filename;

  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    tableBodyHeight: "",
    tableBodyMaxHeight: "",
    selectableRows: "none",
    downloadOptions: {
      filename: filename,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    ...toolbar,
  };

  return (
    <ContentBox>
      <MUIDataTable data={data} columns={columns} options={options} />
    </ContentBox>
  );
};

export default MyDashDataTable;
