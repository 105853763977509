import * as React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  styled,
  Box,
  IconButton,
  useTheme,
  Tooltip,
  Button,
} from "@mui/material";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useMutation, useQueryClient } from "react-query";
import { Icon } from "@mui/material";
import { updateLocalSamitiPersons } from "app/service/api-service/localsamiti-service";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const AddPersonSamiti = (props) => {
  const [state, setState] = useState({
    samiti_id: props.addperson.samiti_id,
    persons:
      props.addperson.persons.length > 0
        ? props.addperson.persons
        : [
            {
              name: "",
              mobileno: "",
              vishesh_nondh: "",
            },
          ],
  });
  const [inputFields, setInputFields] = useState(state.persons);
  const theme = useTheme();
  const queryClient = useQueryClient();

  const UpdateLocalSamitiPersonsMutation = useMutation(
    updateLocalSamitiPersons,
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(["getLocalSamitibyVasti", props.value]);
        setInputFields([
          {
            name: "",
            mobileno: "",
            vishesh_nondh: "",
          },
        ]);
        props.handleClose();
      },
    }
  );

  const handleSubmit = async () => {
    UpdateLocalSamitiPersonsMutation.mutate(state);
  };

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        name: "",
        mobileno: "",
        vishesh_nondh: "",
      },
    ]);
  };

  const removeInputFields = (index, event) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
    setState({ ...state, persons: rows });
  };

  return (
    <MyDialogBox
      maxWidth="md"
      open={props.open}
      handleClose={() => {
        props.handleClose();
      }}
      title="સભ્યો ઉમેરો"
      content={
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          {inputFields.map((data, key) => {
            const { name, mobileno, vishesh_nondh } = data;
            return (
              <Grid
                container
                key={key}
                spacing={1}
                sx={{
                  borderBottom: "1px dashed #939090",
                  mb: 2,
                  justifyContent: "center",
                }}
              >
                <Grid item lg={4} md={4} sm={11.8} xs={11.8}>
                  <TextField
                    type="text"
                    name="name"
                    id="standard-basic3"
                    value={name || ""}
                    onChange={(event) => {
                      const list = [...inputFields];
                      list[key]["name"] = event.target.value;
                      setInputFields(list);
                      setState({ ...state, persons: list });
                    }}
                    errorMessages={["નામ દાખલ કરો"]}
                    label="નામ *"
                    validators={["required"]}
                  />
                </Grid>
                <Grid item lg={3.2} md={3.2} sm={11.8} xs={11.8}>
                  <TextField
                    inputProps={{
                      pattern: "[0-9]*",
                      maxLength: "10",
                      autoComplete: "new-password",
                    }}
                    type="tel"
                    value={(mobileno && String(mobileno)) || ""}
                    name="mobileno"
                    id="standard-basic1"
                    onChange={(event) => {
                      event.target.value.replace(/\D/g, "");
                      event.target.value.substr(0, 10);
                      const list = [...inputFields];
                      list[key]["mobileno"] = String(event.target.value);
                      setInputFields(list);
                      setState({ ...state, persons: list });
                    }}
                    errorMessages={[
                      "માન્ય મોબાઇલ નંબર દાખલ કરો",
                      "માન્ય મોબાઇલ નંબર દાખલ કરો",
                      "માન્ય મોબાઇલ નંબર દાખલ કરો",
                    ]}
                    label="મોબાઇલ નંબર"
                    validators={[
                      "isNumber",
                      "minStringLength: 10",
                      "maxStringLength: 10",
                    ]}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={11.8} xs={11.8}>
                  <TextField
                    multiline
                    type="text"
                    name="vishesh_nondh"
                    id="standard-basic3"
                    value={vishesh_nondh || ""}
                    onChange={(event) => {
                      const list = [...inputFields];
                      list[key]["vishesh_nondh"] = event.target.value;
                      setInputFields(list);
                      setState({ ...state, persons: list });
                    }}
                    label="વિશેષ નોંધ"
                  />
                </Grid>
                <Grid item lg={0.8} md={0.8} sm={0.8} xs={0.8}>
                  {inputFields.length !== 1 && key !== 0 ? (
                    <Box
                      sx={{
                        pt: 1.5,
                        pl: 0.3,
                        [theme.breakpoints.down("md")]: {
                          mt: -1,
                          pt: 0,
                          pl: 0,
                        },
                        [theme.breakpoints.down("sm")]: {
                          mt: -1,
                          pt: 0,
                          pl: 0,
                        },
                      }}
                    >
                      <Tooltip
                        title="હટાવો"
                        placement="bottom"
                        onClick={(event) => removeInputFields(key, event)}
                      >
                        <IconButton>
                          <Icon color="error">clear</Icon>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            );
          })}
          <Tooltip title="ઉમેરો" placement="bottom">
            <Button variant="outlined" color="warning" onClick={addInputField}>
              ઉમેરો
            </Button>
          </Tooltip>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={UpdateLocalSamitiPersonsMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default AddPersonSamiti;
