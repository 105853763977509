import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import GujaratiFont from "../../../../../pdf_fonts/MuktaVaani-Regular.ttf";
import BoldGujaratiFont from "../../../../../pdf_fonts/MuktaVaani-Bold.ttf";

Font.register({
  family: "GujaratiFont",
  src: GujaratiFont,
});
Font.register({
  family: "GujaratiFont",
  src: BoldGujaratiFont,
});
const styles = StyleSheet.create({
  page: {
    fontFamily: "GujaratiFont",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  header: {
    fontSize: "25px",
    textAlign: "center",
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexGrow: 1,
    height: "auto",
    flexDirection: "row",
  },
  tableCol: {
    border: "2px solid black",
    borderStyle: "solid",
    borderCollapse: "collapse",
  },
  tableColCell: {
    padding: "5px",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    flexGrow: 1,
    marginTop: 5,
    marginBottom: 5,
    fontSize: "12px",
    textAlign: "center",
  },
});

const SevaVastiReportPDF = ({ sevaList }) => {
  let count = 1;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.header, { margin: "0 0 20px 0" }]}>
          <Text>સેવા વસ્તી રિપોર્ટ</Text>
        </View>
        <View style={styles.table}>
          <View>
            <View style={styles.tableRow} wrap={false} fixed>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableColCell}>ક્રમાંક</Text>
              </View>
              <View style={[styles.tableCol, { width: "30%" }]}>
                <Text style={styles.tableColCell}>શાખા</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableColCell}>સેવા વસ્તી</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableColCell}>સેવા કાર્યકર્તા</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableColCell}>મોબાઇલ નંબર</Text>
              </View>
            </View>
            {sevaList?.map((seva, index) => (
              <View style={styles.tableRow} wrap={false} key={index + 1}>
                <View style={[styles.tableCol, { width: "10%" }]}>
                  <Text style={styles.tableCell}>{count++}</Text>
                </View>
                <View style={[styles.tableCol, { width: "30%" }]}>
                  <Text style={styles.tableCell}>
                    {seva?.shakha_id?.shakha_name}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: "20%" }]}>
                  <Text style={styles.tableCell}>{seva?.seva_vasti}</Text>
                </View>
                <View style={[styles.tableCol, { width: "20%" }]}>
                  <Text style={styles.tableCell}>
                    {seva?.seva_karyakarta?.map(
                      (karyakarta, index) =>
                        "(" +
                        (index + 1) +
                        ") " +
                        karyakarta?.swayamsevak_id?.name +
                        "\n"
                    )}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: "20%" }]}>
                  <Text style={styles.tableCell}>
                    {seva?.seva_karyakarta?.map(
                      (karyakarta, index) =>
                        "(" +
                        (index + 1) +
                        ") " +
                        karyakarta?.swayamsevak_id?.mobileno +
                        "\n"
                    )}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default SevaVastiReportPDF;
