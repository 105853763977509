import Loadable from "app/components/Loadable";
import { lazy } from "react";
import AccessDenied from "./AccessDenied";
import PublicCommunity from "./PublicCommunity";

const NotFound = Loadable(lazy(() => import("./NotFound")));
// const ForgotPassword = Loadable(lazy(() => import("./ForgotPassword")));
const JwtLogin = Loadable(lazy(() => import("./JwtLogin")));
// const JwtRegister = Loadable(lazy(() => import("./JwtRegister")));

const sessionRoutes = [
  // { path: '/session/signup', element: <JwtRegister /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/public/community", element: <PublicCommunity /> },
  // { path: '/session/forgot-password', element: <ForgotPassword /> },
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/access_denied", element: <AccessDenied /> },
];

export default sessionRoutes;
