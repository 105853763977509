import axiosInstance from "axios-instance";
import download from "downloadjs";

export async function getAllSwaymsevak() {
  const response = await axiosInstance.get(
    "api/swaymsevak/fetchallswaymsevaks"
  );
  return response.data;
}

export async function getSwaymsevak(gatnayak_id) {
  const response = await axiosInstance.get(
    `api/swaymsevak/fetchallswaymsevak/${gatnayak_id}`
  );
  return response.data;
}

export async function getSwaymsevakbyShakha(shakha_id) {
  const response = await axiosInstance.get(
    `api/swaymsevak/swaymsevakbyshakha/${shakha_id}`
  );
  return response.data;
}

export async function searchSwaymsevak(search_query) {
  const response = await axiosInstance.get("api/swaymsevak/searchswaymsvak", {
    params: {
      search: search_query,
    },
  });
  return response.data;
}

export async function searchSwaymsevakbyShakha(shakha_id) {
  const response = await axiosInstance.get(
    `api/swaymsevak/searchswaymsvakbyshakha/${shakha_id}`
  );
  return response.data;
}

export async function getExistsShakhatoli() {
  const response = await axiosInstance.get(
    "api/swaymsevak/fetchaexitsshakhatoli"
  );
  return response.data;
}

export async function getGatnayakTolibyGatnayak(gatnayak_id) {
  const response = await axiosInstance.get(
    `api/swaymsevak/fetchgatnayaktolibygatnayak/${gatnayak_id}`
  );
  return response.data;
}

export async function addSwaymsevak({
  name,
  mobileno,
  address,
  vyavsahay,
  sanghshikshan,
  karyaruchi,
  shakhatoli_role,
  shakha_id,
  is_gatnayak,
}) {
  const response = await axiosInstance.post("api/swaymsevak/addswaymsevak", {
    name,
    mobileno,
    address,
    vyavsahay,
    sanghshikshan,
    karyaruchi,
    shakhatoli_role,
    shakha_id,
    is_gatnayak,
  });
  return response;
}

export async function updateSwaymsevak({
  _id: id,
  name,
  mobileno,
  address,
  vyavsahay,
  sanghshikshan,
  karyaruchi,
  shakhatoli_role,
  shakha_id,
  is_gatnayak,
}) {
  const response = await axiosInstance.put(
    `api/swaymsevak/updateswaymsevak/${id}`,
    {
      name,
      mobileno,
      address,
      vyavsahay,
      sanghshikshan,
      karyaruchi,
      shakhatoli_role,
      shakha_id,
      is_gatnayak,
    }
  );
  return response;
}

export async function deleteSwaymsevak(id) {
  const response = await axiosInstance.delete(
    `api/swaymsevak/deleteswaymsevak/${id}`
  );
  return response;
}

export async function addGatnayakToli(data) {
  const response = await axiosInstance.post("api/swaymsevak/addgatnayaktoli", {
    data,
  });
  return response;
}

export async function downloadSampleExcelFile() {
  const response = await axiosInstance.get(
    "api/swaymsevak/download_sample_excel_file",
    {
      responseType: "blob",
    }
  );
  return download(
    response.data,
    "SAMPLE_SWAYMSEVAK_EXCEL",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
}

export async function importSwaymsevakExcel(formData) {
  const response = await axiosInstance.post(
    "api/swaymsevak/import_swaymsevak_excel",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=XXX",
      },
    }
  );
  return response;
}
