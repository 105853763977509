import axiosInstance from "axios-instance";
import dayjs from "dayjs";

export async function getVastiPramukhVarg() {
  const response = await axiosInstance.get(
    "api/vastipramukhvarg/fetchallvastipramukhvarg"
  );
  return response.data;
}

export async function getVastiPramukhVargYears() {
  const response = await axiosInstance.get(
    "api/vastipramukhvarg/fetchvastipramukhvargyears"
  );
  return response.data;
}

export async function getVastiPramukhVargbyMonth({ month, year }) {
  const response = await axiosInstance.get(
    "api/vastipramukhvarg/fetchbymonthvastipramukhvarg",
    {
      params: {
        month,
        year,
      },
    }
  );
  return response.data;
}

export async function addVastiPramukhVarg({
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.post(
    "api/vastipramukhvarg/addvastipramukhvarg",
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function updateVastiPramukhVarg({
  _id: id,
  date,
  sthan,
  upsthiti,
  bal,
  tarun,
  yog,
}) {
  const response = await axiosInstance.put(
    `api/vastipramukhvarg/updatevastipramukhvarg/${id}`,
    {
      date: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      sthan,
      upsthiti,
      bal,
      tarun,
      yog,
    }
  );
  return response;
}

export async function deleteVastiPramukhVarg(id) {
  const response = await axiosInstance.delete(
    `api/vastipramukhvarg/deletevastipramukhvarg/${id}`
  );
  return response;
}
