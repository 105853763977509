import { Alert, Snackbar } from "@mui/material";
import { amber, green } from "@mui/material/colors";
import { styled } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "app/redux/reducers/SnackbarReducer";

const ContentRoot = styled("div")(({ theme }) => ({
  "& .icon": { fontSize: 20 },
  "& .success": { backgroundColor: green[600] },
  "& .warning": { backgroundColor: amber[700] },
  "& .error": { backgroundColor: theme.palette.error.main },
  "& .info": { backgroundColor: theme.palette.primary.main },
  "& .iconVariant": { opacity: 0.9, marginRight: theme.spacing(1) },
  "& .message": { display: "flex", alignItems: "center" },
  "& .margin": { margin: theme.spacing(1) },
}));

export default function GlobalSnackbar() {
  const vertical = "top";
  const horizontal = "right";
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector(
    (state) => state.snackbar.snackbarMessage
  );
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackbar(false, snackbarType, snackbarMessage));
  };

  return (
    <ContentRoot>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        style={{ whiteSpace: "pre-line" }}
      >
        <Alert
          style={{ alignItems: "center", lineHeight: "25px" }}
          onClose={handleClose}
          severity={snackbarType}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ContentRoot>
  );
}
