import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Dashboard = Loadable(lazy(() => import("./Dashboard")));

const CommunityRoutes = [
  {
    path: "/community/dashboard",
    element: <Dashboard />,
    auth: [
      "એડમિન સ્તર",
      "શાખા સ્તર",
      "નગર સ્તર",
      "ઉપનગર સ્તર",
      "વસ્તી સ્તર",
      "સ્વયંસેવક સ્તર",
    ],
  },
];

export default CommunityRoutes;
