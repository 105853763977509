import * as React from "react";
import { Box, Icon, Tooltip } from "@mui/material";
import { useQuery } from "react-query";
import { useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { LoadingButton } from "@mui/lab";
import Layout from "../Layout";
import { getShakha } from "app/service/api-service/shakha-service";
import { H2 } from "app/components/Typography";
import MyReportTable from "app/components/MyReportTable";
import { getVastibyShakhacount } from "app/service/api-service/vasti-service";
import VartmanSthitiReportPDF from "./VartmanSthitiReportPDF";

const VartmanSthitiReport = () => {
  const [PDFLoading, setPDFLoading] = useState(false);
  const [shakhaList, setshakhaList] = useState([]);
  const [shakhaList2, setshakhaList2] = useState([]);
  const [state, setstate] = useState([]);

  const { isLoading } = useQuery(["getShakha"], getShakha, {
    onSuccess: (data) => {
      setshakhaList([]);
      setshakhaList(...shakhaList, [
        [
          data?.filter((shakha) => shakha.is_saptahik === 0).length,
          data?.filter((shakha) => shakha.is_saptahik === 1).length,
          data?.length,
        ],
      ]);
      setshakhaList2([]);
      setshakhaList2(...shakhaList2, [
        [
          "શાખા",
          data?.filter(
            (shakha) =>
              shakha.is_saptahik === 0 &&
              shakha.shakha_prakar_1 === "સંયુક્ત વિદ્યાર્થી શાખા"
          ).length,
          data?.filter(
            (shakha) =>
              shakha.is_saptahik === 0 &&
              shakha.shakha_prakar_1 === "તરુણ વિધાર્થી (કોલેજીયન) શાખા"
          ).length,
          data?.filter(
            (shakha) =>
              shakha.is_saptahik === 0 &&
              shakha.shakha_prakar_1 === "તરુણ વ્યવસાયિક શાખા"
          ).length,
          data?.filter(
            (shakha) =>
              shakha.is_saptahik === 0 &&
              shakha.shakha_prakar_1 === "પ્રૌઢ વ્યવસાયિક શાખા"
          ).length,
          data?.filter((shakha) => shakha.is_saptahik === 0).length,
        ],
        [
          "સાપ્તાહિક શાખા",
          data?.filter(
            (shakha) =>
              shakha.is_saptahik === 1 &&
              shakha.shakha_prakar_1 === "સંયુક્ત વિદ્યાર્થી શાખા"
          ).length,
          data?.filter(
            (shakha) =>
              shakha.is_saptahik === 1 &&
              shakha.shakha_prakar_1 === "તરુણ વિધાર્થી (કોલેજીયન) શાખા"
          ).length,
          data?.filter(
            (shakha) =>
              shakha.is_saptahik === 1 &&
              shakha.shakha_prakar_1 === "તરુણ વ્યવસાયિક શાખા"
          ).length,
          data?.filter(
            (shakha) =>
              shakha.is_saptahik === 1 &&
              shakha.shakha_prakar_1 === "પ્રૌઢ વ્યવસાયિક શાખા"
          ).length,
          data?.filter((shakha) => shakha.is_saptahik === 1).length,
        ],
      ]);
    },
  });

  useQuery(["getVastibyShakhacount"], getVastibyShakhacount, {
    onSuccess: (data) => {
      let total_purn_vasti = 0;

      data.filter(({ shakha_count }) => {
        let tarun_shakha = 0;
        let tarun_v_shakha = 0;
        let prodh_shakha = 0;
        shakha_count.filter(({ shakha_prakar_1 }) => {
          if (shakha_prakar_1 === "તરુણ વિધાર્થી (કોલેજીયન) શાખા") {
            tarun_shakha += 1;
          }
          if (shakha_prakar_1 === "તરુણ વ્યવસાયિક શાખા") {
            tarun_v_shakha += 1;
          }
          if (shakha_prakar_1 === "પ્રૌઢ વ્યવસાયિક શાખા") {
            prodh_shakha += 1;
          }
          return false;
        });
        if (prodh_shakha + tarun_v_shakha + tarun_shakha >= 3) {
          total_purn_vasti += 1;
        }
        return false;
      });

      setstate(...state, [
        [
          total_purn_vasti,
          data.filter(({ shakha_count }) => shakha_count.length > 0).length,
          data.filter(
            ({ shakha_count }) =>
              shakha_count.filter(({ is_saptahik }) => is_saptahik === 1)
                .length > 0
          ).length,
          data.filter(({ shakha_count }) => shakha_count.length === 0).length,
        ],
      ]);
    },
  });

  const DownloadPDF = async () => {
    setPDFLoading(true);
    const doc = (
      <VartmanSthitiReportPDF
        shakhaList={shakhaList}
        shakhaList2={shakhaList2}
        state={state}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "વર્તમાન સ્થિતિ.pdf");
    setPDFLoading(false);
  };
  const columns = ["શાખા ", "સાપ્તાહિક શાખા", "કુલ શાખા"];
  const columns2 = [
    "શાખા/પ્રકાર",
    "સંયુક્ત વિદ્યાર્થી શાખા",
    "તરુણ વિધાર્થી (કોલેજીયન) શાખા",
    "તરુણ વ્યવસાયિક શાખા",
    "પ્રૌઢ વ્યવસાયિક શાખા",
    "કુલ શાખા",
  ];
  const columns3 = [
    "પૂર્ણ વસ્તી",
    "કાર્યયુક્ત વસ્તી",
    "સંપર્કીત વસ્તી",
    "કાર્યવિહીન વસ્તી",
  ];
  const options = {};

  return (
    <Layout
      isLoading={isLoading || false}
      title="વર્તમાન સ્થિતિ રિપોર્ટ"
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              borderBottom: "1px dashed #ccc",
            }}
          >
            <Tooltip title="ડાઉનલોડ રિપોર્ટ" placement="bottom">
              <LoadingButton
                loading={PDFLoading}
                onClick={DownloadPDF}
                variant="contained"
                color="primary"
                style={{
                  minHeight: 36,
                  margin: "10px 0 10px 10px",
                  fontWeight: "bold",
                }}
              >
                <Icon className="icon">picture_as_pdf</Icon> &nbsp;ડાઉનલોડ
                રિપોર્ટ
              </LoadingButton>
            </Tooltip>
          </Box>
          <H2
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              mt: 2,
              pb: 2,
              borderBottom: "1px dashed #ccc",
            }}
          >
            (1) વર્તમાન સ્થિતિ (શાખા/ સાપ્તાહિક શાખા)
          </H2>
          <MyReportTable
            data={shakhaList}
            columns={columns}
            options={options}
          />
          <H2
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              mt: 2,
              pb: 2,
              borderBottom: "1px dashed #ccc",
            }}
          >
            (2) પ્રકારના આધારે શાખાઓ
          </H2>
          <MyReportTable
            data={shakhaList2}
            columns={columns2}
            options={options}
          />
          <H2
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              mt: 2,
              pb: 2,
              borderBottom: "1px dashed #ccc",
            }}
          >
            (3) વસ્તી
          </H2>
          <MyReportTable data={state} columns={columns3} options={options} />
        </>
      }
    />
  );
};

export default VartmanSthitiReport;
