import axiosInstance from "axios-instance";

export async function getSevaVasti() {
  const response = await axiosInstance.get("api/sevavasti/fetchallsevavasti");
  return response.data;
}

export async function getSevaVastibyShakha(shakha_id) {
  const response = await axiosInstance.get(
    `api/sevavasti/fetchsevavastibyshakha/${shakha_id}`
  );
  return response.data;
}

export async function addSevaVasti({
  seva_vasti,
  shakha_id,
  seva_karyakarta,
  pathdan_kendra,
  sanskar_kendra,
  aarogya_kendra,
  svavlamban,
}) {
  const response = await axiosInstance.post("api/sevavasti/addsevavasti", {
    seva_vasti,
    shakha_id,
    seva_karyakarta,
    pathdan_kendra,
    sanskar_kendra,
    aarogya_kendra,
    svavlamban,
  });
  return response;
}

export async function updateSevaVasti({
  _id: id,
  seva_vasti,
  shakha_id,
  seva_karyakarta,
  pathdan_kendra,
  sanskar_kendra,
  aarogya_kendra,
  svavlamban,
}) {
  const response = await axiosInstance.put(
    `api/sevavasti/updatesevavasti/${id}`,
    {
      seva_vasti,
      shakha_id,
      seva_karyakarta,
      pathdan_kendra,
      sanskar_kendra,
      aarogya_kendra,
      svavlamban,
    }
  );
  return response;
}

export async function deleteSevaVasti(id) {
  const response = await axiosInstance.delete(
    `api/sevavasti/deletesevavasti/${id}`
  );
  return response;
}
