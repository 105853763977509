import { Box, Card, Grid, Icon, styled } from "@mui/material";
import { H2 } from "app/components/Typography";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layout";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import AddSamparkshreniYadi from "./AddSamparkshreniYadi";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const SamparkshreniYadi = () => {
  const navigate = useNavigate();
  const cardList = [
    {
      name: "સામાજિક અગ્રણી અને સ્વયંસેવી સંગઠન શ્રેણી",
      to: "/dashboard/view_samparkshreni_yadi/",
    },
    { name: "ચિકિત્સક શ્રેણી", to: "/dashboard/view_samparkshreni_yadi/" },
    { name: "ન્યાયિક શ્રેણી", to: "/dashboard/view_samparkshreni_yadi/" },
    {
      name: "પ્રબુદ્ધ નાગરિક શ્રેણી",
      to: "/dashboard/view_samparkshreni_yadi/",
    },
    {
      name: "પ્રશાંશનિક અધિકારી શ્રેણી",
      to: "/dashboard/view_samparkshreni_yadi/",
    },
    {
      name: "બિલ્ડર શ્રેણી (આર્થિક શ્રેણી)",
      to: "/dashboard/view_samparkshreni_yadi/",
    },
    {
      name: "વ્યાપારી શ્રેણી (આર્થિક શ્રેણી)",
      to: "/dashboard/view_samparkshreni_yadi/",
    },
    {
      name: "ઔદ્યોગિક શ્રેણી (આર્થિક શ્રેણી)",
      to: "/dashboard/view_samparkshreni_yadi/",
    },
    { name: "સીએ શ્રેણી", to: "/dashboard/view_samparkshreni_yadi/" },
    { name: "કલા શ્રેણી", to: "/dashboard/view_samparkshreni_yadi/" },
    { name: "ક્રિડા શ્રેણી", to: "/dashboard/view_samparkshreni_yadi/" },
    { name: "ધાર્મિક શ્રેણી", to: "/dashboard/view_samparkshreni_yadi/" },
  ];
  const [addopen, setAddopen] = useState(false);

  const handleClickOpen = () => {
    setAddopen(true);
  };

  const handleClose = () => {
    setAddopen(false);
  };

  return (
    <Layout
      title="સંપર્ક શ્રેણી યાદી"
      content={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              mt: 2,
              pb: 2,
              borderBottom: "2px dotted #2c3c6e",
            }}
          >
            <LoadingButton
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              style={{
                minHeight: 40,
                margin: "10px 0",
                fontWeight: "bold",
              }}
            >
              <Icon className="icon">add</Icon> &nbsp;યાદી ઉમેરો
            </LoadingButton>
          </Box>
          <Grid container spacing={3} sx={{ mb: "24px", mt: 0.5 }}>
            {cardList.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                key={index}
                onClick={() =>
                  navigate(item.to, {
                    state: { samparkshreni_prakar: item.name },
                  })
                }
              >
                <StyledCard elevation={6}>
                  <ContentBox>
                    <Box>
                      <H2
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          whiteSpace: "pre-wrap",
                          fontSize: "1.25rem",
                        }}
                      >
                        {item.name}
                      </H2>
                    </Box>
                  </ContentBox>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
          {addopen && (
            <AddSamparkshreniYadi handleClose={handleClose} open={addopen} />
          )}
        </>
      }
    />
  );
};

export default SamparkshreniYadi;
