import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Staff = Loadable(lazy(() => import("./Staff")));

const StaffRoutes = [
  {
    path: "/staff/allstaff",
    element: <Staff />,
    auth: ["એડમિન સ્તર", "નગર સ્તર", "ઉપનગર સ્તર", "વસ્તી સ્તર"],
  },
];

export default StaffRoutes;
