import { Box, Card, Grid, styled } from "@mui/material";
import { H2, Span } from "app/components/Typography";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import { useQuery } from "react-query";
import { getUpnagar } from "app/service/api-service/upnagar-service";
import { getSaptahikShakha } from "app/service/api-service/shakha-service";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px !important",
  background: "#eef2ff",
  border: "1px outset #2c3c6e",
  color: "#2c3c6e",
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.8,
    fontSize: "55px",
    color: "#ff4200",
  },
}));

const Upnagar = () => {
  const { data: upnagarList, isLoading } = useQuery(["getUpnagar"], getUpnagar);
  const { data: saptahik_shakha } = useQuery(
    ["getSaptahikShakha"],
    getSaptahikShakha
  );
  const navigate = useNavigate();
  let all_shakha_count = upnagarList?.reduce(
    (n, { shakha_count }) => n + shakha_count,
    0
  );
  all_shakha_count += saptahik_shakha ? saptahik_shakha.length : 0;
  return (
    <Layout
      isLoading={isLoading || false}
      title={`ઉપનગર (${all_shakha_count ? all_shakha_count : 0} શાખા)`}
      content={
        <Grid container spacing={3} sx={{ mb: "24px", mt: 0.5 }}>
          {upnagarList?.map((upnagar, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={index}
              onClick={() =>
                navigate("/dashboard/shakha/", {
                  state: { id: upnagar._id },
                })
              }
            >
              <StyledCard elevation={6}>
                <ContentBox>
                  <Box>
                    <H2
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        whiteSpace: "pre-wrap",
                        fontSize: "1.25rem",
                      }}
                    >
                      {upnagar.upnagar_name}{" "}
                      <Span sx={{ color: "#2d2b9b" }}>
                        ({upnagar.shakha_count})
                      </Span>
                    </H2>
                  </Box>
                </ContentBox>
              </StyledCard>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sm={4}
            key={-1}
            onClick={() =>
              navigate("/dashboard/shakha/", {
                state: { id: "0", saptahik_shakha: saptahik_shakha },
              })
            }
          >
            <StyledCard elevation={6}>
              <ContentBox>
                <Box>
                  <H2
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      whiteSpace: "pre-wrap",
                      fontSize: "1.25rem",
                    }}
                  >
                    સાપ્તાહિક મિલન{" "}
                    <Span sx={{ color: "#2d2b9b" }}>
                      ({saptahik_shakha ? saptahik_shakha.length : 0})
                    </Span>
                  </H2>
                </Box>
              </ContentBox>
            </StyledCard>
          </Grid>
        </Grid>
      }
    />
  );
};

export default Upnagar;
