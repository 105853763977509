import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            fontSize: "17px",
            fontWeight: "bold",
          },
          sortAction: {
            fontSize: "17px",
            fontWeight: "bold",
            alignItems: "center",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none !important",
            borderTop: "1px dashed black",
            borderBottom: "1px dashed black",
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            fontSize: "15px",
            wordBreak: "break-word",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          regular: {
            minHeight: "8px",
            textAlign: "center",
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          actions: {
            display: "none",
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            display: "none",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            marginBottom: "20px",
          },
        },
      },
    },
  });

const MyReportTable = (props) => {
  const columns = props.columns;
  const data = props.data;
  const title = props.title;

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "",
    tableBodyMaxHeight: "",
    rowsPerPage: 100000000000,
    rowsPerPageOptions: [],
    showFirstButton: false,
    selectableRows: "none",
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        sx={{ padding: 20 }}
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default MyReportTable;
