import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, styled, Box } from "@mui/material";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { updateKaryakariniBethak } from "app/service/api-service/karyakarinibethak-service";
import MyDialogBox from "app/components/MyFormDialogBox";
import { useQueryClient, useMutation } from "react-query";
import MyDatePicker from "app/components/MyDatePicker";
import dayjs from "dayjs";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const EditKaryakariniBethak = (props) => {
  const editval = props.edit;
  const [state, setState] = useState(editval);

  React.useEffect(() => {
    ValidatorForm.addValidationRule("isValidDate", (value) => {
      return dayjs(value, "DD-MM-YYYY", true).isValid();
    });
    return () => ValidatorForm.removeValidationRule("isValidDate");
  }, [state.date]);

  const queryClient = useQueryClient();

  const UpdateKaryakariniBethakMutation = useMutation(updateKaryakariniBethak, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["getKaryakariniBethakbyMonth", props?.value],
        (karyakarinibethakList) => {
          karyakarinibethakList.map((karyakarinibethak, index) => {
            if (karyakarinibethak._id === data.karyakarinibethakData._id) {
              karyakarinibethakList[index] = data.karyakarinibethakData;
            }
            return karyakarinibethak;
          });
          return karyakarinibethakList;
        }
      );
      props.setValue({
        month: state.date.get("month"),
        year: state.date.get("year"),
      });
      setState({});
      props.handleClose();
    },
  });

  const handleSubmit = async () => {
    UpdateKaryakariniBethakMutation.mutate(state);
  };

  const handleChange = (event) => {
    if (event.target.name !== "sthan") {
      event.target.value.replace(/\D/g, "");
      event.target.value.substr(0, 10);
      setState({
        ...state,
        [event.target.name]: event.target.validity.valid
          ? event.target.value
          : "",
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleDateChange = (date) => {
    setState({ ...state, date });
  };

  return (
    <MyDialogBox
      open={props.open}
      handleClose={() => {
        props.handleClose();
        setState({});
      }}
      title="સંપાદિત કરો"
      content={
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <MyDatePicker
                label="તારીખ *"
                value={state.date || ""}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <TextField
                type="text"
                name="sthan"
                id="standard-basic"
                value={state?.sthan || ""}
                onChange={handleChange}
                errorMessages={["સ્થાન દાખલ કરો"]}
                label="સ્થાન *"
                validators={["required"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="upsthiti"
                id="standard-basic1"
                value={String(state?.upsthiti) || ""}
                onChange={handleChange}
                errorMessages={["ઉપસ્થિતિ દાખલ કરો", "માન્ય ઉપસ્થિતિ દાખલ કરો"]}
                label="ઉપસ્થિતિ *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="bal"
                id="standard-basic1"
                value={String(state?.bal) || ""}
                onChange={handleChange}
                errorMessages={["બાલ દાખલ કરો", "માન્ય બાલ દાખલ કરો"]}
                label="બાલ *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="tarun"
                id="standard-basic1"
                value={String(state?.tarun) || ""}
                onChange={handleChange}
                errorMessages={["તરુણ દાખલ કરો", "માન્ય તરુણ દાખલ કરો"]}
                label="તરુણ *"
                validators={["required", "isNumber"]}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                inputProps={{
                  pattern: "[0-9]*",
                  autoComplete: "new-password",
                }}
                type="tel"
                name="yog"
                id="standard-basic1"
                value={
                  (state.yog =
                    Number(state?.tarun || 0) + Number(state?.bal || 0))
                }
                onChange={handleChange}
                errorMessages={["યોગ દાખલ કરો", "માન્ય યોગ દાખલ કરો"]}
                label="યોગ *"
                disabled={true}
                validators={["required", "isNumber"]}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            <LoadingButton
              color="primary"
              loading={UpdateKaryakariniBethakMutation.isLoading}
              variant="contained"
              type="submit"
            >
              સાચવો
            </LoadingButton>
          </Box>
        </ValidatorForm>
      }
    />
  );
};

export default EditKaryakariniBethak;
